const GitHub = (props) => (
  <svg
    viewBox='0 0 16 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10.27 14.999q-.106-.103-.206-.211a.57.57 0 0 1-.143-.399c.004-.677.013-1.354 0-2.03a4.6 4.6 0 0 0-.1-.792 1.23 1.23 0 0 0-.512-.771l-.093-.066c.17-.024.318-.042.466-.064.42-.06.835-.15 1.239-.29.937-.33 1.546-.976 1.794-1.964.2-.797.27-1.604.129-2.422-.093-.54-.301-1.032-.656-1.442-.168-.195-.21-.38-.168-.634.074-.467.073-.94-.068-1.4l-.04-.118c-.083-.21-.123-.235-.34-.215a2.3 2.3 0 0 0-.829.255c-.341.172-.672.367-1.009.549-.036.019-.084.039-.12.03-.5-.13-1.011-.172-1.524-.183-.622-.012-1.241.011-1.852.148q-.172.039-.34.1a.14.14 0 0 1-.147-.021c-.464-.333-.949-.625-1.501-.772-.168-.045-.345-.056-.518-.08-.077-.012-.12.02-.152.099a3.2 3.2 0 0 0-.187 1.622q.029.21.08.417a.17.17 0 0 1-.032.162c-.116.153-.232.307-.339.466-.295.444-.416.942-.43 1.474-.017.67.033 1.331.25 1.97.35 1.026 1.069 1.658 2.051 1.996q.365.124.741.203c.247.053.5.083.755.123-.01.012-.019.028-.032.04-.274.241-.47.53-.541.902-.007.035-.047.074-.08.09-.301.149-.62.173-.948.159-.423-.019-.74-.215-.992-.558a7 7 0 0 0-.571-.687.88.88 0 0 0-.797-.27c-.01.002-.022 0-.03.005-.056.032-.146.056-.157.102-.013.045.042.125.088.167.087.08.192.14.285.215.263.21.456.48.588.794.112.265.234.524.43.737.322.35.73.504 1.188.517.299.009.599-.018.913-.029.003.037.009.091.01.145.003.416-.003.833.01 1.25a.68.68 0 0 1-.278.59c-.037.028-.07.061-.107.092h-.02c-.23-.098-.463-.187-.688-.296-1.596-.77-2.796-1.964-3.584-3.587a7.7 7.7 0 0 1-.755-3.941c.125-1.818.793-3.4 2-4.731C3.529 1.203 4.912.428 6.539.124c.261-.049.527-.07.792-.106.021-.002.042-.011.064-.018h.973l.456.06c1.175.15 2.26.559 3.236 1.246 1.674 1.176 2.755 2.784 3.157 4.83.459 2.338-.035 4.47-1.464 6.355a7.3 7.3 0 0 1-3.359 2.476q-.03.014-.062.032z'
      fill='currentColor'
    />
  </svg>
);
export default GitHub;
