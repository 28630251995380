import React, { useState, Fragment } from 'react';
import StepFourImg from '../../img/StepFour.svg';
import { Dialog, Transition } from '@headlessui/react';
import { Formik, Form } from 'formik';
import BlackRightArrow from '../../img/BlackRightArrow.svg';
import BlueLeftArrow from '../../img/BlueLeftArrow.svg';
import Oval from '../../img/Oval.svg';
import Edit from '../../img/Edit.svg';
import Submitted from '../../img/Submitted.svg';
import User from '../../img/User.js';
import Flag from '../../img/Flag.js';
import AdditionalInfo from '../../img/Additionalinfo.js';
import { Link } from 'react-router-dom';

const StepFour = (props) => {
  let [isOpen, setIsOpen] = useState(false);
  let [isErrorOpen, setIsErrorOpen] = useState(false);
  let [submitLoading, setSubmitLoading] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  function openErrorModal() {
    setIsErrorOpen(true);
  }
  function closeErrorModal() {
    setIsErrorOpen(false);
  }

  function closeLoadingModal() {
    setSubmitLoading(false);
  }
  const handleSubmit = async (values) => {
    setSubmitLoading(true);
    const token = await window.grecaptcha.execute(
      process.env.REACT_APP_RECAPTCHA_SITE_KEY,
      { action: 'submit' }
    );

    values.recaptcha = token;

    const res = await props.next(values, true);

    setSubmitLoading(false);

    if (res === 'Deal Submitted Successfully!') {
      openModal();
    } else {
      openErrorModal();
    }
  };

  function reformatDate(dateStr) {
    let dArr = dateStr.split('-'); // ex input "2010-01-18"
    return dArr[1] + '/' + dArr[2] + '/' + dArr[0]; //ex out: "18/01/10"
  }
  const isMobileDevice = window.innerWidth <= 400;

  return (
    <Formik
      enableReinitialize={true}
      initialValues={props.data}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form className='flex flex-col relative lg:static'>
          <div className='relative min-h-[calc(100vh-300px)] 3xl:max-h-[1080px] 3xl:min-h-max 3xl:max-w-[1283px] '>
            <div className='flex-col sm:flex-row header-background text-white sm:flex sm:items-center sm:justify-between p-5 h-[72px] lg:h-[100px] xl:h-[125px] 2xl:h-[150px] xl:pl-[22px] 2xl:pl-[40px] 2xl:pr-[37px] rounded-2xl m-3 lg:m-7'>
              <h1 className='text-2xl 2xl:text-3xl  font-semibold'>
                Deal registration form
              </h1>
              <img
                className='w-[200px] mt-2 sm:mt-0 sm:w-auto mr-4 2xl:mr-0'
                alt='stepper'
                src={StepFourImg}
              ></img>
            </div>
            <div className=''>
              <div className='w-full  xl:min-h-[320px] 2xl:min-h-[400px] '>
                <div className='pb-5 pl-[32px] lg:pl-[52px] lg:pr-[63px] 2xl:pl-[70px]'>
                  <div className='flex items-center'>
                    <h3 className='text-sm md:text-base xl:text-lg 2xl:text-xl text-[#b0b0b0] font-semibold'>
                      Deal registration
                    </h3>
                    <button
                      onClick={() => props.prev(values)}
                      className='hover:cursor-pointer'
                    >
                      <h3 className='text-sm md:text-base xl:text-lg ml-1 2xl:text-xl text-[#b0b0b0] hover:text-[#3d82f1] font-semibold'>
                        {'>'} End customer info
                      </h3>
                    </button>
                    <button
                      className={`${
                        values.formType !== '' ? 'hover:cursor-pointer' : ''
                      } `}
                      onClick={
                        values.formType !== ''
                          ? () => {
                              const updatedValues = { ...values, formType: '' };
                              props.prev2(updatedValues);
                              localStorage.setItem(
                                'formValues',
                                JSON.stringify({ ...values, formType: '' })
                              );
                            }
                          : () => {}
                      }
                    >
                      <h3
                        className={`${
                          values.formType !== ''
                            ? 'text-[#b0b0b0] hover:cursor-pointer hover:text-[#3d82f1]'
                            : 'text-[#3d82f1] hover:cursor-text'
                        } text-sm md:text-base xl:text-lg ml-1 2xl:text-xl font-semibold`}
                      >
                        {'>'} Plan
                      </h3>
                    </button>
                    {values.formType === 'cloud' ? (
                      <button
                        onClick={() => {
                          props.prev(values);
                        }}
                        className='hover:cursor-pointer'
                      >
                        <h3 className='text-sm md:text-base xl:text-lg ml-1 2xl:text-xl text-[#b0b0b0] hover:text-[#3d82f1] font-semibold'>
                          {'>'} Wazuh Cloud
                        </h3>
                      </button>
                    ) : values.formType === 'onpremises' ? (
                      <button
                        onClick={() => {
                          props.prev(values);
                        }}
                        className='hover:cursor-pointer'
                      >
                        <h3 className='text-sm md:text-base xl:text-lg ml-1 2xl:text-xl text-[#b0b0b0] hover:text-[#3d82f1] font-semibold'>
                          {'>'} On Prem deployment
                        </h3>
                      </button>
                    ) : (
                      <div></div>
                    )}
                    <h3 className='text-sm md:text-base xl:text-lg ml-1 2xl:text-xl text-[#3d82f1] font-semibold'>
                      {'>'} Additional information
                    </h3>
                  </div>
                  <div className='grid md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 xl:gap-20 2xl:gap-x-[115px]'>
                    <div className='mt-4 flex flex-col 2xl:mt-8'>
                      <div
                        onClick={() => props.prev3(values)}
                        className='text-[#3D82F1] flex hover:cursor-pointer hover:underline mb-2 tex text-base 2xl:text-lg items-center'
                      >
                        <User
                          className='h-9 w-9 mr-2'
                          bgfill='#EBF2FB'
                          mainfill='#3D82F1'
                        />
                        End customer info
                        <img
                          alt='edit button'
                          className='ml-2 w-4'
                          src={Edit}
                        ></img>
                      </div>
                      <div className='ml-11 grid grid-cols-1'>
                        <div className='flex flex-col md:flex-row mb-3 text-sm 2xl:text-base'>
                          <h1 className='font-semibold'>Company name:&nbsp;</h1>
                          <h1>{props.data.Name}</h1>
                        </div>
                        <div className='flex flex-col md:flex-row  mb-3 text-sm 2xl:text-base'>
                          <h1 className=' font-semibold'>Website:&nbsp;</h1>
                          <h1>
                            {props.data.Description === ''
                              ? '-'
                              : props.data.Description}
                          </h1>
                        </div>
                        <div className='flex  flex-col md:flex-row mb-3 text-sm 2xl:text-base'>
                          <h1 className=' font-semibold'>
                            Contact person:&nbsp;
                          </h1>
                          <h1> {props.data.Contact_Name__c}</h1>
                        </div>
                        <div className='flex flex-col md:flex-row mb-3 text-sm 2xl:text-base'>
                          <h1 className=' font-semibold'>Budget:&nbsp;</h1>
                          <h1>
                            {props.data.budget__c === ''
                              ? '-'
                              : props.data.budget__c}
                          </h1>
                        </div>
                        <div className='flex flex-col md:flex-row mb-3 text-sm 2xl:text-base'>
                          <h1 className=' font-semibold'>Email:&nbsp;</h1>
                          <h1> {props.data.Contact_Email__c}</h1>
                        </div>
                        <div className='flex flex-col md:flex-row mb-3 text-sm 2xl:text-base'>
                          <h1 className=' font-semibold'>
                            Estimated close date:&nbsp;
                          </h1>
                          <h1>{reformatDate(props.data.CloseDate)}</h1>
                        </div>
                      </div>
                    </div>
                    <div className='mt-4 2xl:mt-8'>
                      <div>
                        <div
                          onClick={() => props.prev2(values)}
                          className='text-[#3D82F1] flex hover:cursor-pointer hover:underline mb-2 text-base 2xl:text-lg items-center'
                        >
                          <Flag
                            className='h-9 w-9 mr-2'
                            bgfill='#EBF2FB'
                            mainfill='#3D82F1'
                          />
                          Product
                          <img
                            alt='edit button'
                            className='ml-2 w-4'
                            src={Edit}
                          ></img>
                        </div>
                        <div className='ml-11 grid grid-cols-1'>
                          <div className='flex flex-col md:flex-row mb-3 text-sm 2xl:text-base'>
                            <h1 className=' font-semibold'>Plan:&nbsp;</h1>
                            <h1>
                              {props.data.formType === 'cloud'
                                ? 'Cloud'
                                : 'On premise'}
                            </h1>
                          </div>
                          {props.data.formType === 'cloud' ? (
                            <>
                              <div className='flex flex-col md:flex-row mb-3 text-sm 2xl:text-base'>
                                <h1 className=' font-semibold'>
                                  Active Agents:&nbsp;
                                </h1>

                                <h1>
                                  {props.data.activeAgents === ''
                                    ? '-'
                                    : props.data.activeAgents}
                                </h1>
                              </div>
                              <div className='flex flex-col md:flex-row mb-3 text-sm 2xl:text-base'>
                                <h1 className=' font-semibold'>
                                  Indexed Data Retention:&nbsp;
                                </h1>

                                <h1>
                                  {props.data.indexedDataRetention === ''
                                    ? '-'
                                    : props.data.indexedDataRetention}
                                </h1>
                              </div>
                              <div className='flex flex-col md:flex-row mb-3 text-sm 2xl:text-base'>
                                <h1 className=' font-semibold'>
                                  Archive Data Retention:&nbsp;
                                </h1>

                                <h1>
                                  {props.data.archiveDataRetention === ''
                                    ? '-'
                                    : props.data.archiveDataRetention}
                                </h1>
                              </div>
                              <div className='flex flex-col md:flex-row mb-3 text-sm 2xl:text-base'>
                                <h1 className=' font-semibold'>
                                  Support Plan:&nbsp;
                                </h1>

                                <h1>
                                  {props.data.supportPlan === ''
                                    ? '-'
                                    : props.data.supportPlan}
                                </h1>
                              </div>
                              <div className='flex flex-col md:flex-row mb-3 text-sm 2xl:text-base'>
                                <h1 className=' font-semibold'>
                                  Average / Peak EPS:&nbsp;
                                </h1>

                                <h1>
                                  {props.data.averagePeakEPS === ''
                                    ? '-'
                                    : props.data.averagePeakEPS}
                                </h1>
                              </div>
                              <div className='flex flex-col md:flex-row mb-3 text-sm 2xl:text-base'>
                                <h1 className=' font-semibold'>
                                  Indexed Data Capacity:&nbsp;
                                </h1>

                                <h1>
                                  {props.data.indexedDataCapacity === ''
                                    ? '-'
                                    : props.data.indexedDataCapacity}
                                </h1>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className='flex flex-col md:flex-row mb-3 text-sm 2xl:text-base'>
                                <h1 className=' font-semibold'>
                                  Hot storage retention period:&nbsp;
                                </h1>

                                <h1>
                                  {props.data
                                    .Hot_Storage_Retention_Period__c === ''
                                    ? '-'
                                    : props.data
                                        .Hot_Storage_Retention_Period__c}
                                </h1>
                              </div>
                              <div className='flex flex-col md:flex-row mb-3 text-sm 2xl:text-base'>
                                <h1 className=' font-semibold'>
                                  Number of servers:&nbsp;
                                </h1>
                                <h1>
                                  {props.data.Number_of_Servers__c === ''
                                    ? '-'
                                    : props.data.Number_of_Servers__c}
                                </h1>
                              </div>
                              <div className='flex flex-col md:flex-row mb-3 text-sm 2xl:text-base'>
                                <h1 className=' font-semibold'>
                                  Cold storage retenton period:&nbsp;
                                </h1>
                                <h1>
                                  {props.data
                                    .Cold_Storage_Retention_Period__c === ''
                                    ? '-'
                                    : props.data
                                        .Cold_Storage_Retention_Period__c}
                                </h1>
                              </div>
                              <div className='flex flex-col md:flex-row mb-3 text-sm 2xl:text-base'>
                                <h1 className=' font-semibold'>
                                  Number of workstation:&nbsp;
                                </h1>
                                <h1>
                                  {props.data.Number_of_Workstations__c === ''
                                    ? '-'
                                    : props.data.Number_of_Workstations__c}
                                </h1>
                              </div>
                              <div className='flex flex-col md:flex-row mb-3 text-sm 2xl:text-base'>
                                <h1 className=' font-semibold'>
                                  Professional support:&nbsp;
                                </h1>
                                <h1>
                                  {props.data.Professional_support === ''
                                    ? '-'
                                    : props.data.Professional_support}
                                </h1>
                              </div>
                              <div className='flex flex-col md:flex-row mb-3 text-sm 2xl:text-base'>
                                <h1 className=' font-semibold'>
                                  Number of network devices:&nbsp;
                                </h1>
                                <h1>
                                  {props.data.Number_of_Network_Devices__c ===
                                  ''
                                    ? '-'
                                    : props.data.Number_of_Network_Devices__c}
                                </h1>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='mt-4 2xl:mt-8'>
                      <div>
                        <div
                          onClick={() => props.prev(values)}
                          className='text-[#3D82F1] flex hover:cursor-pointer hover:underline mb-2 text-base 2xl:text-lg items-center'
                        >
                          <AdditionalInfo
                            className='h-9 w-9 mr-2'
                            bgfill='#EBF2FB'
                            mainfill='#3D82F1'
                          />
                          Additional info
                          <img
                            alt='edit button'
                            className='ml-2 w-4'
                            src={Edit}
                          ></img>
                        </div>
                        <div className='ml-11 grid grid-cols-1'>
                          <div className='flex flex-col md:flex-row mb-3 text-sm 2xl:text-base'>
                            <h1 className=' font-semibold inline-block'>
                              Comments:&nbsp;
                            </h1>
                            <h1 className='truncate'>
                              {props.data.Sales_Notes__c === ''
                                ? '-'
                                : props.data.Sales_Notes__c}
                            </h1>
                          </div>
                          <div className='flex flex-col md:flex-row mb-3 text-sm 2xl:text-base'>
                            <h1 className=' font-semibold'>Demo:&nbsp;</h1>
                            <h1>
                              {props.data.Request_Demo__c === ''
                                ? '-'
                                : props.data.Request_Demo__c}
                            </h1>
                          </div>
                        </div>

                        <div className='ml-11 flex flex-col sm:flex-row items-center text-sm 2xl:text-base'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='flex w-full 3xl:w-[1283px] justify-between col-span-1 portrait:md:col-span-2 md:col-span-3 lg:col-span-2 xl:col-span-3 py-2 mb-6 2xl:mb-3'>
            <button
              onClick={() => props.prev(values)}
              type='button'
              className='bg-[#FBFCFD] justify-center border border-[#3D82F1] ml-5 xl:ml-[52px] 2xl:ml-[70px] hover:text-blue-700 hover:border-blue-800 hover:cursor-pointer text-sm 2xl:text-base  2xl:right-[calc(100vw-1500px)]   text-[#3D82F1] rounded-lg px-2 py-2 flex items-center w-[91px] lg:w-[109px] lg:h-[42px]'
            >
              <img
                alt='white arrow pointing right'
                className='relative right-2'
                src={BlueLeftArrow}
              ></img>
              Previous
            </button>

            <input type='hidden' id='recaptcha-token' name='recaptcha-token' />
            <button
              type='submit'
              className='bg-yellow-gradient btn text-sm 2xl:text-base justify-center  2xl:right-[calc(100vw-1500px)] rounded-lg px-2 py-2 flex items-center mr-5 md:mr-10 lg:mr-16 xl:mr-16 p-5 2xl:mr-16 w-[91px] lg:w-[109px] lg:h-[42px]'
            >
              Submit
              <img
                alt='white arrow pointing right'
                className='relative left-3'
                src={BlackRightArrow}
              ></img>
            </button>
          </div>
          <Transition appear show={isOpen} as={Fragment}>
            <Dialog as='div' className='relative z-10' onClose={closeModal}>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-black bg-opacity-75' />
              </Transition.Child>

              <div className='fixed inset-0 overflow-y-auto'>
                <div className='flex min-h-full items-center justify-center p-4 text-center'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 scale-95'
                    enterTo='opacity-100 scale-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 scale-100'
                    leaveTo='opacity-0 scale-95'
                  >
                    <Dialog.Panel className='w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                      <div className='mt-4 flex flex-col justify-center items-center font-manrope'>
                        <img
                          alt='submitted message'
                          className='w-44'
                          src={Submitted}
                        />
                        <div className=' text-center mt-2'>
                          <p className='text-[22px] font-semibold'>
                            Your submission has been sent!
                          </p>
                          <p className='text-base font-semibold my-4'>
                            Wazuh Sales team will review it shortly.
                          </p>
                        </div>
                        <Link to='/dashboard'>
                          <button
                            type='button'
                            className='inline-flex mt-4 mb-4 justify-center rounded-lg border border-transparent bg-[#3D82F1] px-12 py-3 text-base font-normal text-white hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2'
                          >
                            Go back home
                          </button>
                        </Link>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>

          <Transition appear show={submitLoading} as={Fragment}>
            <Dialog
              as='div'
              className='relative z-10'
              onClose={closeLoadingModal}
            >
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-black bg-opacity-75' />
              </Transition.Child>

              <div className='fixed inset-0 overflow-y-auto'>
                <div className='flex min-h-full items-center justify-center p-4 text-center'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 scale-95'
                    enterTo='opacity-100 scale-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 scale-100'
                    leaveTo='opacity-0 scale-95'
                  >
                    <Dialog.Panel className='w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                      <div className='m-4 flex flex-col justify-center items-center font-manrope'>
                        <img
                          alt='submitted message'
                          className='w-44'
                          src={Oval}
                        />
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>

          <Transition appear show={isErrorOpen} as={Fragment}>
            <Dialog
              as='div'
              className='relative z-10'
              onClose={closeErrorModal}
            >
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-black bg-opacity-75' />
              </Transition.Child>

              <div className='fixed inset-0 overflow-y-auto'>
                <div className='flex min-h-full items-center justify-center p-4 text-center'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 scale-95'
                    enterTo='opacity-100 scale-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 scale-100'
                    leaveTo='opacity-0 scale-95'
                  >
                    <Dialog.Panel className='w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                      <div className='mt-4 flex flex-col justify-center items-center font-manrope'>
                        <img
                          alt='submitted message'
                          className='w-44'
                          src={Submitted}
                        />
                        <div className=' text-center mt-2'>
                          <p className='text-2xl font-semibold'>
                            There was an error submitting your form!
                          </p>
                          <p className='text-base font-semibold my-4'>
                            Please try again.
                          </p>
                        </div>

                        <button
                          onClick={closeErrorModal}
                          type='button'
                          className='inline-flex mt-4 mb-4 justify-center rounded-md border border-transparent bg-[#3D82F1] px-8 py-2 text-base font-normal text-white hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2'
                        >
                          Close
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </Form>
      )}
    </Formik>
  );
};

export default StepFour;
