import * as React from 'react';
const Search = (props) => (
  <svg
    width={31}
    height={31}
    className='theme-common-icon'
    id='search'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='2 1 31 31'
    fill='none'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.9413 20.2372C21.4927 19.7777 22.3865 19.7777 22.9379 20.2372L28.5849 24.943C29.1363 25.4025 29.1363 26.1474 28.5849 26.6068C28.0336 27.0663 27.3739 27.0663 26.8226 26.6068L20.9413 21.9009C20.39 21.4415 20.39 20.6966 20.9413 20.2372Z'
      fill='#256BD1'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.2941 7.07597C11.048 7.07597 7.60575 10.5182 7.60575 14.7644C7.60575 19.0105 11.048 22.4528 15.2941 22.4528C19.5403 22.4528 22.9825 19.0105 22.9825 14.7644C22.9825 10.5182 19.5403 7.07597 15.2941 7.07597ZM6.11768 14.7644C6.11768 9.69634 10.2261 5.58789 15.2941 5.58789C20.3622 5.58789 24.4706 9.69634 24.4706 14.7644C24.4706 19.8324 20.3622 23.9408 15.2941 23.9408C10.2261 23.9408 6.11768 19.8324 6.11768 14.7644Z'
      fill='#256BD1'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.294 8.25914C11.7011 8.25914 8.78844 11.1718 8.78844 14.7647C8.78844 18.3576 11.7011 21.2703 15.294 21.2703C18.8869 21.2703 21.7996 18.3576 21.7996 14.7647C21.7996 11.1718 18.8869 8.25914 15.294 8.25914ZM7.5293 14.7647C7.5293 10.4764 11.0057 7 15.294 7C19.5823 7 23.0587 10.4764 23.0587 14.7647C23.0587 19.053 19.5823 22.5294 15.294 22.5294C11.0057 22.5294 7.5293 19.053 7.5293 14.7647Z'
      fill='#256BD1'
    />
  </svg>
);
export default Search;
