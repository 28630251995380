import React, { useState, useEffect } from 'react';
import {
  Pie,
  PieChart,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { Formik, Field, Form } from 'formik';
import { useGetStagesByPartnerMutation } from '../../redux/features/user/dealsApiSlice';
import { useGetPartnersMutation } from '../../redux/features/user/partnersApiSlice';
import ChartInfo from '../Popover/ChartInfo';

const COLORS = [
  '#3D82F1',
  '#FEDD0B',
  '#D3ADF0',
  '#9B95DB',
  '#C00462',
  '#6BCEBA',
  '#7840BF',
  '#409ABF',
  '#E84DC3',
];

function convertArray(array) {
  const resultArray = [];
  const stages = {};

  array.forEach((obj) => {
    const stageName = obj.StageName;
    if (stages[stageName]) {
      stages[stageName]++;
    } else {
      stages[stageName] = 1;
    }
  });

  for (const stage in stages) {
    resultArray.push({
      Stage: stage,
      Amount: stages[stage],
    });
  }

  return resultArray;
}

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const backgroundWidth = 40; // Width of the background box
  const backgroundHeight = 30; // Height of the background box
  const padding = 4; // Padding on the left and right

  return (
    <g>
      <>
        {(percent * 100).toFixed(0) > 5 ? (
          <>
            <rect
              x={x - backgroundWidth / 2 - padding}
              y={y - backgroundHeight / 2}
              width={backgroundWidth + padding * 2}
              height={backgroundHeight}
              rx={15}
              ry={20}
              fill='rgba(0, 0, 0, 0.2)'
            />
            <text
              fontSize={14}
              x={x}
              y={y}
              fill='white'
              textAnchor='middle'
              dominantBaseline='central'
            >
              {`${(percent * 100).toFixed(0)}%`}
            </text>
          </>
        ) : (
          <></>
        )}
      </>
    </g>
  );
};

const CustomLegend = ({ data, limit }) => {
  const visibleData = data.slice(0, 3);
  return (
    <div className='custom-scrollbar bg-white rounded-lg py-2 xl:mr-12'>
      <div className='md:flex-col flex overflow-x-scroll md:overflow-x-auto max-w-[150px] xl:max-w-[200px] md:overflow-y-scroll ml-2 mr-5 xl:mx-4 2xl:mx-4 pb-2'>
        {data.map((entry, index) => (
          <div key={index} className='flex  items-center mr-[10px]'>
            <span
              className=' w-[10px] h-[10px] rounded-xl mr-1'
              style={{ backgroundColor: COLORS[index % COLORS.length] }}
            />
            <span className='md:whitespace-nowrap md:overflow-ellipsis md:max-w-[70px] xl:max-w-[120px] text-sm 2xl:text-base truncate'>
              {entry.Stage}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const OperationsStage = () => {
  const [getPartners] = useGetPartnersMutation();
  const [getStages] = useGetStagesByPartnerMutation();
  const [query, setQuery] = useState('all');
  const [stagesList, setStagesList] = useState([]);
  const [partnersList, setPartnersList] = useState([]);
  const AmountInPartnersList = convertArray(stagesList);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  useEffect(() => {
    const getPartnersList = async () => {
      const result = await getPartners('register-user').unwrap();
      setPartnersList(result);
    };
    getPartnersList();
  }, []);

  useEffect(() => {
    const getPartnerData = async () => {
      const result = await getStages(query).unwrap();
      setStagesList(result);
    };
    getPartnerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      if (active && payload && payload.length) {
        const data = payload[0].payload;

        return (
          <div
            className='bg-white border-2 rounded-lg border-gray-300 p-4 flex'
            style={{ color: data.fill }}
          >
            <p>{data.Stage + ':'}</p>
            <p>{data.Amount}</p>
          </div>
        );
      }
      return null;
    }
  };

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  const windowWidth = screenSize.width;

  return (
    <div>
      <div>
        <div className='flex items-center'>
          <h1 className='text-[22px] mr-1 font-semibold text-left'>
            Operations Stage
          </h1>
          <ChartInfo
            text={
              'Shows all the current registered deal stages of that partner, only recurrent services since the very beginning.'
            }
          />
        </div>
        <span className='form-divider h-1 mr-0 lg:mr-6 2xl:mr-3 block mt-2 mb-7'></span>
      </div>
      <div className='rounded-lg  max-md:justify-center bg-[#F6F6F6] px-2 py-4 2xl:h-[500px] 3xl:h-[430px]'>
        <div className='xl:mt-3 flex justify-center'>
          <Formik>
            <Form>
              <Field
                className=' border leading-tight text-sm lg:text-base 2xl:text-lg border-[#B7C6D7] rounded-md w-40 px-2 py-2 text-black placeholder-[#B7C6D7] styled-select xl:mr-0 2xl:mr-0'
                as='select'
                onChange={(e) => setQuery(e.target.value)}
              >
                <option value='all'>All Partners</option>
                {partnersList.map((partner) => (
                  <option value={partner.name}>{partner.name}</option>
                ))}
              </Field>
            </Form>
          </Formik>
        </div>
        <div className='flex max-md:items-center w-full'>
          <div className='flex items-center w-full lg:h-[245px] xl:h-auto'>
            <ResponsiveContainer width='100%' height={392}>
              <PieChart
                margin={{
                  top: -25,
                  bottom: 5,
                }}
              >
                <Pie
                  className='focus:outline-none'
                  data={AmountInPartnersList}
                  dataKey='Amount'
                  nameKey='Stage'
                  fill='#8884d8'
                  labelLine={false}
                  label={renderCustomizedLabel}
                >
                  {AmountInPartnersList.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Legend
                  verticalAlign={windowWidth < 768 ? 'bottom' : 'middle'}
                  align={windowWidth < 768 ? 'center' : 'right'}
                  layout='vertical'
                  content={
                    <CustomLegend data={AmountInPartnersList} limit={3} />
                  }
                />
                <Tooltip content={<CustomTooltip />} />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OperationsStage;
