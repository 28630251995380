import ArrowToRight from '../img/ArrowToRight';
import BenefitsTable from './BenefitsTable';
import { useState } from 'react';

export default function ProfilePlanBenefits({ discount }) {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <div
        onClick={openModal}
        className='mb-4 items-center text-[16px] font-normal btn bg-yellow-gradient rounded-lg p-2 col-span-1 hover:cursor-pointer flex w-[245px] pl-6'
      >
        Check your plan benefits
        <ArrowToRight mainfill={'#292929'} className='ml-2' />
      </div>

      <BenefitsTable
        discount={discount}
        isOpen={isOpen}
        closeModal={closeModal}
        openModal={openModal}
      />
    </>
  );
}
