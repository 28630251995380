const Slack = (props) => (
  <svg
    viewBox='0 0 16 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10.926 4.107c0 .541.006 1.083-.001 1.624-.011.828-.582 1.419-1.36 1.418-.782-.002-1.327-.586-1.33-1.432a479 479 0 0 1 .001-3.247c.004-.819.554-1.393 1.326-1.399.768-.005 1.347.574 1.362 1.377.01.553.002 1.106.002 1.66m-6.44.357c.565 0 1.13-.01 1.695.002.786.016 1.354.588 1.351 1.343-.003.75-.585 1.336-1.358 1.342q-1.678.014-3.355 0c-.78-.005-1.36-.58-1.366-1.332-.005-.763.551-1.334 1.337-1.352.565-.012 1.13-.002 1.696-.003m6.762 6.075c-.529 0-1.058.004-1.587-.001-.842-.01-1.4-.56-1.427-1.347-.024-.684.603-1.38 1.417-1.352 1.081.036 2.165.005 3.246.01.83.003 1.43.58 1.42 1.35-.01.756-.606 1.326-1.41 1.337-.553.009-1.106.003-1.659.003m-6.401.366c0-.578-.01-1.155.002-1.731.015-.783.586-1.332 1.36-1.323A1.31 1.31 0 0 1 7.532 9.18q.014 1.695 0 3.39a1.35 1.35 0 0 1-1.346 1.359 1.337 1.337 0 0 1-1.335-1.327c-.012-.565-.003-1.13-.003-1.695zm6.797-3.754c0-.565-.053-1.108.012-1.635.088-.693.756-1.124 1.49-1.047.632.067 1.15.65 1.163 1.31.014.677-.513 1.298-1.188 1.36-.37.033-.744.01-1.116.011zm-3.406 4.107c.558 0 1.078-.045 1.586.011.682.075 1.158.738 1.095 1.426a1.36 1.36 0 0 1-1.364 1.234c-.71-.014-1.293-.568-1.315-1.27-.013-.456-.002-.912-.002-1.401m-.713-7.507c-.533 0-1.026.03-1.514-.006-.706-.052-1.202-.685-1.166-1.435a1.317 1.317 0 0 1 1.298-1.24c.715-.014 1.33.504 1.376 1.202.031.476.006.956.006 1.48M4.124 7.855c0 .55.046 1.069-.01 1.576-.078.693-.77 1.184-1.46 1.105a1.35 1.35 0 0 1-1.2-1.423 1.31 1.31 0 0 1 1.285-1.256c.454-.011.908-.002 1.385-.002'
      fill='currentColor'
    />
  </svg>
);
export default Slack;
