import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import QRCode from 'qrcode.react';
import {
  useGenerateMutation,
  useVerifyMutation,
  useNeeds2FAMutation,
  useDisableMutation,
} from '../../../redux/features/user/2FAApiSlice';
import useAuth from '../../../hooks/useAuth';
import { Formik, Form, Field } from 'formik';

export default function MyModal() {
  let [isOpen, setIsOpen] = useState(false);
  let [isVerifyOpen, setIsVerifyOpen] = useState(false);
  let [isVerifySuccesfullyOpen, setIsVerifySuccessfullyOpen] = useState(false);
  let [isVerifyFailedOpen, setIsVerifyFailedOpen] = useState(false);
  let [isDisableSuccesfullyOpen, setIsDisableSuccessfullyOpen] =
    useState(false);
  let [isDisableFailedOpen, setIsDisableFailedOpen] = useState(false);
  let [isDisableOpen, setIsDisableOpen] = useState(false);
  let [needsTwoFA, setNeedsTwoFA] = useState([]);
  let [codes, setCodes] = useState([]);
  const [generate] = useGenerateMutation();
  const [verifyToken] = useVerifyMutation();
  const [needsAuth] = useNeeds2FAMutation();
  const [disable2FA] = useDisableMutation();
  const [secretKey, setSecretKey] = useState('');

  const userinfo = useAuth();

  useEffect(() => {
    const fetchNeedsAuth = async () => {
      try {
        const res = await needsAuth({ email: userinfo.email }).unwrap();
        setNeedsTwoFA(res.TwoFA);
      } catch (err) {
        console.log(err);
      }
    };
    fetchNeedsAuth();
  }, [isVerifyOpen, isDisableSuccesfullyOpen, isDisableFailedOpen]);

  useEffect(() => {
    const fetchSecretKey = async () => {
      try {
        const res = await generate({ id: userinfo.id }).unwrap();
        setSecretKey(res);
      } catch (err) {
        console.log(err);
      }
    };
    fetchSecretKey();
  }, []);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function openDisableModal() {
    setIsDisableOpen(true);
  }

  function closeDisableModal() {
    setIsDisableOpen(false);
  }

  function closeVerifyModal() {
    setIsVerifyOpen(false);
  }

  function openVerifyModal() {
    setIsVerifyOpen(true);
    setIsOpen(false);
  }

  function closeVerifySuccessfully() {
    setIsVerifySuccessfullyOpen(false);
  }

  function closeVerifyFailed() {
    setIsVerifyFailedOpen(false);
  }

  function closeDisableSuccesfull() {
    setIsDisableSuccessfullyOpen(false);
  }

  function closeDisableFailed() {
    setIsDisableFailedOpen(false);
  }

  const TwoFactorInitialData = {
    token: '',
  };

  const handleVerify = async (values) => {
    const token = values.token;

    const res = await verifyToken({
      userId: userinfo.id,
      token: token,
    }).unwrap();

    if (res.verified && res.verified === true) {
      const codesArray = res.codes[0].OTPCodes.split(',');
      setCodes(codesArray);
      setIsVerifyOpen(false);
      setIsVerifySuccessfullyOpen(true);
    } else if (res.verified === false) {
      setIsVerifyOpen(false);
      setIsVerifyFailedOpen(true);
    }
  };

  const disableInitialData = {
    password: '',
  };

  const handleDisable2FA = async (formData) => {
    closeDisableModal();
    const res = await disable2FA({
      userId: userinfo.id,
      password: formData.password,
    }).unwrap();
    console.log(res.verified, 'res ');
    if (res === '2FA disabled successfully!') {
      setIsDisableSuccessfullyOpen(true);
    } else {
      setIsDisableFailedOpen(true);
    }
  };

  function downloadCodes() {
    const codesText = codes.join('\n');
    const blob = new Blob([codesText], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, 'wazuh_partners_console_codes.txt');
  }

  return (
    <>
      <div className=''>
        <h3 className='text-[22px] mt-4 font-semibold'>
          Two Factor Authentication
        </h3>
        <span className='form-divider h-1 mr-0 lg:mr-11 2xl:mr-3 hidden md:block mt-2 mb-7'></span>

        {needsTwoFA === 'Enabled' ? (
          <button
            type='button'
            onClick={openDisableModal}
            className='rounded-lg bg-red-300 p-2 px-4 text-white hover:bg-red-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75'
          >
            Disable 2FA
          </button>
        ) : (
          <button
            type='button'
            onClick={openModal}
            className='rounded-lg  p-2 px-4 bg-yellow-gradient btn focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75'
          >
            Enable 2FA
          </button>
        )}
      </div>

      <Transition appear show={isDisableFailedOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeDisableFailed}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black/25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg flex justify-center font-medium leading-6 text-gray-900'
                  >
                    Disable Two Factor Authentication
                  </Dialog.Title>
                  <p className='my-2'>
                    There was an error trying to disable the Two Factor
                    Authentication, please try again later!
                  </p>

                  <div className='mt-4 flex justify-end'>
                    <button
                      type='button'
                      className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                      onClick={closeDisableFailed}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isDisableSuccesfullyOpen} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          onClose={closeDisableSuccesfull}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black/25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg flex justify-center font-medium leading-6 text-gray-900'
                  >
                    Disable Two Factor Authentication
                  </Dialog.Title>
                  <p className='my-2'>
                    Two Factor Authentication has been disabled successfully!
                  </p>

                  <div className='mt-4 flex justify-end'>
                    <button
                      type='button'
                      className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                      onClick={closeDisableSuccesfull}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isDisableOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeDisableModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black/25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg flex justify-center font-medium leading-6 text-gray-900'
                  >
                    Disable Two Factor Authentication
                  </Dialog.Title>
                  <p className='my-2'>
                    Are you sure you want to disable your Two Factor
                    Authentication?
                  </p>

                  <p>Please enter your password</p>
                  <Formik
                    onSubmit={handleDisable2FA}
                    initialValues={disableInitialData}
                  >
                    <Form>
                      <Field
                        type='password'
                        autoComplete='off'
                        name='password'
                        placeholder='Enter your password'
                        className='rounded-lg bg-gray-50 border  text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5'
                      ></Field>

                      <div className='mt-4 flex justify-end'>
                        <button
                          type='button'
                          className='inline-flex mr-5 justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                          onClick={closeDisableModal}
                        >
                          Close
                        </button>
                        <button
                          type='submit'
                          className='inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2'
                        >
                          Disable
                        </button>
                      </div>
                    </Form>
                  </Formik>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black/25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg flex justify-center font-medium leading-6 text-gray-900'
                  >
                    Two Factor Authentication
                  </Dialog.Title>
                  <p className='my-2'>
                    Scan this QR code with your authentication app and then
                    proceed to verify it.
                  </p>
                  <div className='mt-4 flex justify-center'>
                    <QRCode
                      value={`otpauth://totp/Wazuh Partners Portal:${userinfo.email}?secret=${secretKey}`}
                    />
                  </div>

                  <div className='mt-4 flex justify-end'>
                    <button
                      type='button'
                      className='inline-flex justify-center rounded-md border border-transparent bg-yellow-gradient btn px-4 py-2 text-sm focus:outline-none focus-visible:ring-2 focus-visible:ring-black focus-visible:ring-offset-2'
                      onClick={openVerifyModal}
                    >
                      Verify
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isVerifyOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeVerifyModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black/25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg flex justify-center font-medium leading-6 text-gray-900'
                  >
                    Two Factor Authentication
                  </Dialog.Title>
                  <p className='my-2'>
                    Enter the code the conde displayed in your athenticator to
                    verify your 2FA.
                  </p>
                  <div className='mt-4 flex justify-center flex-col'>
                    Enter the code here
                    <Formik
                      onSubmit={handleVerify}
                      initialValues={TwoFactorInitialData}
                    >
                      <Form>
                        <Field
                          type='number'
                          autoComplete='off'
                          name='token'
                          placeholder='Enter 2FA code'
                          maxLength='6'
                          min='0'
                          className='rounded-lg bg-gray-50 border  text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5'
                        ></Field>
                        <div className='flex justify-end mt-0'>
                          <button
                            className='inline-flex justify-center rounded-md border border-transparent bg-yellow-gradient btn px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-black focus-visible:ring-offset-2'
                            type='submit'
                          >
                            Verify
                          </button>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isVerifySuccesfullyOpen} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          onClose={closeVerifySuccessfully}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black/25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg flex justify-center font-medium leading-6 text-gray-900'
                  >
                    Two Factor Authentication
                  </Dialog.Title>
                  <p className='my-2'>
                    Two factor authentication setted up correctly!
                  </p>
                  <p>
                    Save this codes in order to gain access if you ever lose
                    your device.
                  </p>
                  <div className='grid grid-cols-2 justify-center text-center font-bold mt-4'>
                    {codes.map((code) => (
                      <div>{code}</div>
                    ))}
                  </div>
                  <div className='flex justify-center mt-4'>
                    <button
                      onClick={downloadCodes}
                      className='inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                    >
                      Download codes
                    </button>
                  </div>
                  <div className='mt-4 flex justify-center flex-col'>
                    <button
                      onClick={closeVerifySuccessfully}
                      className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                      type='submit'
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isVerifyFailedOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeVerifyFailed}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black/25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg flex justify-center font-medium leading-6 text-gray-900'
                  >
                    Two Factor Authentication
                  </Dialog.Title>
                  <p className='my-2'>Verified failed, please try again!</p>
                  <div className='mt-4 flex justify-center flex-col'>
                    <button
                      onClick={closeVerifyFailed}
                      className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                      type='submit'
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
