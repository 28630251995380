import React, { useState, useEffect } from 'react';
import WorldMap from '../components/WazuhAnalyticsPanel/WorldMap';
import { useGetPartnersMutation } from '../redux/features/user/partnersApiSlice';
import {
  useGetStagesByPartnerMutation,
  useGetMostGrossingPartnersMutation,
} from '../redux/features/user/dealsApiSlice';
import OperationsStage from '../components/WazuhAnalyticsPanel/OperationsStage';
import MostGrossingPartners from '../components/WazuhAnalyticsPanel/MostGrossingPartners';
import UsersList from '../components/WazuhAnalyticsPanel/UsersList';

const WazuhUsersPanel = () => {
  const [getPartners] = useGetPartnersMutation();
  const [getStages] = useGetStagesByPartnerMutation();
  const [getMostGrossing] = useGetMostGrossingPartnersMutation();
  const [partnersList, setPartnersList] = useState([]);
  const [stagesList, setStagesList] = useState([]);
  const [grossList, setGrossList] = useState([]);
  const [query, setQuery] = useState('all');

  useEffect(() => {
    const getPartnersList = async () => {
      const result = await getPartners('register-user').unwrap();
      setPartnersList(result);
    };
    getPartnersList();
  }, []);

  useEffect(() => {
    const getGrossingList = async () => {
      const result = await getMostGrossing().unwrap();
      setGrossList(result);
    };
    getGrossingList();
  }, []);

  useEffect(() => {
    const getPartnerData = async () => {
      const result = await getStages(query).unwrap();
      setStagesList(result);
    };
    getPartnerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  function convertArray(array) {
    const resultArray = [];
    const stages = {};

    array.forEach((obj) => {
      const stageName = obj.StageName;
      if (stages[stageName]) {
        stages[stageName]++;
      } else {
        stages[stageName] = 1;
      }
    });

    for (const stage in stages) {
      resultArray.push({
        Stage: stage,
        Amount: stages[stage],
      });
    }

    return resultArray;
  }

  const AmountInPartnersList = convertArray(stagesList);

  const COLORS = [
    '#3D82F1',
    '#FEDD0B',
    '#D3ADF0',
    '#9B95DB',
    '#C00462',
    '#6BCEBA',
    '#7840BF',
    '#409ABF',
    '#E84DC3',
  ];

  return (
    <div className='bg-white'>
      <div className='text-2xl 2xl:text-3xl font-semibold p-5 h-[72px] lg:h-[100px] xl:h-[125px] 2xl:h-[150px] xl:pl-[22px] 2xl:px-[40px] text-white header-background flex items-center rounded-2xl m-3 lg:m-7'>
        Sales Analytics
      </div>
      <div className='grid grid-cols-2 gap-9 m-10'>
        <div className=' col-span-1 2xl:col-span-1 '>
          <OperationsStage />
        </div>

        <div className='col-span-1 2xl:col-span-1 '>
          <WorldMap />
        </div>
        <div className='col-span-2'>
          <MostGrossingPartners />
        </div>
        <div className='col-span-2 flex flex-col'>
          <UsersList />
        </div>
        {/* <div className='col-span-2 flex flex-col'>
        <Support />
      </div> */}
      </div>
    </div>
  );
};

export default WazuhUsersPanel;
