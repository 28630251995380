const LinkedIn = (props) => (
  <svg
    viewBox='0 0 16 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M3.549 1.563c0 .863-.695 1.562-1.553 1.562S.443 2.425.443 1.563 1.138 0 1.996 0s1.553.7 1.553 1.563m.012 2.812H.431v10h3.13zm4.998 0h-3.11v10h3.11v-5.25c0-2.918 3.775-3.157 3.775 0v5.25h3.124V8.043c0-4.925-5.587-4.745-6.9-2.321z'
      fill='currentColor'
    />
  </svg>
);
export default LinkedIn;
