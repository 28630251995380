const XTwitter = (props) => (
  <svg
    viewBox='0 0 14 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M13.382 12.991h-3.9q-.142-.191-.28-.384l-3.07-4.368c-.169-.24-.17-.24-.367-.015q-1.402 1.59-2.797 3.183c-.46.527-.94 1.034-1.372 1.584q-.564.001-1.129-.002c-.027 0-.092.043-.084-.04.04-.041.084-.08.122-.123 1.587-1.803 3.172-3.608 4.762-5.407.135-.152.114-.258.006-.41Q2.823 3.531.382.053c.039-.09.117-.04.173-.04Q2.42.008 4.282.01L7.44 4.5c.172.245.173.243.367.022L11.778.01h1.17c-.016.1-.094.16-.155.229Q10.56 2.784 8.316 5.32c-.131.148-.13.246-.018.405q2.078 2.942 4.143 5.893c.318.455.66.893.94 1.372M2.869.887c-.188 0-.376-.004-.563.002-.082.003-.2-.046-.238.04-.035.08.067.15.116.218l4.528 6.336 3.196 4.47c.077.107.145.212.303.21.44-.005.88-.002 1.32 0 .142 0 .198-.043.1-.176l-.101-.14C9.013 8.326 6.493 4.808 3.988 1.281c-.212-.3-.428-.46-.795-.398-.105.019-.216.003-.324.003'
      fill='currentColor'
    />
  </svg>
);
export default XTwitter;
