import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import StepOneImg from '../../img/StepOne.svg';
import BlackRightArrow from '../../img/BlackRightArrow.svg';
import WhiteRightArrow from '../../img/WhiteRightArrow.svg';

const website_regex =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

const valid_characters_regex =
  /^(?=.*[a-zA-ZÀ-ȕ0-9.!#$%&'*+\-/=?^_`{|}~])[a-zA-ZÀ-ȕ0-9.!#$%&'*+\-/=?^_`{|}~ ]{1,120}$/gm;

const stepOneValidationSchema = Yup.object({
  Name: Yup.string()
    .required('Company name is required')
    .max(30, 'Company name must be 30 characters or less.')
    .matches(valid_characters_regex, 'Invalid input'),
  Contact_Name__c: Yup.string()
    .required('Contact name is required')
    .max(30, 'Contact name must be 30 characters or less.')
    .matches(valid_characters_regex, 'Invalid input'),
  Contact_Email__c: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .max(70, 'Email name must be 70 characters or less.'),
  Description: Yup.string()
    .required('Website is required')
    .matches(website_regex, 'URL is not valid')
    .matches(valid_characters_regex, 'Invalid input'),
  budget__c: Yup.string().required('Budget is required'),
  CloseDate: Yup.date().required('Close date is required'),
});

const StepOne = (props) => {
  const handleSubmit = (values) => {
    props.next(values);
  };

  return (
    <Formik
      className=''
      enableReinitialize={true}
      validationSchema={stepOneValidationSchema}
      initialValues={props.data}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isValid, values, handleChange }) => (
        <Form className='flex flex-col'>
          <div className='relative min-h-[calc(100vh-500px)] 3xl:max-h-[1080px] 3xl:min-h-max 3xl:max-w-[1283px] '>
            <div className='text-2xl sm:text-3xl font-semibold p-5 h-[72px] lg:h-[100px] xl:h-[125px] 2xl:h-[150px] xl:pl-[22px] 2xl:pl-[40px] 2xl:pr-[37px] text-white header-background flex flex-col sm:flex-row sm:items-center justify-between rounded-2xl m-3 lg:m-7'>
              <h1 className='text-2xl 2xl:text-3xl  font-semibold'>
                Deal registration form
              </h1>
              <img
                className='w-[200px] mt-2 sm:mt-0 sm:w-auto mr-4 2xl:mr-0'
                src={StepOneImg}
              ></img>
            </div>
            <div className=''>
              <div className='w-full  xl:min-h-[280px] 2xl:min-h-[400px] '>
                <div className='pb-5 pl-[32px] lg:pl-[52px] lg:pr-[63px] 2xl:pl-[70px]'>
                  <div className='flex items-center'>
                    <h3 className='text-lg 2xl:text-xl text-[#b0b0b0] font-semibold'>
                      Deal registration
                    </h3>
                    <h3 className='text-lg ml-1 2xl:text-xl text-[#3d82f1] font-semibold'>
                      {'>'} End customer info
                    </h3>
                  </div>
                  <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 mt-6 xl:w-full gap-x-10  md:static'>
                    <div>
                      {errors.Name && touched.Name ? (
                        <p className='text-sm 2xl:text-base font-semibold mb-2 text-red-500'>
                          {errors.Name}
                        </p>
                      ) : (
                        <div className='flex'>
                          <p className='font-semibold mb-2 text-sm 2xl:text-base'>
                            Company name
                          </p>
                          <p className='text-red-500 ml-4'>*</p>
                        </div>
                      )}

                      <Field
                        className=' border focus:border-[#3D82F1] border-[#B7C6D7] rounded-md text-sm 2xl:text-base w-full px-2 py-2 text-black placeholder-[#B7C6D7]'
                        placeholder='Company name'
                        type='text'
                        name='Name'
                        onChange={(e) => {
                          handleChange(e);
                          localStorage.setItem(
                            'formValues',
                            JSON.stringify({ ...values, Name: e.target.value })
                          );
                        }}
                      ></Field>
                    </div>
                    <div className='flex flex-col'>
                      <div>
                        {errors.Contact_Name__c && touched.Contact_Name__c ? (
                          <p className='font-semibold text-sm mt-4 2xl:text-base mb-2 lg:mt-0 text-red-500'>
                            {errors.Contact_Name__c}
                          </p>
                        ) : (
                          <div className='flex'>
                            <p className='font-semibold text-sm 2xl:text-base mb-2 mt-4  md:mt-0 '>
                              Contact person
                            </p>
                            <p className='text-red-500 ml-4'>*</p>
                          </div>
                        )}

                        <Field
                          className=' border focus:border-[#3D82F1] border-[#B7C6D7] rounded-md text-sm 2xl:text-base w-full px-2 py-2 text-black placeholder-[#B7C6D7]'
                          placeholder='Contact name'
                          type='text'
                          name='Contact_Name__c'
                          onChange={(e) => {
                            handleChange(e);
                            localStorage.setItem(
                              'formValues',
                              JSON.stringify({
                                ...values,
                                Contact_Name__c: e.target.value,
                              })
                            );
                          }}
                        ></Field>
                      </div>
                    </div>
                    <div className='lg:mt-4 xl:mt-0 flex flex-col'>
                      <div>
                        {errors.Contact_Email__c && touched.Contact_Email__c ? (
                          <p className='font-semibold flex text-sm 2xl:text-base mb-2 text-red-500'>
                            {errors.Contact_Email__c}
                          </p>
                        ) : (
                          <div className='flex'>
                            <p className='font-semibold text-sm 2xl:text-base mb-2 mt-4 lg:mt-0'>
                              Email
                            </p>
                            <p className='text-red-500 ml-4'>*</p>
                          </div>
                        )}

                        <Field
                          className=' border focus:border-[#3D82F1] border-[#B7C6D7] rounded-md text-sm 2xl:text-base w-full px-2 py-2 text-black placeholder-[#B7C6D7]'
                          placeholder='email@company.com'
                          type='email'
                          name='Contact_Email__c'
                          onChange={(e) => {
                            handleChange(e);
                            localStorage.setItem(
                              'formValues',
                              JSON.stringify({
                                ...values,
                                Contact_Email__c: e.target.value,
                              })
                            );
                          }}
                        ></Field>
                      </div>
                    </div>
                    <div className='mt-4 xl:mt-10'>
                      {errors.Description && touched.Description ? (
                        <p className='font-semibold text-sm 2xl:text-base mb-2 text-red-500'>
                          {errors.Description}
                        </p>
                      ) : (
                        <div className='flex'>
                          <p className='font-semibold text-sm 2xl:text-base mb-2'>
                            Website
                          </p>
                          <p className='text-red-500 ml-4'>*</p>
                        </div>
                      )}

                      <Field
                        className=' border focus:border-[#3D82F1] border-[#B7C6D7] rounded-md text-sm 2xl:text-base w-full px-2 py-2 text-black placeholder-[#B7C6D7]'
                        placeholder='Website'
                        type='text'
                        name='Description'
                        onChange={(e) => {
                          handleChange(e);
                          localStorage.setItem(
                            'formValues',
                            JSON.stringify({
                              ...values,
                              Description: e.target.value,
                            })
                          );
                        }}
                      ></Field>
                    </div>
                    <div className='mt-4 xl:mt-10 flex flex-col'>
                      <div>
                        {errors.budget__c && touched.budget__c ? (
                          <p className='font-semibold text-sm 2xl:text-base mb-2 text-red-500'>
                            {errors.budget__c}
                          </p>
                        ) : (
                          <div className='flex'>
                            <p className='font-semibold text-sm 2xl:text-base mb-2'>
                              Budget
                            </p>
                            <p className='text-red-500 ml-4'>*</p>
                          </div>
                        )}

                        <Field
                          className=' border focus:border-[#3D82F1] border-[#B7C6D7] rounded-md text-sm 2xl:text-base w-full px-2 py-2 text-black placeholder-[#B7C6D7] styled-select'
                          as='select'
                          name='budget__c'
                          onChange={(e) => {
                            handleChange(e);
                            localStorage.setItem(
                              'formValues',
                              JSON.stringify({
                                ...values,
                                budget__c: e.target.value,
                              })
                            );
                          }}
                        >
                          <option
                            className='text-gray-200'
                            selected
                            disabled
                            value=''
                          >
                            -
                          </option>
                          <option value='USD 0 - 10k'>USD 0 - 10K</option>
                          <option value='USD 10 - 30k'>USD 10 - 30K</option>
                          <option value='USD 30 - 50k'>USD 30k - 50K</option>
                          <option value='USD 50k+'>USD 50K+</option>
                          <option value='n/a'>N/A</option>
                        </Field>
                      </div>
                    </div>
                    <div className='mt-4 xl:mt-10 flex flex-col'>
                      <div>
                        {errors.CloseDate && touched.CloseDate ? (
                          <p className='font-semibold text-sm 2xl:text-base mb-2 text-red-500'>
                            {errors.CloseDate}
                          </p>
                        ) : (
                          <div className='flex'>
                            <p className='font-semibold text-sm 2xl:text-base mb-2'>
                              Estimated close date
                            </p>
                            <p className='text-red-500 ml-4'>*</p>
                          </div>
                        )}

                        <Field
                          className=' border focus:border-[#3D82F1] border-[#B7C6D7] rounded-md text-sm 2xl:text-base w-full px-2 py-2 text-black placeholder-[#B7C6D7]'
                          type='date'
                          name='CloseDate'
                          placeholder='mm-dd-yyyy'
                          onChange={(e) => {
                            handleChange(e);
                            localStorage.setItem(
                              'formValues',
                              JSON.stringify({
                                ...values,
                                CloseDate: e.target.value,
                              })
                            );
                          }}
                        ></Field>
                      </div>
                    </div>
                  </div>
                  {/* mt-[calc(100vh-95vh)]  xl:mt-[calc(100vh-90vh)] 2xl:mt-[calc(100vh-70vh)]  */}
                </div>
              </div>
            </div>
          </div>
          <div className='flex w-full 3xl:w-[1283px] justify-end col-span-1 portrait:md:col-span-2 md:col-span-3 lg:col-span-2 xl:col-span-3 py-2 pb-3'>
            <button
              type='submit'
              className={`${
                isValid &&
                values.Name !== '' &&
                values.Contact_Name__c !== '' &&
                values.Contact_Email__c !== ''
                  ? 'bg-[#3D82F1] border border-[#3D82F1] hover:bg-blue-800 hover:cursor-pointer text-white'
                  : 'bg-[#B7C6D7] border border-[#B7C6D7] hover:cursor-not-allowed text-white'
              } text-sm 2xl:text-base justify-center  2xl:right-[calc(100vw-1500px)] rounded-lg px-2 py-2 flex items-center mr-5 md:mr-10 lg:mr-16 xl:mr-16 p-5 2xl:mr-16 w-[91px] lg:w-[109px] lg:h-[42px]`}
            >
              Next
              <img
                alt='white arrow pointing right'
                className='relative left-5'
                src={`${
                  isValid &&
                  values.Name !== '' &&
                  values.Contact_Name__c !== '' &&
                  values.Contact_Email__c !== ''
                    ? WhiteRightArrow
                    : WhiteRightArrow
                }`}
              ></img>
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default StepOne;
