import React, { useState, useEffect } from 'react';
import Export from '../components/img/Export';
import Search from '../components/img/Search.svg';
import Plus from '../components/img/Plus.svg';
import RegisteredDealsTable from './RegisteredDealsTable';
import { Link, useLocation } from 'react-router-dom';
import { downloadExcel } from 'react-export-table-to-excel';
import MobileRegisteredDeals from '../components/MobileRegisteredDeals';
import useAuth from '../hooks/useAuth';
import {
  useGetDealsMutation,
  useGetDealsByNameMutation,
} from '../redux/features/user/dealsApiSlice';
import { useGetPartnerByIdMutation } from '../redux/features/user/partnersApiSlice';
import SearchNotFound from '../components/img/SearchNotFound.png';

const SearchResult = () => {
  const [deals, SetDeals] = useState([]);
  const [formatedDeal, setFormatedDeals] = useState([]);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState('Partner_Client__c');
  const [orientation, setOrientation] = useState('ascending');
  const [callLoading, setCallLoading] = useState(true);
  const [stageType, setStageType] = useState('');
  const [partnerData, setPartnerData] = useState(null);
  const [getDeals] = useGetDealsMutation();
  const [getPartner] = useGetPartnerByIdMutation();
  const [getDealsByName] = useGetDealsByNameMutation();

  const userinfo = useAuth();

  const location = useLocation();

  const searchName = location.pathname.split('/')[2];

  useEffect(() => {
    const getPartnerData = async () => {
      const partnerInfo = await getPartner(userinfo.partnerId).unwrap();
      setPartnerData(partnerInfo);
    };

    getPartnerData();
  }, []);

  useEffect(() => {
    const getDealByNameData = async () => {
      if (partnerData && partnerData.length > 0) {
        const result = await getDealsByName({
          partner: partnerData[0].salesforceId,
          searchname: searchName,
        }).unwrap();
        SetDeals(result);
        setCallLoading(false);
      }
    };
    getDealByNameData();
  }, [partnerData, searchName]);

  function formatLead(lead) {
    const { Id, Name, CreatedDate, Sales_Notes__c } = lead;
    const dealType = Sales_Notes__c.toLowerCase().includes('cloud')
      ? 'Cloud'
      : Sales_Notes__c.toLowerCase().includes('onpremises')
      ? 'On premises'
      : 'Unknown';
    const date = new Date(CreatedDate);
    date.setMonth(date.getMonth() + 3); // Add 3 months to the created date
    const formattedDate = date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD

    return {
      Id: Id,
      Partner_Client__c: Name,
      CloseDate: formattedDate,
      Type: dealType,
      dealType: 'Lead',
      StageName: 'Pending approval',
    };
  }

  function formatOpportunity(opportunity) {
    const {
      Id,
      Partner_Client__c,
      StageName,
      Type,
      CloseDate,
      Contract_Expiration_Date__c,
    } = opportunity;
    const TypeFormated = Type.toLowerCase().includes('cloud')
      ? 'Cloud'
      : Type.toLowerCase().includes('on premises')
      ? 'On premises'
      : 'Unknown';

    let formattedStage;
    switch (StageName) {
      case 'Closed Lost':
        formattedStage = 'Closed Lost';
        break;
      case 'Closed Disqualified':
        formattedStage = 'Not approved';
        break;
      case 'Closed Won':
        formattedStage = 'Customer';
        break;
      default:
        formattedStage = 'Approved';
    }

    const dealTypeFormated =
      StageName === 'Closed Won' ? 'Customer' : 'Opportunity';

    const CloseDateFormated =
      StageName === 'Closed Won' ? Contract_Expiration_Date__c : CloseDate;

    return {
      Id: Id,
      Partner_Client__c: Partner_Client__c,
      CloseDate: CloseDateFormated,
      Type: TypeFormated,
      dealType: dealTypeFormated,
      StageName: formattedStage,
    };
  }

  useEffect(() => {
    const leads = deals.leads;
    const customers = deals.opportunities;

    const formattedLeads = Array.isArray(leads) ? leads.map(formatLead) : [];
    const formattedOpportunities = Array.isArray(customers)
      ? customers.map(formatOpportunity)
      : [];

    const unifiedArray = [...formattedLeads, ...formattedOpportunities];

    setFormatedDeals(unifiedArray);
  }, [deals]);

  //   const CloudTypes = [
  //     'Customer New Cloud',
  //     'Customer Renewal Cloud',
  //     'Customer Upgrade Cloud',
  //     'Customer Renewal/Upgrade Cloud',
  //     'Customer Renewal/Downgrade Cloud',
  //     'Partner Customer New Cloud',
  //     'Partner Customer Renewal Cloud',
  //     'Partner Customer Renewal/Upgrade Cloud',
  //     'Partner Customer Renewal/Downgrade Cloud',
  //     'Cloud',
  //   ];

  //   const PremisesTypes = [
  //     'Customer New On-premise',
  //     'Customer Renewal On-premise',
  //     'Customer Renewal/Expansion On-premise',
  //     'Customer Renewal/Reduction On-premise',
  //     'Customer Expansion On-premise',
  //     'Partner Customer New On premises',
  //     'Partner Customer Renewal On premises',
  //     'Partner Customer Renewal/ Upgrade On premises',
  //     'On Premises',
  //   ];

  //   const newArray = deals
  //     .filter(
  //       (obj) => CloudTypes.includes(obj.Type) || PremisesTypes.includes(obj.Type)
  //     )
  //     .map((obj) => {
  //       return {
  //         ...obj,
  //         Type: CloudTypes.includes(obj.Type) ? 'Cloud' : 'On premises',
  //       };
  //     });

  const search = (data) => {
    return data.filter(
      (item) =>
        item.Partner_Client__c?.toLowerCase().includes(query.toLowerCase()) ||
        item.Type.toLowerCase().includes(query.toLowerCase()) ||
        item.StageName.toLowerCase().includes(query.toLowerCase()) ||
        item.CloseDate?.toLowerCase().includes(query.toLowerCase())
    );
  };

  const searchByStage = (data) => {
    return data.filter((item) =>
      item.StageName.toLowerCase().includes(stageType.toLowerCase())
    );
  };

  function filterAndSortArray(data, sortByProperty, sortDirection) {
    // Check if sortDirection is valid
    if (sortDirection !== 'ascending' && sortDirection !== 'descending') {
      throw new Error(
        'Invalid sortDirection. Use "ascending" or "descending".'
      );
    }

    // Clone the data array to avoid modifying the original array
    const clonedData = [...data];

    // Sort the array based on the sortByProperty
    clonedData.sort((a, b) => {
      const aValue = a[sortByProperty];
      const bValue = b[sortByProperty];

      // Compare the values based on the sortDirection
      if (sortDirection === 'ascending') {
        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;
        return 0;
      } else {
        if (aValue < bValue) return 1;
        if (aValue > bValue) return -1;
        return 0;
      }
    });

    return clonedData;
  }

  const body = filterAndSortArray(search(formatedDeal), sort, orientation).map(
    (data) => [
      data.Partner_Client__c,
      data.Type,
      data.CloseDate,
      data.StageName,
    ]
  );
  const header = ['Company', 'Service', 'Expiration Date', 'Stage'];

  function handleDownloadExcel() {
    downloadExcel({
      fileName: 'Excel Search',
      sheet: 'Search',
      tablePayload: {
        header,
        body: body,
      },
    });
  }
  return (
    <div className='flex flex-col'>
      <div className='flex justify-between bg-[#3D82F1] text-white p-5 h-[72px] 2xl:h-[76px] 2xl:pl-[40px] 2xl:pr-[26px] rounded-2xl m-3 lg:m-7'>
        <div className='flex items-center'>
          <h1 className='text-2xl 2xl:text-3xl font-semibold ml-2 md:ml-4 2xl:ml-0'>
            Search result
          </h1>
        </div>

        <div className='hidden md:flex items-center space-x-4 mr-4 2xl:mr-[9px] 2xl:ml-0 '>
          <div className='flex items-center'>
            <input
              type='text'
              id='website-admin'
              className='rounded-md h-8 2xl:h-10 relative pr-6 left-8 pl-3 bg-gray-50 border  text-gray-900 focus:ring-mainblue focus:border-mainblue block flex-1 min-w-0 w-full text-sm border-gray-300'
              placeholder='Search'
              onChange={(e) => setQuery(e.target.value)}
            />
            <span className='inline-flex relative right-2  items-center px-2 text-gray-900 bg-gray-[#DBE3EB] rounded-l-md  border-r-0'>
              <img alt='search' src={Search} />
            </span>
          </div>
          <button
            onClick={handleDownloadExcel}
            className='text-[#292929] h-8 2xl:h-10 text-sm xl:text-base hidden md:flex items-center bg-yellow-gradient btn rounded-lg p-2 ml-6'
          >
            <Export className='mr-1 w-4  xl:w-5 xl:h-4' fill='#292929' />
            Download
          </button>
        </div>
      </div>
      <div className='hidden md:block 2xl:my-[0px] 2xl:mx-[0px]'>
        {callLoading === false && formatedDeal.length < 1 ? (
          <div className='mx-8 flex justify-center items-center text-center  text-lg'>
            <img className='mt-8 mb-2 h-[300px]' src={SearchNotFound}></img>
          </div>
        ) : (
          <RegisteredDealsTable
            stage={stageType}
            loading={callLoading}
            setSort={setSort}
            setOrientation={setOrientation}
            // setLastSort={setLastSort}
            // setlastOrientation={setlastOrientation}
            data={filterAndSortArray(search(formatedDeal), sort, orientation)}
          />
        )}
      </div>
      {/* <MobileRegisteredDeals deals={formatedDeal} /> */}
    </div>
  );
};

export default SearchResult;
