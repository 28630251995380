import { useState } from 'react';
import WazuhWLogo from '../components/img/wazuh-w.svg';
import Phone from '../components/img/Phone.svg';
import XTwitter from '../components/img/XTwitter';
import LinkedIn from '../components/img/LinkedIn';
import Reddit from '../components/img/Reddit';
import GitHub from '../components/img/GitHub';
import Slack from '../components/img/Slack';
import GoogleGroup from '../components/img/GoogleGroup';
import Discord from '../components/img/Discord';
import { Link } from 'react-router-dom';
import WhiteRightArrow from '../components/img/WhiteRightArrow.svg';

const Footer = () => {
  const [isFooterOpen, setIsFooterOpen] = useState(false);

  return (
    <div
      className={`w-full 3xl:max-w-[1400px] shadow-lg lg:fixed z-10 bottom-0 transition-all duration-300 mt-[90px] ${
        isFooterOpen ? 'lg:h-[550px]' : 'lg:h-[100px]'
      } bg-black`}
    >
      <div className='bg-black w-full relative'>
        <div
          onClick={() => setIsFooterOpen(!isFooterOpen)}
          className={`bg-black h-8 ${
            isFooterOpen
              ? '-top-8'
              : ' bottom-[175px] md:bottom-32 lg:bottom-24'
          } hover:cursor-pointer rounded-tr-full rounded-tl-full w-12 absolute flex items-center justify-center`}
        >
          <img
            className={` ${isFooterOpen ? 'rotate-90' : 'rotate-[270deg]'}`}
            src={WhiteRightArrow}
          ></img>
        </div>
        <div>
          <div
            className={`${
              isFooterOpen ? 'grid' : 'hidden'
            } grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 mx-16 lg:mx-64`}
          >
            <div className='pb-10 flex flex-col pt-16'>
              <h3 className=' font-semibold mb-2 text-[17px] uppercase  inline-block text-white'>
                Explore
              </h3>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://wazuh.com/platform/overview/'
                className='text-white leading-[1.8rem] xl:leading-[2.11rem] hover:text-blue-500'
              >
                Overview
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://wazuh.com/platform/xdr/'
                className='text-white leading-[1.8rem] xl:leading-[2.11rem] hover:text-blue-500'
              >
                XDR
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://wazuh.com/platform/siem/'
                className='text-white leading-[1.8rem] xl:leading-[2.11rem] hover:text-blue-500'
              >
                SIEM
              </a>
            </div>
            <div className='pb-10 flex flex-col pt-16'>
              <h3 className=' font-semibold mb-2 text-[17px] uppercase  inline-block text-white'>
                Services
              </h3>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://wazuh.com/cloud/'
                className='text-white leading-[1.8rem] xl:leading-[2.11rem] hover:text-blue-500'
              >
                Wazuh Cloud
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://wazuh.com/services/professional-support/'
                className='text-white leading-[1.8rem] xl:leading-[2.11rem] hover:text-blue-500'
              >
                Professional support
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://wazuh.com/services/consulting-services/'
                className='text-white leading-[1.8rem] xl:leading-[2.11rem] hover:text-blue-500'
              >
                Consulting Services
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://wazuh.com/services/training-courses/'
                className='text-white leading-[1.8rem] xl:leading-[2.11rem] hover:text-blue-500'
              >
                Training courses
              </a>
            </div>
            <div className='pb-10 flex flex-col pt-16'>
              <h3 className=' font-semibold mb-2 text-[17px] uppercase  inline-block text-white'>
                Company
              </h3>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://wazuh.com/about-us/'
                className='text-white leading-[1.8rem] xl:leading-[2.11rem] hover:text-blue-500'
              >
                About us
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://wazuh.com/our-customers/'
                className='text-white leading-[1.8rem] xl:leading-[2.11rem] hover:text-blue-500'
              >
                Customers
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://wazuh.com/partners/'
                className='text-white leading-[1.8rem] xl:leading-[2.11rem] hover:text-blue-500'
              >
                Partners
              </a>
            </div>
            <div className='pb-10 flex flex-col'>
              <h3 className=' font-semibold mb-2 text-[17px] uppercase  inline-block text-white'>
                Documentation
              </h3>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://documentation.wazuh.com/current/quickstart.html'
                className='text-white leading-[1.8rem] xl:leading-[2.11rem] hover:text-blue-500'
              >
                Quickstart
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://documentation.wazuh.com/current/getting-started/index.html'
                className='text-white leading-[1.8rem] xl:leading-[2.11rem] hover:text-blue-500'
              >
                Getting started
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://documentation.wazuh.com/current/installation-guide/index.html'
                className='text-white leading-[1.8rem] xl:leading-[2.11rem] hover:text-blue-500'
              >
                Installation guide
              </a>
            </div>
            <div className='pb-10 flex flex-col'>
              <h3 className=' font-semibold mb-2 text-[17px] uppercase  inline-block text-white'>
                Resources
              </h3>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://wazuh.com/blog/'
                className='text-white leading-[1.8rem] xl:leading-[2.11rem] hover:text-blue-500'
              >
                Blog
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://wazuh.com/community/'
                className='text-white leading-[1.8rem] xl:leading-[2.11rem] hover:text-blue-500'
              >
                Community
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://wazuh.com/case-studies/'
                className='text-white leading-[1.8rem] xl:leading-[2.11rem] hover:text-blue-500'
              >
                Case studies
              </a>
            </div>
          </div>
        </div>
        <div className='flex flex-col lg:flex-row justify-between text-[13px] 2xl:text-sm h-44 md:h-32 lg:h-24'>
          <div className='grid grid-cols-2 mx-12 gap-y-2  md:flex mt-8 lg:mt-0 justify-center lg:justify-start items-center 2xl:w-3/5 text-white md:ml-6 lg:ml-20'>
            <img
              className='w-[32px] mr-4 hidden md:block'
              src={WazuhWLogo}
            ></img>
            <div className='pr-3 2xl:pr-7'>© 2025 Wazuh Inc.</div>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://wazuh.com/legal-resources/terms-of-use/'
              className='text-end md:text-start md:border-l border-white md:py-1 md:px-3 2xl:px-7 hover:text-[#3D82F1]'
            >
              Legal resources
            </a>
            <Link
              to='/contact-us/sales-team'
              className='md:border-l border-white md:py-1 md:px-3 2xl:px-7 hover:text-[#3D82F1]'
            >
              Contact us
            </Link>
            <a
              href='tel:+18443492984'
              className='justify-end md:justify-start content-end md:content-start md:border-l flex border-white md:py-1 md:px-3 2xl:px-7 hover:text-[#3D82F1]'
            >
              <img className='mr-2' src={Phone}></img>
              +5 (844) 549 2984
            </a>
          </div>
          <div className='flex justify-center lg:justify-end mb-8 md:mb-4 lg:mb-0 items-center lg:mr-12 2xl:mr-20 xl:w-2/5 text-white'>
            <div className='flex'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://x.com/wazuh'
              >
                <XTwitter className='h-5 mr-4  text-white hover:text-blue-500' />
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.linkedin.com/company/wazuh'
              >
                <LinkedIn className='h-5 mr-4  text-white hover:text-blue-500' />
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.reddit.com/r/Wazuh/'
              >
                <Reddit className='h-5 mr-4  text-white hover:text-blue-500' />
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://github.com/wazuh'
              >
                <GitHub className='h-5 mr-4  text-white hover:text-blue-500' />
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://discord.gg/rg9eZTtC7W'
              >
                <Discord className='h-5 mr-4  text-white hover:text-blue-500' />
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://join.slack.com/t/wazuh/shared_invite/zt-2e0nak5th-o6CoT_SqJA34KWVdbYGfaw'
              >
                <Slack className='h-5 mr-4  text-white hover:text-blue-500' />
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://groups.google.com/g/wazuh/'
              >
                <GoogleGroup className='h-5 mr-4  text-white hover:text-blue-500' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
