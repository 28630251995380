import React, { useState, useEffect } from 'react';
import ChartInfo from '../Popover/ChartInfo';
import {
  ResponsiveContainer,
  Pie,
  PieChart,
  Cell,
  Tooltip,
  Legend,
} from 'recharts';
import Oval from '../img/Oval.svg';

const RADIAN = Math.PI / 180;

const calculateInnerRadius = (resolution) => {
  if (resolution <= 640) {
    return 80; // Adjust this value as needed
  } else if (resolution > 640 && resolution <= 768) {
    return 60; // Adjust this value as needed
  } else if (resolution > 768 && resolution <= 1024) {
    return 60;
  } else if (resolution > 1024 && resolution <= 1280) {
    return 60;
  } else return 80;
};

const COLORS = [
  '#3585F9',
  '#3240FF', //Nuevo azul 1
  '#48E3FF', //Nuevo Cyan
  '#0BFFDB', //Nuevo teal
  '#FCDE30',
  '#FA704D',
  '#D54639',
  '#C00462',
  '#6BCEBA',
  '#7840BF',
  '#409ABF',
  '#E84DC3',
];

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const backgroundWidth = 40; // Width of the background box
  const backgroundHeight = 30; // Height of the background box
  const padding = 4; // Padding on the left and right

  return (
    <g>
      <>
        {(percent * 100).toFixed(0) > 5 ? (
          <>
            <rect
              x={x - backgroundWidth / 2 - padding}
              y={y - backgroundHeight / 2}
              width={backgroundWidth + padding * 2}
              height={backgroundHeight}
              rx={15}
              ry={20}
              fill='rgba(0, 0, 0, 0.2)'
            />
            <text
              fontSize={14}
              x={x}
              y={y}
              fill='white'
              textAnchor='middle'
              dominantBaseline='central'
            >
              {`${(percent * 100).toFixed(0)}%`}
            </text>
          </>
        ) : (
          <></>
        )}
      </>
    </g>
  );
};

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const PartnerCurrentContracts = ({ customers, loading }) => {
  const totalAmount = customers?.reduce((accumulator, currentObject) => {
    return accumulator + currentObject.Amount;
  }, 0);

  const CustomLegend = ({ data, limit }) => {
    const visibleData = data.slice(0, 3);

    return (
      <div className='flex flex-col relative bottom-2'>
        <div className='flex items-center justify-center custom-scrollbar'>
          <div className='overflow-y-auto flex md:block overflow-x-scroll md:overflow-x-hidden md:max-h-[220px] max-w-[200px] text-ellipsis'>
            {data.map((entry, index) => (
              <div
                key={index}
                className='flex py-[2px]  items-center mr-[10px]'
              >
                <span
                  className=' w-[10px] h-[10px] rounded-xl mr-1'
                  style={{ backgroundColor: COLORS[index % COLORS.length] }}
                />
                <span className='whitespace-nowrap overflow-ellipsis md:max-w-[120px] lg:max-w-[150px] xl:max-w-[100px] text-sm 2xl:text-base  2xl:max-w-[150px] truncate'>
                  {entry.Partner_Client__c}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className='bg-white border-2 rounded-lg border-gray-300 p-4'>
          {payload.map((pld, index) => (
            <div style={{ color: pld.payload.fill }}>
              {pld.name}: ${pld.value.toLocaleString()}
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  const screenResolution = screenSize.width;

  return (
    <div className=''>
      <div className=''>
        <div className='flex items-center '>
          <h1 className='text-[22px] mr-1 font-semibold text-left'>
            Partner Current Contracts
          </h1>
          <ChartInfo
            text={
              'Shows all your current active partner contracts with the percentage based on the total amount displayed. Includes only recurrent services, does not include professional hours and trainings.'
            }
          />
        </div>
        <span className='form-divider h-1 mr-0 lg:mr-6 2xl:mr-3 hidden md:block mt-2 mb-7'></span>
      </div>
      {loading === true ? (
        <div className='flex items-center justify-center bg-[#F6F6F6] h-[450px]'>
          <img src={Oval}></img>
        </div>
      ) : loading === false && customers.length <= 0 ? (
        <div className='flex items-center justify-center bg-[#F6F6F6] h-[450px]'>
          Register your first customer to view this chart
        </div>
      ) : (
        <div className='rounded-lg bg-[#F6F6F6] px-2 pt-2  '>
          <div className='flex justify-center'>
            <div className='flex  w-[260px] text-center justify-center items-center border border-gray-300 rounded-lg relative py-2 top-4'>
              <div className='flex xl:flex-col 2xl:flex-row xl:text-center 2xl:text-start'>
                <h1 className='font-semibold leading-tight 2xl:text-lg text-[#3D82F1]'>
                  Total revenue:&nbsp;
                </h1>
                <h3 className='2xl:text-lg leading-tight'>
                  {customers?.length && numberWithCommas(`$ ${totalAmount}`)}
                </h3>
              </div>
            </div>
          </div>
          <div className='flex flex-col relative md:right-6 items-center h-[394px]'>
            <ResponsiveContainer width='100%' height={392}>
              <PieChart
                margin={{
                  right: 40,
                }}
              >
                <Pie
                  className='focus:outline-none'
                  data={customers}
                  dataKey='Amount'
                  nameKey='Partner_Client__c'
                  fill='#8884d8'
                  innerRadius={calculateInnerRadius(screenResolution)}
                  labelLine={false}
                  label={renderCustomizedLabel}
                >
                  {customers.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>

                <div className='flex  items-center border border-gray-300 rounded-lg p-2 mt-2'>
                  <div className='flex '>
                    <h1 className='font-semibold leading-tight text-lg text-[#3D82F1]'>
                      Total revenue:&nbsp;
                    </h1>
                    <h3 className='text-lg leading-tight'>
                      {customers?.length &&
                        numberWithCommas(`$ ${totalAmount}`)}
                    </h3>
                  </div>
                </div>
                <Legend
                  wrapperStyle={
                    screenResolution > 700 && screenResolution < 1023
                      ? {
                          marginRight: 50,
                          backgroundColor: 'white',
                          borderRadius: '10px',
                          padding: '10px 10px 0px 10px',
                        }
                      : {
                          marginRight: 0,
                          backgroundColor: 'white',
                          borderRadius: '10px',
                          padding: '10px 10px 0px 10px',
                        }
                  }
                  layout='vertical'
                  verticalAlign={screenResolution < 768 ? 'bottom' : 'middle'}
                  align={screenResolution < 768 ? 'center' : 'right'}
                  content={<CustomLegend data={customers} limit={3} />}
                ></Legend>
                <Tooltip content={<CustomTooltip />} />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </div>
  );
};

export default PartnerCurrentContracts;
