import React, { useState, useEffect } from 'react';
import {
  useGetPartnerByIdMutation,
  useGetPartnerDetailsMutation,
  useGetPartnerDatesMutation,
} from '../redux/features/user/partnersApiSlice';

import useAuth from '../hooks/useAuth';

//Icons
import Company from '../components/img/Company.svg';
import Location from '../components/img/Location.svg';
import Discount from '../components/img/Discount.js';
import Flag from '../components/img/Flag.js';
import Calendar from '../components/img/Calendar.js';
import Oval from '../components/img/Oval.svg';
import ProfilePlanBenefits from '../components/Modals/ProfilePlanBenefits';
import ProfileLevels from '../components/Modals/ProfileLevels';
import { useGetCustomersPastYearMutation } from '../redux/features/user/dealsApiSlice.js';
import LogoOnWebsiteInfo from '../components/Popover/LogoOnWebsiteInfo.js';

function reformatDate(dateStr) {
  let dArr = dateStr.split('-'); // ex input "2010-01-18"
  return dArr[1] + '/' + dArr[2] + '/' + dArr[0]; //ex out: "18/01/10"
}

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [partnerData, setPartnerData] = useState(null);
  const [customersPastYear, setCustomersPastYear] = useState(0);
  const [partnerDetails, setPartnerDetails] = useState([]);
  const [partnerDates, setPartnerDates] = useState([]);
  const userinfo = useAuth();
  const [getPartner] = useGetPartnerByIdMutation();
  const [getPartnerDetail] = useGetPartnerDetailsMutation();
  const [getPartnerDates] = useGetPartnerDatesMutation();
  const [getCustomersLastYear] = useGetCustomersPastYearMutation();

  // useEffect(() => {
  //   const getProfilePic = async () => {
  //     const result = await axios.get(
  //       `${process.env.REACT_APP_API_URL}/api/s3img/profile/${userinfo.partnerId}`
  //     );
  //     setProfilePicc(result.data);
  //   };
  //   getProfilePic();
  // }, []);

  useEffect(() => {
    const getPartnerData = async () => {
      const result = await getPartner(userinfo.partnerId).unwrap();
      setPartnerData(result);
    };
    getPartnerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getPartnerDetailsFunction = async () => {
      if (partnerData && partnerData.length > 0) {
        const partnerInfo = await getPartnerDetail(
          partnerData[0].salesforceId
        ).unwrap();
        setPartnerDetails(partnerInfo[0]);
      }
    };
    getPartnerDetailsFunction();
  }, [partnerData]);

  useEffect(() => {
    const getPartnerDatesFunction = async () => {
      if (partnerData && partnerData.length > 0) {
        const result = await getPartnerDates(
          partnerData[0].salesforceId
        ).unwrap();
        setPartnerDates(result);
        setLoading(true);
      }
    };
    getPartnerDatesFunction();
  }, [partnerData]);

  useEffect(() => {
    const getCustomersLastYearAmount = async () => {
      if (partnerData && partnerData.length > 0) {
        const result = await getCustomersLastYear(
          partnerData[0].salesforceId
        ).unwrap();
        setCustomersPastYear(result);
      }
    };
    getCustomersLastYearAmount();
  }, [partnerData]);

  return (
    <div className=''>
      {!loading ? (
        <div className='flex mt-[50vh] -translate-y-32 justify-center items-center'>
          <img alt='loading' className='w-32' src={Oval} />
        </div>
      ) : (
        <>
          {/* <div className='sm:hidden'>
            <MobileUserData />
          </div> */}
          <div className='text-2xl 2xl:text-3xl font-semibold p-5 h-[72px] lg:h-[100px] xl:h-[125px] 2xl:h-[150px] xl:pl-[22px] 2xl:px-[40px] text-white header-background flex items-center rounded-2xl m-3 lg:m-7'>
            Company
          </div>
          <div className='m-5 xl:mx-[40px] 2xl:mx-[55px]'>
            <h1 className='text-[22px] font-semibold text-left max-md:mb-[30px]'>
              Information
            </h1>

            <span className='form-divider h-1 mr-0 lg:mr-6 2xl:mr-3 hidden md:block mt-2 mb-7'></span>
            <div className='flex pt-4 pb-8 w-full justify-between'>
              <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-16 max-sm:ml-1 sm:gap-x-8 2xl:gap-x-64 gap-y-12 2xl:gap-y-12'>
                <div className='flex items-center order-1'>
                  <div>
                    <img
                      alt='company'
                      className='mr-2 w-[41px]'
                      src={Company}
                    ></img>
                  </div>
                  <div className='flex'>
                    <h1 className='font-semibold leading-tight text-lg text-[#3D82F1]'>
                      Company:&nbsp;
                    </h1>
                    <h3 className='text-lg leading-tight'>
                      {partnerData[0].name}
                    </h3>
                  </div>
                </div>

                <div className='flex items-center order-2'>
                  <div>
                    <img
                      alt='company'
                      className='mr-2 w-[41px]'
                      src={Location}
                    ></img>
                  </div>
                  <div className='flex'>
                    <h1 className='font-semibold leading-tight text-lg text-[#3D82F1]'>
                      Location:&nbsp;
                    </h1>
                    <h3 className='text-lg leading-tight'>
                      {partnerDetails.BillingCountry
                        ? partnerDetails.BillingCountry
                        : '-'}
                    </h3>
                  </div>
                </div>

                {/* <div className='flex order-2 lg:order-3'>
                  <div className='mt-1'>
                    <img alt='flag' className='mr-2 h-6' src={Flag}></img>
                  </div>
                  <div className='flex flex-col'>
                    <h1 className=' font-semibold text-lg'>Actual Plan</h1>
                    <h3 className=' text-base'>
                      {' '}
                      {partnerDetails.Account_Type__c
                        ? partnerDetails.Account_Type__c
                        : '-'}
                    </h3>
                  </div>
                </div> */}

                <div className='flex items-center order-3'>
                  <div>
                    <Calendar
                      className='mr-2 w-[41px] h-[41px]'
                      bgfill={'#EBF2FB'}
                      mainfill={'#3D82F1'}
                    />
                  </div>
                  <div className='flex'>
                    <h1 className='font-semibold leading-tight text-lg text-[#3D82F1]'>
                      Partner since:&nbsp;
                    </h1>
                    <h3 className='text-lg leading-tight'>
                      {partnerDates.StartDate
                        ? reformatDate(partnerDates.StartDate)
                        : '-'}
                    </h3>
                  </div>
                </div>

                <div className='flex items-center order-4'>
                  <div>
                    <Calendar
                      className='mr-2 w-[41px] h-[41px]'
                      bgfill={'#EBF2FB'}
                      mainfill={'#3D82F1'}
                    />
                  </div>
                  <div className='flex'>
                    <h1 className='font-semibold leading-tight text-lg text-[#3D82F1]'>
                      Expiration date:&nbsp;
                    </h1>
                    <h3 className='text-lg leading-tight'>
                      {partnerDates.ExpirationDate
                        ? reformatDate(partnerDates.ExpirationDate)
                        : '-'}
                    </h3>
                  </div>
                </div>

                <div className='flex items-center order-5'>
                  <div>
                    <Flag
                      className='mr-2 h-[41px] w-[41px]'
                      bgfill={'#EBF2FB'}
                      mainfill={'#3D82F1'}
                    />
                  </div>
                  <div className='flex'>
                    <h1 className='font-semibold leading-tight text-lg text-[#3D82F1]'>
                      Actual Plan:&nbsp;
                    </h1>
                    <h3 className='text-lg leading-tight'>
                      {partnerDetails.Account_Type__c
                        ? partnerDetails.Account_Type__c
                        : '-'}
                    </h3>
                  </div>
                </div>

                <div className='flex items-center order-6'>
                  <div>
                    <Discount
                      bgfill={'#EBF2FB'}
                      mainfill={'#3D82F1'}
                      className='h-[41px] w-[41px] mr-2'
                    />
                  </div>
                  <div className='flex flex-col w-[160px]'>
                    <div className='flex relative'>
                      <h1 className='font-semibold leading-tight text-lg text-[#3D82F1] '>
                        Discount:&nbsp;
                      </h1>
                      <h3 className='text-lg leading-tight '>
                        {partnerDetails.Account_Type__c === 'Platinum'
                          ? partnerDetails.Partner_Current_Discount__c
                            ? partnerDetails.Partner_Current_Discount__c >= 0 &&
                              partnerDetails.Partner_Current_Discount__c <= 21
                              ? '18'
                              : partnerDetails.Partner_Current_Discount__c >
                                  21 &&
                                partnerDetails.Partner_Current_Discount__c <= 25
                              ? '22'
                              : partnerDetails.Partner_Current_Discount__c >
                                  25 &&
                                partnerDetails.Partner_Current_Discount__c <= 29
                              ? '26'
                              : '30'
                            : '-'
                          : partnerDetails.Account_Type__c === 'Gold'
                          ? partnerDetails.Partner_Current_Discount__c
                            ? partnerDetails.Partner_Current_Discount__c >= 0 &&
                              partnerDetails.Partner_Current_Discount__c <= 13
                              ? '10'
                              : partnerDetails.Partner_Current_Discount__c >
                                  13 &&
                                partnerDetails.Partner_Current_Discount__c <= 17
                              ? '14'
                              : partnerDetails.Partner_Current_Discount__c >
                                  17 &&
                                partnerDetails.Partner_Current_Discount__c <= 21
                              ? '18'
                              : '22'
                            : '-'
                          : partnerDetails.Account_Type__c === 'Old MSSP'
                          ? partnerDetails.Partner_Current_Discount__c
                          : '-'}
                        % off
                      </h3>

                      <div className='absolute top-7 '>
                        {partnerDetails ? (
                          <>
                            <ProfileLevels
                              discount={
                                partnerDetails.Partner_Current_Discount__c
                              }
                              plan={partnerDetails.Account_Type__c}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex items-center order-7'>
                  <div>
                    <Flag
                      className='mr-2 w-[41px] h-[41px]'
                      bgfill={'#EBF2FB'}
                      mainfill={'#3D82F1'}
                    />
                  </div>
                  <div className='flex'>
                    <h1 className='font-semibold leading-tight text-lg text-[#3D82F1]'>
                      Logo on website:&nbsp;
                    </h1>
                    <h3 className='text-lg leading-tight mr-2'>
                      {customersPastYear >= 2 ? 'Yes' : 'No'}
                    </h3>
                    <div className='relative top-[2px]'>
                      <LogoOnWebsiteInfo
                        text={
                          'To have your logo displayed on our website, you should have at least 2 active customers closed in the past year.'
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className='order-8 md:col-start-2 xl:col-start-3 flex relative top-2'>
                  {partnerDetails ? (
                    <ProfilePlanBenefits
                      plan={partnerDetails.Account_Type__c}
                      discount={partnerDetails.Partner_Current_Discount__c}
                    />
                  ) : (
                    <ProfilePlanBenefits discount={0} plan={'-'} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Profile;
