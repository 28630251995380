const Reddit = (props) => (
  <svg
    viewBox='0 0 19 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clip-path='url(#a)'>
      <path
        d='M18.382 8.834a1.99 1.99 0 0 0-3.373-1.424c-1.357-.893-3.194-1.462-5.228-1.535l1.112-3.501 3.012.705-.004.044c0 .895.73 1.622 1.63 1.622s1.63-.727 1.63-1.622a1.628 1.628 0 0 0-3.146-.589l-3.246-.761a.28.28 0 0 0-.33.187l-1.24 3.905c-2.13.026-4.058.598-5.476 1.519a1.98 1.98 0 0 0-1.35-.534 1.99 1.99 0 0 0-1.99 1.984c0 .728.4 1.359.988 1.704a3.5 3.5 0 0 0-.065.642c0 2.934 3.606 5.32 8.04 5.32s8.04-2.386 8.04-5.32a3.6 3.6 0 0 0-.057-.607 1.98 1.98 0 0 0 1.054-1.739M5.464 10.196a1.186 1.186 0 0 1 2.372 0c0 .651-.532 1.18-1.186 1.18s-1.186-.529-1.186-1.18m6.796 3.502c-.598.595-1.536.884-2.868.884l-.01-.002-.01.002c-1.333 0-2.27-.29-2.868-.884a.277.277 0 0 1 0-.392.28.28 0 0 1 .395 0c.487.485 1.296.72 2.473.72l.01.003.01-.002c1.176 0 1.986-.237 2.473-.722a.28.28 0 0 1 .395 0 .28.28 0 0 1 0 .393m-.142-2.321c-.654 0-1.186-.53-1.186-1.18a1.185 1.185 0 0 1 2.372 0c0 .65-.532 1.18-1.186 1.18'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='currentColor' d='M.382 0h18v18h-18z' />
      </clipPath>
    </defs>
  </svg>
);
export default Reddit;
