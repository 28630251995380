const GoogleGroup = (props) => (
  <svg
    viewBox='0 0 13 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clip-path='url(#a)'>
      <path
        d='M9.777 5.551c.968 0 1.935.004 2.903-.002.17-.001.245.043.265.221.221 2.06-.2 3.932-1.703 5.469-.907.926-2.046 1.44-3.328 1.654-1.038.173-2.07.143-3.088-.141a6.55 6.55 0 0 1-3.385-2.208A6.25 6.25 0 0 1 .034 7.145a6.26 6.26 0 0 1 .563-3.34C1.31 2.29 2.452 1.197 4.008.526a6.6 6.6 0 0 1 2.25-.515 6.7 6.7 0 0 1 3.277.627A6.4 6.4 0 0 1 11 1.599c.092.079.094.138.005.22q-.799.746-1.591 1.5c-.099.092-.167.078-.266-.008-.642-.552-1.399-.81-2.248-.866a4.05 4.05 0 0 0-3.97 2.363c-1.046 2.338.23 5.145 2.913 5.666 1.412.274 2.72-.014 3.745-1.101a3.15 3.15 0 0 0 .722-1.285c.075-.255.061-.264-.207-.264H6.962q-.342 0-.342-.344c0-.559.007-1.118-.004-1.676-.004-.194.055-.259.258-.257.968.009 1.936.004 2.904.004'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='currentColor' d='M0 0h13v13H0z' />
      </clipPath>
    </defs>
  </svg>
);
export default GoogleGroup;
