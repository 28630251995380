const ArrowPagination = (props) => (
  <svg
    width='6'
    height='13'
    viewBox='0 0 6 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M5.78126 0.215784C6.06358 0.513229 6.07399 1.00696 5.80449 1.31857L1.47858 6.32048C1.39169 6.42095 1.39169 6.57905 1.47858 6.67952L5.80449 11.6814C6.07399 11.993 6.06358 12.4868 5.78126 12.7842C5.49893 13.0817 5.05159 13.0702 4.78209 12.7586L0.456182 7.75667C-0.152061 7.05337 -0.152061 5.94663 0.456182 5.24333L4.78209 0.24143C5.05159 -0.0701784 5.49893 -0.0816606 5.78126 0.215784Z'
      fill={props.mainfill}
    />
  </svg>
);
export default ArrowPagination;
