import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SmallBlackArrow from '../components/img/SmallBlackArrow.svg';
import BlackBurger from '../components/img/BlackBurger.svg';
import CardsIcon from '../components/img/CardsIcon.svg';
import Export from '../components/img/Export';
import ResourcesCard from '../components/ResourcesCard';
import Search from '../components/img/Search.svg';
import Back from '../components/img/Back.svg';
import Oval from '../components/img/Oval.svg';
import BlueLeftArrow from '../components/img/BlueLeftArrow.svg';
import BlueTriangle from '../components/img/BlueTriangle.svg';
import { DownloadFiles } from '../hooks/DownloadFiles';

const Help = () => {
  const [loading, setLoading] = useState(true);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [currentView, setCurrentView] = useState('list');
  const [currentFolder, setCurrentFolder] = useState('root');
  const [salesWithInfo, setSalesWithInfo] = useState([]);
  const [MarketingWithInfo, setMarketingWithInfo] = useState([]);
  const [legalWithInfo, setLegalWithInfo] = useState([]);
  const [battleWithInfo, setBattleWithInfo] = useState([]);
  const [loadingSales, setLoadingSales] = useState(true);
  const [loadingMarketing, setLoadingMarketing] = useState(true);
  const [loadingLegal, setLoadingLegal] = useState(true);
  const [loadingBattle, setLoadingBattle] = useState(true);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState('Name');
  const [orientation, setOrientation] = useState('ascending');
  const [lastSort, setLastSort] = useState('Name');
  const [lastOrientation, setLastOrientation] = useState('ascending');
  const [salesFolderOpen, setSalesFolderOpen] = useState(false);
  const [marketingFolderOpen, setMarketingFolderOpen] = useState(false);
  const [legalFolderOpen, setLegalFolderOpen] = useState(false);
  const [battlecardsFolderOpen, setBattlecardsFolderOpen] = useState(false);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  const url = `${process.env.REACT_APP_API_URL}/api/`;

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  const screenResolution = screenSize.width;

  const columnAmount =
    screenResolution < 640
      ? 1
      : screenResolution <= 768
      ? 2
      : screenResolution <= 1100
      ? 3
      : screenResolution <= 1536
      ? 4
      : 5;

  const rootColumnAmount =
    screenResolution < 640
      ? 1
      : screenResolution <= 768
      ? 2
      : screenResolution <= 1100
      ? 3
      : screenResolution <= 1536
      ? 4
      : 5;

  // const rootColumnAmount =
  //   screenResolution < 640
  //     ? 1
  //     : screenResolution <= 768
  //     ? 2
  //     : screenResolution <= 1100
  //     ? 3
  //     : 4;

  const setViewToList = () => {
    setCurrentView('list');
    setCurrentFolder('root');
  };

  const setViewToCards = () => {
    setCurrentView('cards');
    setCurrentFolder('root');
  };

  const handleFolder = (folderName) => {
    if (folderName === 'Sales') {
      setSalesFolderOpen(!salesFolderOpen);
    } else if (folderName === 'Marketing') {
      setMarketingFolderOpen(!marketingFolderOpen);
    } else if (folderName === 'Legal') {
      setLegalFolderOpen(!legalFolderOpen);
    } else if (folderName === 'BattleCards') {
      setBattlecardsFolderOpen(!battlecardsFolderOpen);
    }
  };

  const salesFiles = [
    {
      Name: 'Wazuh Partnership',
      File: 'Wazuh Partnership 101.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Sales Training for Wazuh Partners',
      File: 'Sales-Training-for-Wazuh-Partners.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Wazuh Service Offerings',
      File: 'Wazuh_Service_Offerings_2024.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Wazuh Demo Credentials',
      File: 'Wazuh_Demo_Credentials.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Wazuh Training Course Syllabus',
      File: 'Wazuh_TrainingCourseSyllabus.pdf',
      Mime: 'application/pdf',
    },
  ];

  const marketingFiles = [
    {
      Name: 'Wazuh Brochure',
      File: 'Wazuh-Brochure.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Wazuh overview - English version',
      File: 'Marketing-Material-Complete-Eng.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Wazuh overview - Spanish version',
      File: 'Marketing-Material-Complete-Esp.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Wazuh Cloud versus On prem deployment',
      File: 'Comparison-of-Wazuh-Cloud-and-on-prem.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Wazuh Service Offerings',
      File: 'Wazuh_Service_Offerings_2024.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Wazuh File Integrity Monitoring',
      File: 'Wazuh-File-integrity-Monitoring.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Wazuh Office 365',
      File: 'Wazuh-Office365.pdf',
      Mime: 'application/pdf',
    },
  ];

  const legalFiles = [
    {
      Name: 'Wazuh Partners Program Terms',
      File: 'Partner-Program-terms.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Partner Program Terms June Update Notice',
      File: 'Partner-Program-Terms-June-Notice.pdf',
      Mime: 'application/pdf',
    },
  ];

  const battleCardsFiles = [
    {
      Name: 'AlertLogic',
      File: 'Wazuh vs AlertLogic.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Alienvault OSSIM',
      File: 'Wazuh vs Alienvault OSSIM.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Alienvault USM',
      File: 'Wazuh vs Alienvault USM.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Amazon Guard Duty',
      File: 'Wazuh vs Amazon Guardduty.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'ArcSight',
      File: 'Wazuh vs ArcSight.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'AWS Inspector',
      File: 'Wazuh vs AWS Inspector.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'AWS Security Hub',
      File: 'Wazuh vs AWS Security Hub.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Camel Secure',
      File: 'Wazuh vs Camel Secure.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Centreon Monitoring',
      File: 'Wazuh vs Centreon Monitoring.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Chronicle',
      File: 'Wazuh vs Chronicle.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Cisco Secure X',
      File: 'Wazuh vs Cisco Secure X.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Crowdstrike',
      File: 'Wazuh vs Crowdstrike.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Cybereason',
      File: 'Wazuh vs Cybereason.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Cynet',
      File: 'Wazuh vs Cynet.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Cyrebro',
      File: 'Wazuh vs Cyrebro.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'DarkTrace',
      File: 'Wazuh vs DarkTrace.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Datadog',
      File: 'Wazuh vs Datadog.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Elastic',
      File: 'Wazuh vs Elastic.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Exabeam',
      File: 'Wazuh vs Exabeam.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'FortiSIEM',
      File: 'Wazuh vs FortiSIEM.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'FortiXDR',
      File: 'Wazuh vs FortiXDR.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'IBM QRadar',
      File: 'Wazuh vs IBM QRadar.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Logpoint',
      File: 'Wazuh vs Logpoint.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'LogRhythm',
      File: 'Wazuh vs LogRhythm.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Lumu',
      File: 'Wazuh vs Lumu.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'ManageEngine EventLog Analyzer',
      File: 'Wazuh vs ManageEngine EventLog Analyzer.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'McAfee',
      File: 'Wazuh vs McAfee.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Microsoft 365 Defender',
      File: 'Wazuh vs Microsoft 365 Defender.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Microsoft Sentinel',
      File: 'Wazuh vs Microsoft Sentinel.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Palo Alto Cortex',
      File: 'Wazuh vs Palo Alto Cortex.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Qualys',
      File: 'Wazuh vs Qualys.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Rapid7',
      File: 'Wazuh vs Rapid7.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Security Onion',
      File: 'Wazuh vs Security Onion.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Securonix',
      File: 'Wazuh vs Securonix.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Sentinelone',
      File: 'Wazuh vs Sentinelone.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Sophos',
      File: 'Wazuh vs Sophos.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Splunk',
      File: 'Wazuh vs Splunk.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Stellar Cyber',
      File: 'Wazuh vs Stellar Cyber.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'SureLog',
      File: 'Wazuh vs SureLog.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Tenable',
      File: 'Wazuh vs Tenable.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Trellix',
      File: 'Wazuh vs Trellix.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Trend Micro',
      File: 'Wazuh vs Trend Micro.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'UTMStack',
      File: 'Wazuh vs UTMStack.pdf',
      Mime: 'application/pdf',
    },
    {
      Name: 'Xcitium',
      File: 'Wazuh vs Xcitium.pdf',
      Mime: 'application/pdf',
    },
  ];

  const fetchLastModifiedDate = async (file) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/s3img/lastmodifieddate`,
      {
        file: file,
      }
    );
    return response.data;
  };

  useEffect(() => {
    const fetchSalesInformation = async () => {
      try {
        const salesInformation = await fetchLastModifiedDate(salesFiles);
        setSalesWithInfo(salesInformation);
        setLoadingSales(false);
      } catch (error) {
        console.error('Error fetching last modified dates for sales:', error);
      }
    };

    const fetchMarketingInformation = async () => {
      try {
        const marketingInformation = await fetchLastModifiedDate(
          marketingFiles
        );
        setMarketingWithInfo(marketingInformation);
        setLoadingMarketing(false);
      } catch (error) {
        console.error(
          'Error fetching last modified dates for marketing:',
          error
        );
      }
    };

    const fetchLegalInformation = async () => {
      try {
        const legalInformation = await fetchLastModifiedDate(legalFiles);
        setLegalWithInfo(legalInformation);
        setLoadingLegal(false);
      } catch (error) {
        console.error('Error fetching last modified dates for legal:', error);
      }
    };

    const fetchBattleInformation = async () => {
      try {
        const battleInformation = await fetchLastModifiedDate(battleCardsFiles);
        setBattleWithInfo(battleInformation);
        setLoadingBattle(false);
      } catch (error) {
        console.error(
          'Error fetching last modified dates for battlecards:',
          error
        );
      }
    };

    fetchSalesInformation();
    fetchMarketingInformation();
    fetchLegalInformation();
    fetchBattleInformation();
  }, []);

  const getMostRecentDate = (array) => {
    if (!array || array.length === 0) {
      return null;
    }

    return array
      .reduce((mostRecent, current) => {
        const currentDate = new Date(current.LastModified);
        return currentDate > mostRecent ? currentDate : mostRecent;
      }, new Date(array[0].LastModified))
      .toISOString();
  };

  const getTotalFileSize = (array) => {
    if (!array || array.length === 0) {
      return 0;
    }

    return array.reduce((total, current) => {
      return total + (current.fileSize || 0);
    }, 0);
  };

  useEffect(() => {
    if (!loadingSales && !loadingMarketing && !loadingLegal && !loadingBattle) {
      setLoading(false);
    }
  }, [loadingSales, loadingMarketing, loadingLegal, loadingBattle]);

  const convertToDateMonthYear = (isoDateString) => {
    const date = new Date(isoDateString);

    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const monthName = monthNames[monthIndex].slice(0, 3);

    const formattedDate = `${monthName} ${year}`;

    return formattedDate;
  };

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [
      'Bytes',
      'KiB',
      'MiB',
      'GiB',
      'TiB',
      'PiB',
      'EiB',
      'ZiB',
      'YiB',
    ];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  const folders = [
    {
      Name: 'Sales',
      data: salesWithInfo,
    },
    {
      Name: 'Marketing',
      data: MarketingWithInfo,
    },
    {
      Name: 'Legal',
      data: legalWithInfo,
    },
    {
      Name: 'BattleCards',
      data: battleWithInfo,
    },
  ];

  const search = (data) => {
    return data.filter(
      (item) =>
        item.Name?.toLowerCase().includes(query.toLowerCase()) ||
        convertToDateMonthYear(item.LastModified)
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        item.fileSize.toLowerCase().includes(query.toLowerCase())
    );
  };

  function filterAndSortArray(data, sortByProperty, sortDirection) {
    if (sortDirection !== 'ascending' && sortDirection !== 'descending') {
      throw new Error(
        'Invalid sortDirection. Use "ascending" or "descending".'
      );
    }

    const clonedData = [...data];

    clonedData.sort((a, b) => {
      const aValue = a[sortByProperty];
      const bValue = b[sortByProperty];

      if (sortDirection === 'ascending') {
        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;
        return 0;
      } else {
        if (aValue < bValue) return 1;
        if (aValue > bValue) return -1;
        return 0;
      }
    });

    return clonedData;
  }

  const setSortAndOrientation = (orientation, sort) => {
    if (orientation === lastOrientation && sort === lastSort) {
      setOrientation('descending');
      setSort(sort);
      setLastOrientation('descending');
    } else {
      setOrientation('ascending');
      setSort(sort);
      setLastOrientation('ascending');
    }
    setLastSort(sort);
  };

  const processFolders = (folders) => {
    return folders.map((folder) => {
      const { data, ...rest } = folder;
      const lastModifiedDate = getMostRecentDate(data);
      const totalFileSize = getTotalFileSize(data);
      return {
        ...rest,
        LastModified: lastModifiedDate,
        fileSize: formatBytes(totalFileSize),
      };
    });
  };

  const processFiles = (files) => {
    return files.map((file) => ({
      ...file,
      fileSize: formatBytes(file.fileSize),
    }));
  };

  const chunkArray = (array, size) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size));
    }
    return chunks;
  };

  return (
    <div className=''>
      <div className='text-lg sm:text-2xl 2xl:text-3xl justify-between font-semibold p-5 h-[72px] lg:h-[100px] xl:h-[125px] 2xl:h-[150px] xl:pl-[22px] 2xl:pl-[40px] text-white header-background flex items-center rounded-2xl m-3 lg:m-7'>
        Resources
        <div className='flex'>
          {/* <span className='inline-flex relative left-9  items-center px-2 text-gray-900 bg-gray-[#DBE3EB] rounded-l-md  border-r-0'>
            <img alt='search' src={Search} />
          </span>
          <input
            type='text'
            id='website-admin'
            className='rounded-md pl-9 h-10 bg-gray-50 border  text-gray-900 focus:ring-mainblue focus:border-mainblue block flex-1 min-w-0 w-[100px] sm:w-[250px] lg:w-[300px] text-sm border-gray-300'
            placeholder='Search a file...'
            onChange={(e) => setQuery(e.target.value)}
          /> */}
          <input
            onChange={(e) => setQuery(e.target.value)}
            type='text'
            id='website-admin'
            className='rounded-md ml-4 pl-1 sm:pl-4 -mr-8  py-1 bg-gray-50 border  text-gray-900 focus:ring-mainblue focus:border-mainblue block flex-1 min-w-0  w-[100px] sm:w-[250px] lg:w-[300px] text-sm border-gray-300 '
            placeholder='Search'
          />
          <span className='inline-flex relative  items-center px-2 text-gray-900 bg-gray-[#DBE3EB] rounded-r-md  border-l-0'>
            <img className='relative sm:right-4' alt='search' src={Search} />
          </span>

          <button
            onClick={() =>
              DownloadFiles(
                `${url}s3img/download/Wazuh Resources.zip`,
                `Wazuh Resources.zip`,
                'application/zip'
              )
            }
            className='text-[#292929] font-normal text-sm xl:text-base hidden md:flex items-center bg-yellow-gradient btn rounded-lg p-2 ml-6 mr-[14px]'
          >
            <Export className='mr-1 w-4  xl:w-5 xl:h-4' fill='#292929' />
            Download
          </button>
        </div>
      </div>
      {loading === true ? (
        <div className='flex justify-center m-12'>
          <img alt='loading' src={Oval} />
        </div>
      ) : (
        <div>
          {currentView === 'list' && (
            <div className='ml-7 mr-3'>
              <div className='custom-scrollbar'>
                <div className=''>
                  <div className='grid grid-cols-12 max-h-[calc(100vh-320px)]  lg:max-h-[calc(100vh-230px)] overflow-y-auto pr-4'>
                    <div className='col-span-4 xl:col-span-5 flex items-center top-0 sticky bg-[#292929] text-white font-semibold py-2 rounded-l-lg mb-2'>
                      <button
                        onClick={() => {
                          setSortAndOrientation('ascending', 'Name');
                        }}
                        className='text-sm md:text-base font-semibold overflow-ellipsis truncate ml-[22px] 2xl:ml-[42px]'
                      >
                        Name
                      </button>
                      {lastSort === 'Name' && (
                        <img
                          className={`${
                            lastOrientation === 'ascending' ? '' : 'rotate-180'
                          } relative ml-2 top-[2px]`}
                          src={SmallBlackArrow}
                        ></img>
                      )}
                    </div>
                    <div className='col-span-4 xl:col-span-3 flex items-center top-0 sticky bg-[#292929] text-white font-semibold py-2 mb-2'>
                      <button
                        onClick={() => {
                          setSortAndOrientation('ascending', 'LastModified');
                        }}
                        className='text-sm md:text-base font-semibold overflow-ellipsis truncate'
                      >
                        Last modified
                      </button>
                      {lastSort === 'LastModified' && (
                        <img
                          className={`${
                            lastOrientation === 'ascending' ? '' : 'rotate-180'
                          } relative ml-2 top-[2px]`}
                          src={SmallBlackArrow}
                        ></img>
                      )}
                    </div>
                    <div className='col-span-2 xl:col-span-2 flex items-center top-0 sticky bg-[#292929] text-white font-semibold py-2 mb-2'>
                      <button
                        onClick={() => {
                          setSortAndOrientation('ascending', 'fileSize');
                        }}
                        className='text-sm md:text-base font-semibold overflow-ellipsis truncate'
                      >
                        File size
                      </button>
                      {lastSort === 'fileSize' && (
                        <img
                          className={`${
                            lastOrientation === 'ascending' ? '' : 'rotate-180'
                          } relative right-4 ml-2 top-[2px]`}
                          src={SmallBlackArrow}
                        ></img>
                      )}
                    </div>
                    <div className='col-span-2 flex justify-end sticky top-0 bg-[#292929] text-white font-semibold py-2 rounded-r-lg z-[9] mb-2'>
                      <button>
                        <button
                          onClick={setViewToList}
                          className={`${
                            currentView === 'list' &&
                            'bg-[rgba(255,255,255,0.4)]'
                          } hover:bg-[rgba(255,255,255,0.4)] hover:cursor-pointer px-3 py-1 rounded-l-3xl border-black border relative right-9`}
                        >
                          <img className='h-3 sm:h-4' src={BlackBurger}></img>
                        </button>
                        <button
                          onClick={setViewToCards}
                          className={`${
                            currentView === 'cards' &&
                            'bg-[rgba(255,255,255,0.4)]'
                          } hover:bg-[rgba(255,255,255,0.4)] px-3 py-1 border-l-0 rounded-r-3xl border-black border relative right-9`}
                        >
                          <img className='h-3 sm:h-4' src={CardsIcon}></img>
                        </button>
                      </button>
                    </div>

                    {currentFolder === 'root' && currentView === 'list' ? (
                      filterAndSortArray(
                        search(processFolders(folders)),
                        sort,
                        orientation
                      ).map((folder) => (
                        <>
                          <div
                            onMouseEnter={() => setHoveredItem(folder.Name)}
                            onMouseLeave={() => setHoveredItem(null)}
                            key={folder.name}
                            onClick={() => handleFolder(folder.Name)}
                            style={{ display: 'contents' }}
                            className='rounded-xl'
                          >
                            <div
                              className={`${
                                hoveredItem === folder.Name &&
                                'bg-[#f6f6f6] cursor-pointer'
                              }  ${
                                (folder.Name === 'Sales' &&
                                  salesFolderOpen &&
                                  'bg-[#C3D8FB] hover:bg-[#cedef9]') ||
                                (folder.Name === 'Legal' &&
                                  legalFolderOpen &&
                                  'bg-[#C3D8FB] hover:bg-[#cedef9]') ||
                                (folder.Name === 'Marketing' &&
                                  marketingFolderOpen &&
                                  'bg-[#C3D8FB] hover:bg-[#cedef9]') ||
                                (folder.Name === 'BattleCards' &&
                                  battlecardsFolderOpen &&
                                  'bg-[#C3D8FB] hover:bg-[#cedef9]')
                              } flex items-center col-span-4 xl:col-span-5 py-4 pl-[22px] 2xl:pl-[42px] rounded-tl-xl rounded-bl-xl overflow-ellipsis truncate text-sm md:text-base`}
                            >
                              {folder.Name}
                              <img
                                src={BlueTriangle}
                                className={`${
                                  (folder.Name === 'Sales' &&
                                    salesFolderOpen) ||
                                  (folder.Name === 'Legal' &&
                                    legalFolderOpen) ||
                                  (folder.Name === 'Marketing' &&
                                    marketingFolderOpen) ||
                                  (folder.Name === 'BattleCards' &&
                                    battlecardsFolderOpen)
                                    ? 'block'
                                    : 'hidden'
                                } pl-2`}
                              ></img>
                            </div>
                            <div
                              className={`${
                                hoveredItem === folder.Name &&
                                'bg-[#f6f6f6] cursor-pointer'
                              } ${
                                (folder.Name === 'Sales' &&
                                  salesFolderOpen &&
                                  'bg-[#C3D8FB] hover:bg-[#cedef9]') ||
                                (folder.Name === 'Legal' &&
                                  legalFolderOpen &&
                                  'bg-[#C3D8FB] hover:bg-[#cedef9]') ||
                                (folder.Name === 'Marketing' &&
                                  marketingFolderOpen &&
                                  'bg-[#C3D8FB] hover:bg-[#cedef9]') ||
                                (folder.Name === 'BattleCards' &&
                                  battlecardsFolderOpen &&
                                  'bg-[#C3D8FB] hover:bg-[#cedef9]')
                              } col-span-4 xl:col-span-3 py-4 overflow-ellipsis truncate text-sm md:text-base`}
                            >
                              {convertToDateMonthYear(folder.LastModified)}
                            </div>
                            <div
                              className={`${
                                hoveredItem === folder.Name &&
                                'bg-[#f6f6f6] cursor-pointer'
                              } ${
                                (folder.Name === 'Sales' &&
                                  salesFolderOpen &&
                                  'bg-[#C3D8FB] hover:bg-[#cedef9]') ||
                                (folder.Name === 'Legal' &&
                                  legalFolderOpen &&
                                  'bg-[#C3D8FB] hover:bg-[#cedef9]') ||
                                (folder.Name === 'Marketing' &&
                                  marketingFolderOpen &&
                                  'bg-[#C3D8FB] hover:bg-[#cedef9]') ||
                                (folder.Name === 'BattleCards' &&
                                  battlecardsFolderOpen &&
                                  'bg-[#C3D8FB] hover:bg-[#cedef9]')
                              } col-span-2 overflow-ellipsis py-4 truncate text-sm md:text-base`}
                            >
                              {folder.fileSize}
                            </div>
                            <div
                              className={`${
                                hoveredItem === folder.Name &&
                                'bg-[#f6f6f6] cursor-pointer'
                              } ${
                                (folder.Name === 'Sales' &&
                                  salesFolderOpen &&
                                  'bg-[#C3D8FB] hover:bg-[#cedef9]') ||
                                (folder.Name === 'Legal' &&
                                  legalFolderOpen &&
                                  'bg-[#C3D8FB] hover:bg-[#cedef9]') ||
                                (folder.Name === 'Marketing' &&
                                  marketingFolderOpen &&
                                  'bg-[#C3D8FB] hover:bg-[#cedef9]') ||
                                (folder.Name === 'BattleCards' &&
                                  battlecardsFolderOpen &&
                                  'bg-[#C3D8FB] hover:bg-[#cedef9]')
                              } flex justify-center relative py-4 col-span-2 rounded-tr-xl rounded-br-xl`}
                            >
                              <Export
                                className={`${
                                  hoveredItem === folder.Name
                                    ? 'block'
                                    : 'invisible'
                                } mr-1 w-4  xl:w-5 xl:h-4`}
                                fill='#3d82f1'
                              />
                            </div>
                            <div className='h-[1px] my-2 bg-[#ececec]  col-span-12 mx-8'></div>
                          </div>
                          {folder.Name === 'BattleCards' &&
                            battlecardsFolderOpen && (
                              <>
                                {filterAndSortArray(
                                  search(processFiles(battleWithInfo)),
                                  sort,
                                  orientation
                                ).map((file) => (
                                  <div
                                    onClick={() =>
                                      DownloadFiles(
                                        `${url}s3img/download/${file.File}`,
                                        `${file.File}`,
                                        'application/pdf'
                                      )
                                    }
                                    onMouseEnter={() =>
                                      setHoveredItem(file.Name)
                                    }
                                    onMouseLeave={() => setHoveredItem(null)}
                                    key={file.Name}
                                    style={{ display: 'contents' }}
                                    className='grid grid-cols-12 rounded-xl'
                                  >
                                    <div
                                      className={`${
                                        hoveredItem === file.Name &&
                                        'bg-[#f6f6f6] cursor-pointer'
                                      } ml-5 col-span-4 xl:col-span-5 py-4 rounded-tl-xl flex items-center  rounded-bl-xl overflow-ellipsis truncate text-sm md:text-base`}
                                    >
                                      {/* <div className='h-20 absolute border-l-2 border-gray-200'></div> */}
                                      <img
                                        className={`${
                                          hoveredItem === file.Name
                                            ? 'pl-7 block'
                                            : 'pl-7 invisible'
                                        } rotate-90 relative bottom-[14px]`}
                                        src={BlueTriangle}
                                      ></img>
                                      <p className=''>{file.Name}</p>
                                    </div>
                                    <div
                                      className={`${
                                        hoveredItem === file.Name &&
                                        'bg-[#f6f6f6] cursor-pointer'
                                      } col-span-4 xl:col-span-3 py-4 overflow-ellipsis truncate text-sm md:text-base`}
                                    >
                                      {convertToDateMonthYear(
                                        file.LastModified
                                      )}
                                    </div>
                                    <div
                                      className={`${
                                        hoveredItem === file.Name &&
                                        'bg-[#f6f6f6] cursor-pointer'
                                      } col-span-2 overflow-ellipsis py-4 truncate text-sm md:text-base`}
                                    >
                                      {file.fileSize}
                                    </div>
                                    <div
                                      className={`${
                                        hoveredItem === file.Name &&
                                        'bg-[#f6f6f6] cursor-pointer'
                                      } flex justify-center relative py-4 col-span-2 rounded-tr-xl rounded-br-xl`}
                                    >
                                      <Export
                                        className={`${
                                          hoveredItem === file.Name
                                            ? 'block'
                                            : 'invisible'
                                        } mr-1 w-4  xl:w-5 xl:h-4`}
                                        fill='#3d82f1'
                                      />
                                    </div>
                                    <div className='h-[1px] my-2 bg-[#ececec]  col-span-12 mx-8'></div>
                                  </div>
                                ))}
                              </>
                            )}
                          {folder.Name === 'Legal' && legalFolderOpen && (
                            <>
                              {filterAndSortArray(
                                search(processFiles(legalWithInfo)),
                                sort,
                                orientation
                              ).map((file) => (
                                <div
                                  onClick={() =>
                                    DownloadFiles(
                                      `${url}s3img/download/${file.File}`,
                                      `${file.File}`,
                                      'application/pdf'
                                    )
                                  }
                                  onMouseEnter={() => setHoveredItem(file.Name)}
                                  onMouseLeave={() => setHoveredItem(null)}
                                  key={file.Name}
                                  style={{ display: 'contents' }}
                                  className='grid grid-cols-12 rounded-xl'
                                >
                                  <div
                                    className={`${
                                      hoveredItem === file.Name &&
                                      'bg-[#f6f6f6] cursor-pointer'
                                    } ml-5 col-span-4 xl:col-span-5 py-4 rounded-tl-xl flex items-center  rounded-bl-xl overflow-ellipsis truncate text-sm md:text-base`}
                                  >
                                    {/* <div className='h-20 absolute border-l-2 border-gray-200'></div> */}
                                    <img
                                      className={`${
                                        hoveredItem === file.Name
                                          ? 'pl-7 block'
                                          : 'pl-7 invisible'
                                      } rotate-90 relative bottom-[14px]`}
                                      src={BlueTriangle}
                                    ></img>
                                    <p className=''>{file.Name}</p>
                                  </div>
                                  <div
                                    className={`${
                                      hoveredItem === file.Name &&
                                      'bg-[#f6f6f6] cursor-pointer'
                                    } col-span-4 xl:col-span-3 py-4 overflow-ellipsis truncate text-sm md:text-base`}
                                  >
                                    {convertToDateMonthYear(file.LastModified)}
                                  </div>
                                  <div
                                    className={`${
                                      hoveredItem === file.Name &&
                                      'bg-[#f6f6f6] cursor-pointer'
                                    } col-span-2 overflow-ellipsis py-4 truncate text-sm md:text-base`}
                                  >
                                    {file.fileSize}
                                  </div>
                                  <div
                                    className={`${
                                      hoveredItem === file.Name &&
                                      'bg-[#f6f6f6] cursor-pointer'
                                    } flex justify-center relative py-4 col-span-2 rounded-tr-xl rounded-br-xl`}
                                  >
                                    <Export
                                      className={`${
                                        hoveredItem === file.Name
                                          ? 'block'
                                          : 'invisible'
                                      } mr-1 w-4  xl:w-5 xl:h-4`}
                                      fill='#3d82f1'
                                    />
                                  </div>
                                  <div className='h-[1px] my-2 bg-[#ececec]  col-span-12 mx-8'></div>
                                </div>
                              ))}
                            </>
                          )}
                          {folder.Name === 'Sales' && salesFolderOpen && (
                            <>
                              {filterAndSortArray(
                                search(processFiles(salesWithInfo)),
                                sort,
                                orientation
                              ).map((file) => (
                                <div
                                  onClick={() =>
                                    DownloadFiles(
                                      `${url}s3img/download/${file.File}`,
                                      `${file.File}`,
                                      'application/pdf'
                                    )
                                  }
                                  onMouseEnter={() => setHoveredItem(file.Name)}
                                  onMouseLeave={() => setHoveredItem(null)}
                                  key={file.Name}
                                  style={{ display: 'contents' }}
                                  className='grid grid-cols-12 rounded-xl'
                                >
                                  <div
                                    className={`${
                                      hoveredItem === file.Name &&
                                      'bg-[#f6f6f6] cursor-pointer'
                                    } ml-5 col-span-4 xl:col-span-5 py-4 rounded-tl-xl flex items-center  rounded-bl-xl overflow-ellipsis truncate text-sm md:text-base`}
                                  >
                                    {/* <div className='h-20 absolute border-l-2 border-gray-200'></div> */}
                                    <img
                                      className={`${
                                        hoveredItem === file.Name
                                          ? 'pl-7 block'
                                          : 'pl-7 invisible'
                                      } rotate-90 relative bottom-[14px]`}
                                      src={BlueTriangle}
                                    ></img>
                                    <p className=''>{file.Name}</p>
                                  </div>
                                  <div
                                    className={`${
                                      hoveredItem === file.Name &&
                                      'bg-[#f6f6f6] cursor-pointer'
                                    } col-span-4 xl:col-span-3 py-4 overflow-ellipsis truncate text-sm md:text-base`}
                                  >
                                    {convertToDateMonthYear(file.LastModified)}
                                  </div>
                                  <div
                                    className={`${
                                      hoveredItem === file.Name &&
                                      'bg-[#f6f6f6] cursor-pointer'
                                    } col-span-2 overflow-ellipsis py-4 truncate text-sm md:text-base`}
                                  >
                                    {file.fileSize}
                                  </div>
                                  <div
                                    className={`${
                                      hoveredItem === file.Name &&
                                      'bg-[#f6f6f6] cursor-pointer'
                                    } flex justify-center relative py-4 col-span-2 rounded-tr-xl rounded-br-xl`}
                                  >
                                    <Export
                                      className={`${
                                        hoveredItem === file.Name
                                          ? 'block'
                                          : 'invisible'
                                      } mr-1 w-4  xl:w-5 xl:h-4`}
                                      fill='#3d82f1'
                                    />
                                  </div>
                                  <div className='h-[1px] my-2 bg-[#ececec]  col-span-12 mx-8'></div>
                                </div>
                              ))}
                            </>
                          )}
                          {folder.Name === 'Marketing' &&
                            marketingFolderOpen && (
                              <>
                                {filterAndSortArray(
                                  search(processFiles(MarketingWithInfo)),
                                  sort,
                                  orientation
                                ).map((file) => (
                                  <div
                                    onClick={() =>
                                      DownloadFiles(
                                        `${url}s3img/download/${file.File}`,
                                        `${file.File}`,
                                        'application/pdf'
                                      )
                                    }
                                    onMouseEnter={() =>
                                      setHoveredItem(file.Name)
                                    }
                                    onMouseLeave={() => setHoveredItem(null)}
                                    key={file.Name}
                                    style={{ display: 'contents' }}
                                    className='grid grid-cols-12 rounded-xl'
                                  >
                                    <div
                                      className={`${
                                        hoveredItem === file.Name &&
                                        'bg-[#f6f6f6] cursor-pointer'
                                      } ml-5 col-span-4 xl:col-span-5 py-4 rounded-tl-xl flex items-center  rounded-bl-xl overflow-ellipsis truncate text-sm md:text-base`}
                                    >
                                      {/* <div className='h-20 absolute border-l-2 border-gray-200'></div> */}
                                      <img
                                        className={`${
                                          hoveredItem === file.Name
                                            ? 'pl-7 block'
                                            : 'pl-7 invisible'
                                        } rotate-90 relative bottom-[14px]`}
                                        src={BlueTriangle}
                                      ></img>
                                      <p className=''>{file.Name}</p>
                                    </div>
                                    <div
                                      className={`${
                                        hoveredItem === file.Name &&
                                        'bg-[#f6f6f6] cursor-pointer'
                                      } col-span-4 xl:col-span-3 py-4 overflow-ellipsis truncate text-sm md:text-base`}
                                    >
                                      {convertToDateMonthYear(
                                        file.LastModified
                                      )}
                                    </div>
                                    <div
                                      className={`${
                                        hoveredItem === file.Name &&
                                        'bg-[#f6f6f6] cursor-pointer'
                                      } col-span-2 overflow-ellipsis py-4 truncate text-sm md:text-base`}
                                    >
                                      {file.fileSize}
                                    </div>
                                    <div
                                      className={`${
                                        hoveredItem === file.Name &&
                                        'bg-[#f6f6f6] cursor-pointer'
                                      } flex justify-center relative py-4 col-span-2 rounded-tr-xl rounded-br-xl`}
                                    >
                                      <Export
                                        className={`${
                                          hoveredItem === file.Name
                                            ? 'block'
                                            : 'invisible'
                                        } mr-1 w-4  xl:w-5 xl:h-4`}
                                        fill='#3d82f1'
                                      />
                                    </div>
                                    <div className='h-[1px] my-2 bg-[#ececec]  col-span-12 mx-8'></div>
                                  </div>
                                ))}
                              </>
                            )}
                        </>
                      ))
                    ) : currentFolder === 'Sales' && currentView === 'list' ? (
                      <>
                        {filterAndSortArray(
                          search(processFiles(salesWithInfo)),
                          sort,
                          orientation
                        ).map((file) => (
                          <div
                            onClick={() =>
                              DownloadFiles(
                                `${url}s3img/download/${file.File}`,
                                `${file.File}`,
                                'application/pdf'
                              )
                            }
                            onMouseEnter={() => setHoveredItem(file.Name)}
                            onMouseLeave={() => setHoveredItem(null)}
                            key={file.Name}
                            style={{ display: 'contents' }}
                            className='grid grid-cols-12 rounded-xl'
                          >
                            <div
                              className={`${
                                hoveredItem === file.Name &&
                                'bg-[#f6f6f6] cursor-pointer'
                              } col-span-4 xl:col-span-5 py-4 rounded-tl-xl rounded-bl-xl overflow-ellipsis truncate text-sm md:text-base`}
                            >
                              {file.Name}
                            </div>
                            <div
                              className={`${
                                hoveredItem === file.Name &&
                                'bg-[#f6f6f6] cursor-pointer'
                              } col-span-4 xl:col-span-3 py-4 overflow-ellipsis truncate text-sm md:text-base`}
                            >
                              {convertToDateMonthYear(file.LastModified)}
                            </div>
                            <div
                              className={`${
                                hoveredItem === file.Name &&
                                'bg-[#f6f6f6] cursor-pointer'
                              } col-span-2 overflow-ellipsis py-4 truncate text-sm md:text-base`}
                            >
                              {file.fileSize}
                            </div>
                            <div
                              className={`${
                                hoveredItem === file.Name &&
                                'bg-[#f6f6f6] cursor-pointer'
                              } flex justify-center relative py-4 col-span-2 rounded-tr-xl rounded-br-xl`}
                            >
                              <Export
                                className={`${
                                  hoveredItem === file.Name
                                    ? 'block'
                                    : 'invisible'
                                } mr-1 w-4  xl:w-5 xl:h-4`}
                                fill='#3d82f1'
                              />
                            </div>
                            <div className='h-[1px] my-2 bg-[#ececec]  col-span-12 mx-8'></div>
                          </div>
                        ))}
                      </>
                    ) : currentFolder === 'Marketing' &&
                      currentView === 'list' ? (
                      <>
                        {filterAndSortArray(
                          search(processFiles(MarketingWithInfo)),
                          sort,
                          orientation
                        ).map((file) => (
                          <div
                            onClick={() =>
                              DownloadFiles(
                                `${url}s3img/download/${file.File}`,
                                `${file.File}`,
                                'application/pdf'
                              )
                            }
                            onMouseEnter={() => setHoveredItem(file.Name)}
                            onMouseLeave={() => setHoveredItem(null)}
                            key={file.Name}
                            style={{ display: 'contents' }}
                            className='grid grid-cols-12 rounded-xl'
                          >
                            <div
                              className={`${
                                hoveredItem === file.Name &&
                                'bg-[#f6f6f6] cursor-pointer'
                              } col-span-4 xl:col-span-5 py-4 rounded-tl-xl rounded-bl-xl overflow-ellipsis truncate text-sm md:text-base`}
                            >
                              {file.Name}
                            </div>
                            <div
                              className={`${
                                hoveredItem === file.Name &&
                                'bg-[#f6f6f6] cursor-pointer'
                              } col-span-4 xl:col-span-3 py-4 overflow-ellipsis truncate text-sm md:text-base`}
                            >
                              {convertToDateMonthYear(file.LastModified)}
                            </div>
                            <div
                              className={`${
                                hoveredItem === file.Name &&
                                'bg-[#f6f6f6] cursor-pointer'
                              } col-span-2 overflow-ellipsis py-4 truncate text-sm md:text-base`}
                            >
                              {file.fileSize}
                            </div>
                            <div
                              className={`${
                                hoveredItem === file.Name &&
                                'bg-[#f6f6f6] cursor-pointer'
                              } flex justify-center relative py-4 col-span-2 rounded-tr-xl rounded-br-xl`}
                            >
                              <Export
                                className={`${
                                  hoveredItem === file.Name
                                    ? 'block'
                                    : 'invisible'
                                } mr-1 w-4  xl:w-5 xl:h-4`}
                                fill='#3d82f1'
                              />
                            </div>
                            <div className='h-[1px] my-2 bg-[#ececec]  col-span-12 mx-8'></div>
                          </div>
                        ))}
                      </>
                    ) : currentFolder === 'Legal' && currentView === 'list' ? (
                      <>
                        {filterAndSortArray(
                          search(processFiles(legalWithInfo)),
                          sort,
                          orientation
                        ).map((file) => (
                          <div
                            onClick={() =>
                              DownloadFiles(
                                `${url}s3img/download/${file.File}`,
                                `${file.File}`,
                                'application/pdf'
                              )
                            }
                            onMouseEnter={() => setHoveredItem(file.Name)}
                            onMouseLeave={() => setHoveredItem(null)}
                            key={file.Name}
                            style={{ display: 'contents' }}
                            className='grid grid-cols-12 rounded-xl'
                          >
                            <div
                              className={`${
                                hoveredItem === file.Name &&
                                'bg-[#f6f6f6] cursor-pointer'
                              } col-span-4 xl:col-span-5 py-4 rounded-tl-xl rounded-bl-xl overflow-ellipsis truncate text-sm md:text-base`}
                            >
                              {file.Name}
                            </div>
                            <div
                              className={`${
                                hoveredItem === file.Name &&
                                'bg-[#f6f6f6] cursor-pointer'
                              } col-span-4 xl:col-span-3 py-4 overflow-ellipsis truncate text-sm md:text-base`}
                            >
                              {convertToDateMonthYear(file.LastModified)}
                            </div>
                            <div
                              className={`${
                                hoveredItem === file.Name &&
                                'bg-[#f6f6f6] cursor-pointer'
                              } col-span-2 overflow-ellipsis py-4 truncate text-sm md:text-base`}
                            >
                              {file.fileSize}
                            </div>
                            <div
                              className={`${
                                hoveredItem === file.Name &&
                                'bg-[#f6f6f6] cursor-pointer'
                              } flex justify-center relative py-4 col-span-2 rounded-tr-xl rounded-br-xl`}
                            >
                              <Export
                                className={`${
                                  hoveredItem === file.Name
                                    ? 'block'
                                    : 'invisible'
                                } mr-1 w-4  xl:w-5 xl:h-4`}
                                fill='#3d82f1'
                              />
                            </div>
                            <div className='h-[1px] my-2 bg-[#ececec]  col-span-12 mx-8'></div>
                          </div>
                        ))}
                      </>
                    ) : currentFolder === 'BattleCards' &&
                      currentView === 'list' ? (
                      <>
                        {filterAndSortArray(
                          search(processFiles(battleWithInfo)),
                          sort,
                          orientation
                        ).map((file) => (
                          <div
                            onClick={() =>
                              DownloadFiles(
                                `${url}s3img/download/${file.File}`,
                                `${file.File}`,
                                'application/pdf'
                              )
                            }
                            onMouseEnter={() => setHoveredItem(file.Name)}
                            onMouseLeave={() => setHoveredItem(null)}
                            key={file.Name}
                            style={{ display: 'contents' }}
                            className='grid grid-cols-12 rounded-xl'
                          >
                            <div
                              className={`${
                                hoveredItem === file.Name &&
                                'bg-[#f6f6f6] cursor-pointer'
                              } col-span-4 xl:col-span-5 pl-8 py-4 rounded-tl-xl rounded-bl-xl overflow-ellipsis truncate text-sm md:text-base`}
                            >
                              {file.Name}
                            </div>
                            <div
                              className={`${
                                hoveredItem === file.Name &&
                                'bg-[#f6f6f6] cursor-pointer'
                              } col-span-4 xl:col-span-3 py-4 overflow-ellipsis truncate text-sm md:text-base`}
                            >
                              {convertToDateMonthYear(file.LastModified)}
                            </div>
                            <div
                              className={`${
                                hoveredItem === file.Name &&
                                'bg-[#f6f6f6] cursor-pointer'
                              } col-span-2 overflow-ellipsis py-4 truncate text-sm md:text-base`}
                            >
                              {file.fileSize}
                            </div>
                            <div
                              className={`${
                                hoveredItem === file.Name &&
                                'bg-[#f6f6f6] cursor-pointer'
                              } flex justify-center relative py-4 col-span-2 rounded-tr-xl rounded-br-xl`}
                            >
                              <Export
                                className={`${
                                  hoveredItem === file.Name
                                    ? 'block'
                                    : 'invisible'
                                } mr-1 w-4  xl:w-5 xl:h-4`}
                                fill='#3d82f1'
                              />
                            </div>
                            <div className='h-[1px] my-2 bg-[#ececec]  col-span-12 mx-8'></div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className='custom-scrollbar mx-8'>
                <div className='max-h-[calc(100vh-400px)] flex-1 overflow-y-auto'></div>
                <div
                  className={`${
                    currentFolder === 'root'
                      ? 'hidden'
                      : 'absolute right-12 bottom-6'
                  } justify-end mt-5`}
                >
                  <button
                    onClick={() => setCurrentFolder('root')}
                    className='btn bg-yellow-gradient px-11 py-[5px] rounded-lg flex items-center'
                  >
                    <img alt='back' className='w-3 mr-2' src={Back}></img> Back
                  </button>
                </div>
              </div>
            </div>
          )}
          {currentView === 'cards' && (
            <div className='flex flex-col ml-7 mr-3'>
              {/* <div className=''>
                <div
                  className={`${
                    currentFolder === 'root' ? 'invisible' : 'flex'
                  } rounded-xl`}
                >
                  <div className='bg-gray-100 text-gray-400 px-4 py-2 rounded-xl  font-semibold text-xl mb-6'>
                    <p className='flex'>
                      <p
                        className='hover:cursor-pointer hover:text-[#3D82F1]'
                        onClick={() => setCurrentFolder('root')}
                      >
                        Resources
                      </p>{' '}
                      {currentFolder !== 'root' && (
                        <p className='flex mx-2 hover:text-gray-400'>
                          {'>'}{' '}
                          <p className='ml-2 text-[#3D82F1] hover:text-[#3D82F1]'>
                            {currentFolder}
                          </p>
                        </p>
                      )}
                    </p>
                  </div>
                </div>
              </div> */}
              <div className='flex justify-between items-center mr-4 bg-[#292929] text-white font-semibold py-2 rounded-lg'>
                <div className=' text-center text-base font-semibold lg:ml-6 2xl:ml-[42px]'>
                  Select a sales material file:
                </div>
                <div className='col-span-1 flex justify-center top-1 bg-[#292929] sticky z-10'>
                  <button>
                    <button
                      onClick={setViewToList}
                      className={`${
                        currentView === 'list' && 'bg-[rgba(255,255,255,0.4)]'
                      } hover:bg-[rgba(255,255,255,0.4)] hover:cursor-pointer px-3 py-1 rounded-l-3xl border-black border relative right-9`}
                    >
                      <img className='h-3 sm:h-4' src={BlackBurger}></img>
                    </button>
                    <button
                      onClick={setViewToCards}
                      className={`${
                        currentView === 'cards' && 'bg-[rgba(255,255,255,0.4)]'
                      } hover:bg-[rgba(255,255,255,0.4)] px-3 py-1 border-l-0 rounded-r-3xl border-black border relative right-9`}
                    >
                      <img className='h-3 sm:h-4' src={CardsIcon}></img>
                    </button>
                  </button>
                </div>
              </div>
              <span className='mt-4 mb-4'></span>
              <div className='custom-scrollbar ml-6 2xl:ml-10 mr-12 flex items-center sm:items-start justify-around flex-col'>
                {/* <div className='max-h-[calc(100vh-400px)]  lg:max-h-[calc(100vh-410px)] overflow-y-auto mr-3 my-0'> */}

                {currentFolder === 'root' ? (
                  <div className='flex flex-col items-center sm:items-start w-full sm:mr-16'>
                    {chunkArray(
                      search(processFolders(folders)),
                      rootColumnAmount
                    ).map((chunk, index) => (
                      <div
                        key={index}
                        className='flex justify-between sm:w-full gap-4 mb-6'
                      >
                        {chunk.map((folder) => (
                          <ResourcesCard
                            key={folder.Name}
                            funct={setCurrentFolder}
                            type='folder'
                            name={folder.Name}
                            date={convertToDateMonthYear(folder.LastModified)}
                          />
                        ))}
                        {Array.from({
                          length: rootColumnAmount - chunk.length,
                        }).map((_, placeholderIndex) => (
                          <div
                            key={`placeholder-${placeholderIndex}`}
                            className='hidden sm:block sm:invisible'
                          >
                            <ResourcesCard
                              type='download'
                              file={'file'}
                              name={'Name'}
                              date={convertToDateMonthYear(
                                '2025-01-01T12:34:56.000Z'
                              )}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ) : // chunkArray(
                //   search(processFolders(folders)),
                //   rootColumnAmount
                // ).map((chunk, index) => (
                //   <div
                //     key={index}
                //     className='flex justify-between sm:w-full gap-4 mb-4'
                //   >
                //     {chunk.map((folder) => (
                //       <ResourcesCard
                //         key={folder.Name}
                //         funct={setCurrentFolder}
                //         type='folder'
                //         name={folder.Name}
                //         date={convertToDateMonthYear(folder.LastModified)}
                //       />
                //     ))}
                //   </div>
                // ))

                currentFolder === 'Sales' ? (
                  <div className='flex flex-col items-center sm:items-start w-full sm:mr-16'>
                    {chunkArray(
                      search(processFolders(salesWithInfo)),
                      columnAmount
                    ).map((chunk, index) => (
                      <div
                        key={index}
                        className='flex justify-between sm:w-full gap-4 mb-6'
                      >
                        {chunk.map((file) => (
                          <ResourcesCard
                            type='download'
                            file={file.File}
                            name={file.Name}
                            date={convertToDateMonthYear(file.LastModified)}
                          />
                        ))}
                        {Array.from({
                          length: columnAmount - chunk.length,
                        }).map((_, placeholderIndex) => (
                          <div
                            key={`placeholder-${placeholderIndex}`}
                            className='hidden sm:block sm:invisible'
                          >
                            <ResourcesCard
                              type='download'
                              file={'file'}
                              name={'Name'}
                              date={convertToDateMonthYear(
                                '2025-01-01T12:34:56.000Z'
                              )}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ) : currentFolder === 'Marketing' ? (
                  <div className='flex flex-col items-center sm:items-start w-full sm:mr-16'>
                    {chunkArray(
                      search(processFolders(MarketingWithInfo)),
                      columnAmount
                    ).map((chunk, index) => (
                      <div
                        key={index}
                        className='flex justify-between sm:w-full gap-4 mb-6'
                      >
                        {chunk.map((file) => (
                          <ResourcesCard
                            type='download'
                            file={file.File}
                            name={file.Name}
                            date={convertToDateMonthYear(file.LastModified)}
                          />
                        ))}
                        {Array.from({
                          length: columnAmount - chunk.length,
                        }).map((_, placeholderIndex) => (
                          <div
                            key={`placeholder-${placeholderIndex}`}
                            className='hidden sm:block sm:invisible'
                          >
                            <ResourcesCard
                              type='download'
                              file={'file'}
                              name={'Name'}
                              date={convertToDateMonthYear(
                                '2025-01-01T12:34:56.000Z'
                              )}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ) : currentFolder === 'Legal' ? (
                  <div className='flex flex-col items-center sm:items-start w-full sm:mr-16'>
                    {chunkArray(
                      search(processFolders(legalWithInfo)),
                      columnAmount
                    ).map((chunk, index) => (
                      <div
                        key={index}
                        className='flex justify-between sm:w-full gap-4 mb-6'
                      >
                        {chunk.map((file) => (
                          <ResourcesCard
                            type='download'
                            file={file.File}
                            name={file.Name}
                            date={convertToDateMonthYear(file.LastModified)}
                          />
                        ))}
                        {Array.from({
                          length: columnAmount - chunk.length,
                        }).map((_, placeholderIndex) => (
                          <div
                            key={`placeholder-${placeholderIndex}`}
                            className='hidden sm:block sm:invisible'
                          >
                            <ResourcesCard
                              type='download'
                              file={'file'}
                              name={'Name'}
                              date={convertToDateMonthYear(
                                '2025-01-01T12:34:56.000Z'
                              )}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ) : currentFolder === 'BattleCards' ? (
                  <div className='flex flex-col items-center sm:items-start w-full sm:mr-16'>
                    {chunkArray(
                      search(processFolders(battleWithInfo)),
                      columnAmount
                    ).map((chunk, index) => (
                      <div
                        key={index}
                        className='flex sm:w-full justify-between gap-4 mb-6'
                      >
                        {chunk.map((file) => (
                          <ResourcesCard
                            type='download'
                            file={file.File}
                            name={file.Name}
                            date={convertToDateMonthYear(file.LastModified)}
                          />
                        ))}
                        {Array.from({
                          length: columnAmount - chunk.length,
                        }).map((_, placeholderIndex) => (
                          <div
                            key={`placeholder-${placeholderIndex}`}
                            className='hidden sm:block sm:invisible'
                          >
                            <ResourcesCard
                              type='download'
                              file={'file'}
                              name={'Name'}
                              date={convertToDateMonthYear(
                                '2025-01-01T12:34:56.000Z'
                              )}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )}

                {/* </div> */}
                <div
                  className={`${
                    currentFolder === 'root'
                      ? 'hidden'
                      : 'relative mt-24 left-1 2xl:left-[26px]'
                  } justify-end mt-5`}
                >
                  <button
                    onClick={() => setCurrentFolder('root')}
                    type='button'
                    className='bg-[#FBFCFD] justify-center border border-[#3D82F1] hover:text-blue-700 hover:border-blue-800 hover:cursor-pointer text-sm 2xl:text-base  2xl:right-[calc(100vw-1500px)]   text-[#3D82F1] rounded-lg px-2 py-2 flex items-center w-[91px] lg:w-[109px] lg:h-[42px]'
                  >
                    <img
                      alt='white arrow pointing right'
                      className='relative right-2'
                      src={BlueLeftArrow}
                    ></img>
                    Back
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Help;
