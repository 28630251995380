import React from 'react';
import ChartInfo from '../Popover/ChartInfo';
import Calendly from '../img/Calendly.svg';
import Calendly3 from '../img/Calendly3-min.png';

const ContactSection = ({ ownerData }) => {
  const salesData = [
    {
      Name: 'Sheila Gahan',
      Email: 'sheila.gahan@wazuh.com',
      Phone: '+1 (408) 688-5380',
      calendly: 'https://calendly.com/sheila-gahan',
      MediumPhotoUrl: `https://partners.wazuh.com/assets/salesProfilePics/Sheila%20Gahan.jpeg`,
    },
    {
      Name: 'Sofia Calcaterra',
      Email: 'sofia.calcaterra@wazuh.com',
      Phone: '+1 (408) 508-5861',
      calendly: 'https://calendly.com/sofia-calcaterra/30min?month=2023-08',
      MediumPhotoUrl: `https://partners.wazuh.com/assets/salesProfilePics/Sofia%20Calcaterra.JPG`,
    },
    {
      Name: 'Francisco Santos',
      Email: 'francisco.santos@wazuh.com',
      Phone: '+1 (408) 558-4630',
      calendly:
        'https://calendly.com/wazuh-francisco-santos/wazuh?month=2023-08',
      MediumPhotoUrl: `https://partners.wazuh.com/assets/salesProfilePics/Francisco%20Santos.jpeg`,
    },
    {
      Name: 'Kristina Stankovska',
      Email: 'kristina.stankovska@wazuh.com',
      Phone: '+1 (408) 874-8102',
      calendly: 'https://calendly.com/kristina-stankovska',
      MediumPhotoUrl: `https://partners.wazuh.com/assets/salesProfilePics/Kristina%20Stankovska.jpg`,
    },
    {
      Name: 'Jorge Arano',
      Email: 'jorge.arano@wazuh.com',
      Phone: '+1 (408) 819-9751',
      calendly: 'https://calendly.com/jorge-arano-wazuh',
      MediumPhotoUrl: `https://partners.wazuh.com/assets/salesProfilePics/Jorge%20Arano.png`,
    },
    {
      Name: 'Alejandro Jácome',
      Email: 'alejandro.jacome@wazuh.com',
      Phone: '+1 (408) 558-4627',
      calendly: 'https://calendly.com/alejandro-jacome-dla',
      MediumPhotoUrl: `https://partners.wazuh.com/assets/salesProfilePics/Alejandro%20Jacome.png`,
    },
    {
      Name: 'Aldana Acuña',
      Email: 'aldana.acuna@wazuh.com',
      Phone: '+1 (408) 608-3135',
      calendly: 'https://calendly.com/aldana-acuna',
      MediumPhotoUrl: `https://partners.wazuh.com/assets/salesProfilePics/Aldana%20Acuna.jpg`,
    },
    {
      Name: 'Kiko Rodriguez',
      Email: 'kiko.rodriguez@wazuh.com',
      Phone: '+1 (408) 608-3142',
      MediumPhotoUrl: `https://partners.wazuh.com/assets/salesProfilePics/Kiko%20Rodriguez.png`,
    },
  ];

  // Function to merge data based on the requirements
  const mergeData = (localItem, ownerItem) => {
    const mergedItem = { ...localItem };

    Object.keys(ownerItem).forEach((key) => {
      if (localItem[key] === undefined) {
        mergedItem[key] = ownerItem[key];
      }
    });

    return mergedItem;
  };

  if (!ownerData || ownerData.length === 0) {
    return (
      <div>
        <div className='flex items-center'>
          <h1 className='text-[22px] font-semibold text-left max-md:mb-[30px] mr-1'>
            Contact
          </h1>
          <ChartInfo
            text={
              'Contact information of your designeted Wazuh Sales Team member, contact them for support in any of the available ways.'
            }
          />
        </div>
        <span className='form-divider h-1 mr-0 lg:mr-6 2xl:mr-3 hidden md:block mt-2 mb-7'></span>
        <div className='flex justify-center items-center bg-[#EBF2FC]'>
          No owner data available.
        </div>
      </div>
    );
  }

  const matchingLocalData = salesData.find(
    (localItem) => localItem.Name === ownerData[0].Name
  );

  const mergedData = matchingLocalData
    ? mergeData(matchingLocalData, ownerData[0])
    : ownerData[0];

  return (
    <div className='flex flex-col h-full'>
      <div className=''>
        <div className='flex items-center'>
          <h1 className='text-[22px] font-semibold text-left mr-1'>Contact</h1>
          <ChartInfo
            text={
              'Contact information of your designeted Wazuh Sales Team member, contact them for support in any of the available ways.'
            }
          />
        </div>

        <span className='form-divider h-1 mr-0 lg:mr-6 2xl:mr-3 hidden md:block mt-2 mb-7'></span>
      </div>
      <div className='rounded-lg bg-[#F6F6F6] flex-grow px-2 py-4 h-full'>
        <div className='flex flex-col sm:flex-row items-center justify-between mx-12'>
          <div className='w-[80px] xl:w-[100px] aspect-square rounded-full overflow-hidden flex items-center justify-center'>
            <img
              className='object-cover w-full h-full'
              src={`${mergedData.MediumPhotoUrl}`}
              alt='Profile Picture'
            />
          </div>
          <div className='font-semibold text-base xl:text-xl max-sm:flex max-sm:flex-col max-sm:items-center'>
            <span>{mergedData.Name}</span>
            <div className='font-semibold text-base xl:text-lg text-blue-500'>
              Customer Success
            </div>
          </div>
          <div className='2xl:flex'>
            <div className='font-semibold text-base xl:text-lg text-blue-500 hover:text-blue-600'>
              <a href={`${'mailto:' + mergedData.Email}`}>{mergedData.Email}</a>
            </div>
            <div className='flex 2xl:hidden items-center'>
              <div className='text-sm xl:text-base text-blue-500'>
                Wazuh Inc.
              </div>
              {mergedData.Phone && (
                <div className='flex'>
                  <div className='text-sm xl:text-base text-gray-500'>
                    {' '}
                    &nbsp;|&nbsp;{' '}
                  </div>
                  <div className='text-sm xl:text-base'>{mergedData.Phone}</div>
                </div>
              )}
            </div>
          </div>
          <div className='hidden 2xl:flex items-center'>
            <div className='text-sm xl:text-base text-blue-500'>Wazuh Inc.</div>
            {mergedData.Phone && (
              <div className='flex'>
                <div className='text-sm xl:text-base text-gray-500'>
                  {' '}
                  &nbsp;|&nbsp;{' '}
                </div>
                <div className='text-sm xl:text-base'>{mergedData.Phone}</div>
              </div>
            )}
          </div>
          {mergedData.calendly && (
            <div className='flex flex-col items-center'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={mergedData.calendly}
              >
                <img
                  className='w-[50px] h-[50px] xl:w-[60px] xl:h-[60px] hover:bg-blue-300 p-[5px] rounded-full hover:cursor-pointer'
                  src={Calendly3}
                  alt='Calendly Icon'
                />
              </a>
              <div className='text-sm xl:text-base font-semibold'>
                Schedule a call
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
