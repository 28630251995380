import TrainingCalendar from './img/TrainingCalendar.svg';
import TrainingTimeZone from './img/TrainingTimeZone.svg';
import TrainingLanguage from './img/TrainingLanguage.svg';
import TrainingCost from './img/TrainingCost.svg';
import ArrowPagination from '../components/img/ArrowPagination';
import {
  useGetTrainingsMutation,
  useGetTrainingsLeftMutation,
} from '../redux/features/user/trainingsApiSlice';
import { useEffect, useState } from 'react';
import moment from 'moment';
import TrainingModal from './Modals/TrainingModal';

const TrainingsSection = ({ username, partner, partnerId }) => {
  const [trainings, setTrainings] = useState([]);
  const [trainingsLeft, setTrainingsLeft] = useState('-');
  const [getTrainings] = useGetTrainingsMutation();
  const [getTrainingsLeft] = useGetTrainingsLeftMutation();
  const [indexPage, setIndexPage] = useState(0);
  const [translateClass, setTranslateClass] = useState(0);
  useEffect(() => {
    const getTrainingsData = async () => {
      const result = await getTrainingsLeft(partnerId).unwrap();
      setTrainingsLeft(result);
    };
    getTrainingsData();
  }, [partnerId]);

  useEffect(() => {
    const getTrainingsData = async () => {
      const result = await getTrainings().unwrap();
      setTrainings(result);
    };
    getTrainingsData();
  }, []);

  function formatDate(dateString) {
    return moment(dateString).format('MMM D');
  }

  const handleNext = () => {
    if (indexPage + 1 < trainings.length) {
      setIndexPage((prev) => prev + 1);
      setTranslateClass((prev) => prev + 100);
    }
  };

  const handlePrev = () => {
    if (indexPage > 0) {
      setIndexPage((prev) => prev - 1);
      setTranslateClass((prev) => prev - 100);
    }
  };

  const formatTrainingObjects = (trainingArray) => {
    return trainingArray.map((training) => {
      let {
        End_Date_1__c,
        Start_Date__c,
        Time_Zone__c,
        Name,
        Training_Type__c,
        Seats_left__c,
        Id,
      } = training;

      // Replace Training_Type__c value based on content
      if (Training_Type__c.includes('English')) {
        Training_Type__c = 'English';
      } else if (Training_Type__c.includes('Spanish')) {
        Training_Type__c = 'Spanish';
      }

      // Replace Time_Zone__c based on content in Name
      const timeZones = ['EDT', 'CET', 'CEST', 'EST'];
      const foundTimeZone = timeZones.find((zone) => Name.includes(zone));
      Time_Zone__c = foundTimeZone ? foundTimeZone : 'All';

      // Format the Start_Date__c and End_Date_1__c
      Start_Date__c = formatDate(Start_Date__c);
      End_Date_1__c = formatDate(End_Date_1__c);

      // Return the updated object
      return {
        Id,
        End_Date_1__c,
        Start_Date__c,
        Time_Zone__c,
        Name,
        Training_Type__c,
        Seats_left__c,
      };
    });
  };

  const formatedTraining = formatTrainingObjects(trainings);

  return (
    <div className='bg-[#F6F6F6] rounded-xl p-4 h-[55%]'>
      <div className='flex flex-col justify-between'>
        <div className='text-xl font-semibold'>Trainings</div>
        <p className=' text-sm 2xl:text-base'>
          You currently have{' '}
          <span className='font-bold text-blue-500'>{trainingsLeft}</span> seats
          available with your partnership.
        </p>
      </div>
      <span className='form-divider w-full block h-1 my-3'></span>
      <div className='flex justify-between'>
        <div>
          <p className=''>
            Wazuh for <span className='font-semibold'>Security Engineers</span>
          </p>
        </div>
        <div className='flex mr-2'>
          <button onClick={handlePrev}>
            <ArrowPagination
              className='w-2 h-4 hover:cursor-pointer hover:scale-125 transform duration-200'
              mainfill={indexPage === 0 ? '#B7C6D7' : '#387DEC'}
            />
          </button>
          <button onClick={handleNext}>
            <ArrowPagination
              className='w-2 h-4 ml-2 rotate-180 hover:cursor-pointer hover:scale-125 transform duration-200'
              mainfill={
                indexPage + 1 === trainings.length ? '#B7C6D7' : '#387DEC'
              }
            />
          </button>
        </div>
      </div>
      <div className='flex bg-white w-full overflow-hidden mt-2 rounded-xl'>
        {formatedTraining.map((training, index) => (
          <div
            key={training.Id || index}
            className='w-full min-w-full flex flex-col flex-1 duration-300 rounded-xl px-2 2xl:px-16 2xl:py-2 mt-2 h-[calc(100%-120px)] 2xl:h-[calc(100%-110px)] justify-center'
            style={{ transform: `translateX(-${translateClass}%)` }}
          >
            <div className='flex justify-between mt-4 w-full'>
              <div className='flex items-center'>
                <img className='w-7 xl:w-8' src={TrainingCalendar}></img>
                <p className='text-sm xl:text-base ml-1 xl:ml-2'>
                  {training.Start_Date__c}
                  {' - '}
                  {training.End_Date_1__c}
                </p>
              </div>
              <div className='flex items-center w-24'>
                <img className='w-7 xl:w-8' src={TrainingTimeZone}></img>
                <p className='text-sm xl:text-base ml-1 xl:ml-2'>
                  {training.Time_Zone__c}
                </p>
              </div>
            </div>
            <div className='flex justify-between mt-4'>
              <div className='flex items-center'>
                <img className='w-7 xl:w-8' src={TrainingLanguage}></img>
                <p className='text-sm xl:text-base ml-1 xl:ml-2'>
                  {training.Training_Type__c}
                </p>
              </div>
              <div className='flex items-center w-24'>
                <img className='w-7 xl:w-8' src={TrainingCost}></img>
                <p className='text-sm xl:text-base ml-1 xl:ml-2'>$ 1,800</p>
              </div>
            </div>
            <TrainingModal
              seatsLeft={trainingsLeft}
              partnerId={partnerId}
              id={training.Id}
              username={username}
              partner={partner}
              courseName={`Wazuh for Security Engineers | ${training.Training_Type__c} |  ${training.Start_Date__c} - ${training.End_Date_1__c} | ${training.Time_Zone__c}`}
              seats={training.Seats_left__c}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrainingsSection;
