import React, { useState, useEffect } from 'react';
import Search from '../components/img/Search.svg';
import Plus from '../components/img/Plus.svg';
import Export from '../components/img/Export';
import MyCustomersTable from './MyCustomersTable';
import { Link } from 'react-router-dom';
import MobileMyCustomers from '../components/MobileMyCustomers';
import useAuth from '../hooks/useAuth';
import { useGetCustomersMutation } from '../redux/features/user/dealsApiSlice';
import { useGetPartnerByIdMutation } from '../redux/features/user/partnersApiSlice';
import { downloadExcel } from 'react-export-table-to-excel';

const MyCustomers = () => {
  const [customers, SetCustomers] = useState([]);
  const [query, setQuery] = useState('');
  const [callLoading, setCallLoading] = useState(true);
  const [partnerData, setPartnerData] = useState(null);
  const [sort, setSort] = useState('Partner_Client__c');
  const [orientation, setOrientation] = useState('ascending');
  const [getCustomers] = useGetCustomersMutation();
  const [getPartner] = useGetPartnerByIdMutation();

  const userinfo = useAuth();

  useEffect(() => {
    const getPartnerData = async () => {
      const partnerInfo = await getPartner(userinfo.partnerId).unwrap();
      setPartnerData(partnerInfo);
    };
    getPartnerData();
  }, []);

  useEffect(() => {
    const getCustomersData = async () => {
      if (partnerData && partnerData.length > 0) {
        const result = await getCustomers(partnerData[0].salesforceId).unwrap();
        SetCustomers(result);
        await setCallLoading(false);
      }
    };
    getCustomersData();
  }, [partnerData]);

  const CloudTypes = [
    'Customer New Cloud',
    'Customer Upgrade Cloud',
    'Partner Customer New Cloud',
    'Partner Customer Upgrade Cloud',
  ];

  const PremisesTypes = [
    'Customer New On-premise',
    'Customer Expansion On-premise',
    'Partner Customer New On premises',
    'Partner Customer Expansion On-Prem',
  ];

  const newArray = customers
    .filter(
      (obj) => CloudTypes.includes(obj.Type) || PremisesTypes.includes(obj.Type)
    )
    .map((obj) => {
      return {
        ...obj,
        Type: CloudTypes.includes(obj.Type) ? 'Cloud' : 'On premises',
      };
    });

  function filterAndSortArray(data, sortByProperty, sortDirection) {
    // Check if sortDirection is valid
    if (sortDirection !== 'ascending' && sortDirection !== 'descending') {
      throw new Error(
        'Invalid sortDirection. Use "ascending" or "descending".'
      );
    }

    // Clone the data array to avoid modifying the original array
    const clonedData = [...data];

    // Sort the array based on the sortByProperty
    clonedData.sort((a, b) => {
      const aValue = a[sortByProperty];
      const bValue = b[sortByProperty];

      // Compare the values based on the sortDirection
      if (sortDirection === 'ascending') {
        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;
        return 0;
      } else {
        if (aValue < bValue) return 1;
        if (aValue > bValue) return -1;
        return 0;
      }
    });

    return clonedData;
  }

  const search2 = (data) => {
    return data.filter(
      (item) =>
        item.Partner_Client__c?.toLowerCase().includes(query.toLowerCase()) ||
        item.Type.toLowerCase().includes(query.toLowerCase()) ||
        item.CloseDate?.toLowerCase().includes(query.toLowerCase()) ||
        item.Amount.toString().includes(query)
    );
  };

  const header = [
    'Company',
    'Actual plan',
    'Service',
    'Expiration Date',
    'Amount',
  ];
  const body = filterAndSortArray(search2(newArray), sort, orientation).map(
    (data) => [
      data.Partner_Client__c,
      data.ProductName,
      data.Type,
      data.Contract_Expiration_Date__c,
      data.Amount,
    ]
  );

  function handleDownloadExcel() {
    downloadExcel({
      fileName: 'Excel Customers',
      sheet: 'Customers',
      tablePayload: {
        header,
        body: body,
      },
    });
    // console.log(search2(newArray));
  }

  return (
    <div>
      <div className=''>
        <div className='flex justify-between header-background text-white p-5 h-[72px] lg:h-[100px] xl:h-[125px] 2xl:h-[150px] 2xl:pl-[40px] 2xl:pr-[26px] rounded-2xl m-3 lg:m-7'>
          <div className='flex items-center'>
            <h1 className='text-2xl 2xl:text-3xl font-semibold ml-2 md:ml-4 2xl:ml-0'>
              Active customers
            </h1>
          </div>

          <div className='hidden md:flex items-center space-x-4 mr-4 2xl:mr-[9px] 2xl:ml-0 '>
            <div className='flex items-center'>
              <input
                type='text'
                id='website-admin'
                className='rounded-md relative h-8 2xl:h-10 pr-6 left-8 pl-3 bg-gray-50 border  text-gray-900 focus:ring-mainblue focus:border-mainblue block flex-1 min-w-0 w-full text-sm border-gray-300'
                placeholder='Search'
                onChange={(e) => setQuery(e.target.value)}
              />
              <span className='inline-flex relative right-2  items-center px-2 text-gray-900 bg-gray-[#DBE3EB] rounded-l-md  border-r-0'>
                <img alt='search' src={Search} />
              </span>
            </div>
            <button
              onClick={handleDownloadExcel}
              className='text-[#292929] h-8 2xl:h-10 text-sm xl:text-base hidden md:flex items-center bg-yellow-gradient btn rounded-lg p-2 ml-6'
            >
              <Export className='mr-1 w-4  xl:w-5 xl:h-4' fill='#292929' />
              Download
            </button>
          </div>
        </div>
      </div>
      <div className=''>
        <MyCustomersTable
          loading={callLoading}
          setSort={setSort}
          setOrientation={setOrientation}
          data={filterAndSortArray(search2(newArray), sort, orientation)}
        />
      </div>
      <MobileMyCustomers customers={newArray} />
    </div>
  );
};

export default MyCustomers;
