import React from 'react';
import FormAditionalInformation from '../../img/FormAditionalInformation.png';
import WhiteRightArrow from '../../img/WhiteRightArrow.svg';
import BlueLeftArrow from '../../img/BlueLeftArrow.svg';
import { Formik, Form, Field } from 'formik';
import StepThreeImg from '../../img/StepThree.svg';
import * as Yup from 'yup';

const StepThree = (props) => {
  const handleSubmit = (values) => {
    props.next(values);
  };

  const valid_characters_regex =
    /^(?=.*[a-zA-ZÀ-ȕ0-9.!#$%&'*+\-/=?,^_`{|}~])[a-zA-ZÀ-ȕ0-9.!#$%&'*+\-/=?,^_`{|}~ ]{1,500}$/gm;

  const stepThreeValidation = Yup.object({
    Request_Demo__c: Yup.string().required('Request a demo is required'),
    Sales_Notes__c: Yup.string().matches(
      valid_characters_regex,
      'Comment must be text up to 500 characters'
    ),
  });

  return (
    <Formik
      validationSchema={stepThreeValidation}
      enableReinitialize={true}
      initialValues={props.data}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isValid, values, setFieldValue, handleChange }) => (
        <Form className='flex flex-col'>
          <div className='relative min-h-[calc(100vh-300px)] 3xl:max-h-[1080px] 3xl:min-h-max 3xl:max-w-[1283px]'>
            <div className='flex-col sm:flex-row header-background text-white sm:flex sm:items-center sm:justify-between p-5 h-[72px] lg:h-[100px] xl:h-[125px] 2xl:h-[150px] xl:pl-[22px] 2xl:pl-[40px] 2xl:pr-[37px] rounded-2xl m-3 lg:m-7'>
              <h1 className='text-2xl 2xl:text-3xl  font-semibold'>
                Deal registration form
              </h1>
              <img
                className='w-[200px] mt-2 sm:mt-0 sm:w-auto mr-4 2xl:mr-0'
                alt='stepper'
                src={StepThreeImg}
              ></img>
            </div>
            <div className=''>
              <div className='w-full  xl:min-h-[320px] 2xl:min-h-[400px] '>
                <div className='pb-5 pl-[32px] lg:pl-[52px] lg:pr-[63px] 2xl:pl-[70px]'>
                  <div className='flex items-center'>
                    <h3 className='text-lg 2xl:text-xl text-[#b0b0b0] font-semibold'>
                      Deal registration
                    </h3>
                    <button
                      onClick={() => props.prev(values)}
                      className='hover:cursor-pointer'
                    >
                      <h3 className='text-lg ml-1 2xl:text-xl text-[#b0b0b0] hover:text-[#3d82f1] font-semibold'>
                        {'>'} End customer info
                      </h3>
                    </button>
                    <button
                      className={`${
                        values.formType !== '' ? 'hover:cursor-pointer' : ''
                      } `}
                      onClick={
                        values.formType !== ''
                          ? () => {
                              const updatedValues = { ...values, formType: '' };
                              localStorage.setItem(
                                'formValues',
                                JSON.stringify({ ...values, formType: '' })
                              );
                              props.prev(updatedValues);
                            }
                          : () => {}
                      }
                    >
                      <h3
                        className={`${
                          values.formType !== ''
                            ? 'text-[#b0b0b0] hover:cursor-pointer hover:text-[#3d82f1]'
                            : 'text-[#3d82f1] hover:cursor-text'
                        } text-lg ml-1 2xl:text-xl font-semibold`}
                      >
                        {'>'} Plan
                      </h3>
                    </button>
                    {values.formType === 'cloud' ? (
                      <button
                        onClick={() => {
                          props.prev(values);
                        }}
                        className='hover:cursor-pointer'
                      >
                        <h3 className='text-lg ml-1 2xl:text-xl text-[#b0b0b0] hover:text-[#3d82f1] font-semibold'>
                          {'>'} Wazuh Cloud
                        </h3>
                      </button>
                    ) : values.formType === 'onpremises' ? (
                      <button
                        onClick={() => {
                          props.prev(values);
                        }}
                        className='hover:cursor-pointer'
                      >
                        <h3 className='text-lg ml-1 2xl:text-xl text-[#b0b0b0] hover:text-[#3d82f1] font-semibold'>
                          {'>'} On Prem deployment
                        </h3>
                      </button>
                    ) : (
                      <div></div>
                    )}
                    <h3 className='text-lg ml-1 2xl:text-xl text-[#3d82f1] font-semibold'>
                      {'>'} Additional information
                    </h3>
                  </div>
                  <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mt-6 xl:w-full gap-10'>
                    <div className='md:col-span-2 xl:col-span-2'>
                      <img
                        className='h-full'
                        src={FormAditionalInformation}
                      ></img>
                    </div>
                    <div className='xl:col-span-2 w-full'>
                      {errors.Request_Demo__c && touched.Request_Demo__c ? (
                        <p className='font-semibold text-red-500'>
                          {errors.Request_Demo__c}
                        </p>
                      ) : (
                        <p className='text-sm mb-2 2xl:text-base font-semibold'>
                          Will you request a demo?
                        </p>
                      )}

                      <Field
                        className='border mb-4 focus:border-[#3D82F1] border-[#B7C6D7] rounded-md w-[250px] xl:w-full px-2 py-2 text-black placeholder-[#B7C6D7] styled-select'
                        placeholder='-'
                        as='select'
                        name='Request_Demo__c'
                        onChange={(e) => {
                          handleChange(e);
                          localStorage.setItem(
                            'formValues',
                            JSON.stringify({
                              ...values,
                              Request_Demo__c: e.target.value,
                            })
                          );
                        }}
                      >
                        <option selected disabled value=''>
                          -
                        </option>
                        <option value='Yes'>Yes</option>
                        <option value='No'>No</option>
                      </Field>
                      {errors.Sales_Notes__c && touched.Sales_Notes__c ? (
                        <p className='font-semibold  text-red-500'>
                          {errors.Sales_Notes__c}
                        </p>
                      ) : (
                        <p className='text-sm mb-2 2xl:text-base font-semibold'>
                          Comments:
                        </p>
                      )}

                      <Field
                        className=' border focus:border-[#3D82F1] border-[#B7C6D7] rounded-md w-[calc(100vw-100px)] sm:w-[500px] md:w-[700px] lg:w-[500px] xl:w-full px-2 py-2 text-black placeholder-[#B7C6D7]  h-[200px] md:h-[120px] lg:h-[200px] '
                        name='Sales_Notes__c'
                        placeholder='Write your message here:'
                        as='textarea'
                        onChange={(e) => {
                          handleChange(e);
                          localStorage.setItem(
                            'formValues',
                            JSON.stringify({
                              ...values,
                              Sales_Notes__c: e.target.value,
                            })
                          );
                        }}
                      ></Field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='flex w-full 3xl:w-[1283px] justify-between col-span-1 portrait:md:col-span-2 md:col-span-3 lg:col-span-2 xl:col-span-3 py-2'>
            <button
              onClick={() => props.prev(values)}
              type='button'
              className='bg-[#FBFCFD] justify-center border border-[#3D82F1] ml-5 xl:ml-[52px] 2xl:ml-[70px] hover:text-blue-700 hover:border-blue-800 hover:cursor-pointer text-sm 2xl:text-base  2xl:right-[calc(100vw-1500px)]   text-[#3D82F1] rounded-lg px-2 py-2 flex items-center w-[91px] lg:w-[109px] lg:h-[42px]'
            >
              <img
                alt='white arrow pointing right'
                className='relative right-2'
                src={BlueLeftArrow}
              ></img>
              Previous
            </button>

            <button
              type='submit'
              className={`${
                isValid && values.Request_Demo__c !== ''
                  ? 'bg-[#3D82F1] border border-[#3D82F1] hover:bg-blue-800 hover:cursor-pointer text-white'
                  : 'bg-[#B7C6D7] border border-[#B7C6D7] hover:cursor-not-allowed text-white'
              } text-sm 2xl:text-base justify-center  2xl:right-[calc(100vw-1500px)] rounded-lg px-2 py-2 flex items-center mr-5 md:mr-10 lg:mr-16 xl:mr-16 p-5 2xl:mr-16 w-[91px] lg:w-[109px] lg:h-[42px]`}
            >
              Next
              <img
                alt='white arrow pointing right'
                className='relative left-5'
                src={
                  isValid && values.Request_Demo__c !== ''
                    ? WhiteRightArrow
                    : WhiteRightArrow
                }
              ></img>
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default StepThree;
