import React from 'react';
import WhiteRightArrow from '../../img/WhiteRightArrow.svg';
import BlueLeftArrow from '../../img/BlueLeftArrow.svg';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import StepTwoImg from '../../img/StepTwo.png';
import ActiveAgents from '../../img/ActiveAgents.svg';
import IndexedDataCapacity from '../../img/IndexedDataCapacity.svg';
import IndexedDataRetention from '../../img/IndexedDataRetention.svg';
import ArchiveDataRetention from '../../img/ArchiveDataRetention.svg';
import SupportPlan from '../../img/SupportPlan.svg';
import AverageEPS from '../../img/AverageEPS.svg';
import Interrogation from '../../img/Interrogation.svg';
import WhiteCheck from '../../img/WhiteCheck.svg';
import CloudInfo from '../../Popover/CloudInfo';
import { Switch } from '@headlessui/react';

const stepTwoValidation = Yup.object().shape({
  formType: Yup.string(),
  Type: Yup.string().when('formType', {
    is: 'cloud',
    then: Yup.string(),
    otherwise: Yup.string(),
  }),
  Number_of_Servers__c: Yup.number().when('formType', {
    is: 'cloud',
    then: Yup.number(),
    otherwise: Yup.number().required('Number of servers is required'),
  }),
  Number_of_Workstations__c: Yup.number().when('formType', {
    is: 'cloud',
    then: Yup.number(),
    otherwise: Yup.number().required('Number of workstations is required'),
  }),
  Number_of_Network_Devices__c: Yup.number().when('formType', {
    is: 'cloud',
    then: Yup.number(),
    otherwise: Yup.number().required('Number of network devices is required'),
  }),
  Hot_Storage_Retention_Period__c: Yup.string().when('formType', {
    is: 'cloud',
    then: Yup.string(),
    otherwise: Yup.string().required('Hot storage is required'),
  }),
  Cold_Storage_Retention_Period__c: Yup.number().when('formType', {
    is: 'cloud',
    then: Yup.number(),
    otherwise: Yup.number().required('Cold storage is required'),
  }),
  Professional_support: Yup.string().when('formType', {
    is: 'cloud',
    then: Yup.string(),
    otherwise: Yup.string().required('Support is required'),
  }),
  cloudType: Yup.string(),
  activeAgents: Yup.string(),
  indexedDataRetention: Yup.string(),
  archiveDataRetention: Yup.string(),
  supportPlan: Yup.string(),
  averagePeakEPS: Yup.string(),
  useRecommendedValueEPS: Yup.boolean(),
  indexedDataCapacity: Yup.string(),
  useRecommendedValueDataCapacity: Yup.boolean(),
});

const StepTwo = (props) => {
  const handleSubmit = (values) => {
    props.next(values);
  };

  const handleFieldChange = async (values, setFieldValue) => {
    if (
      values.useRecommendedValueEPS === true &&
      values.useRecommendedValueDataCapacity === false
    ) {
      if (values.activeAgents === '100') {
        await setFieldValue('averagePeakEPS', '100 to 500 EPS');
        localStorage.setItem(
          'formValues',
          JSON.stringify({
            ...values,
            averagePeakEPS: '100 to 500 EPS',
          })
        );
      } else if (values.activeAgents === '250') {
        await setFieldValue('averagePeakEPS', '250 to 1250 EPS');
        localStorage.setItem(
          'formValues',
          JSON.stringify({
            ...values,
            averagePeakEPS: '250 to 1250 EPS',
          })
        );
      } else if (values.activeAgents === '500') {
        await setFieldValue('averagePeakEPS', '500 to 2500 EPS');
        localStorage.setItem(
          'formValues',
          JSON.stringify({
            ...values,
            averagePeakEPS: '500 to 2500 EPS',
          })
        );
      } else if (values.activeAgents === '1000') {
        await setFieldValue('averagePeakEPS', '1000 to 5000 EPS');
        localStorage.setItem(
          'formValues',
          JSON.stringify({
            ...values,
            averagePeakEPS: '1000 to 5000 EPS',
          })
        );
      } else if (values.activeAgents === '2000') {
        await setFieldValue('averagePeakEPS', '2000 to 10000 EPS');
        localStorage.setItem(
          'formValues',
          JSON.stringify({
            ...values,
            averagePeakEPS: '2000 to 10000 EPS',
          })
        );
      } else {
        await setFieldValue('averagePeakEPS', 'More than 2000 to 10000 EPS');
        localStorage.setItem(
          'formValues',
          JSON.stringify({
            ...values,
            averagePeakEPS: 'More than 2000 to 10000 EPS',
          })
        );
      }
    }
    if (
      values.useRecommendedValueDataCapacity === true &&
      values.useRecommendedValueEPS === false
    ) {
      if (values.averagePeakEPS === '100 to 500 EPS') {
        if (values.indexedDataRetention === '1 Month') {
          await setFieldValue('indexedDataCapacity', '25 GB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...values,
              indexedDataCapacity: '25 GB',
            })
          );
        } else if (values.indexedDataRetention === '3 Months') {
          await setFieldValue('indexedDataCapacity', '100 GB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...values,
              indexedDataCapacity: '100 GB',
            })
          );
        } else if (values.indexedDataRetention === '6 Months') {
          await setFieldValue('indexedDataCapacity', '250 GB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...values,
              indexedDataCapacity: '250 GB',
            })
          );
        } else if (values.indexedDataRetention === 'More than 6') {
          await setFieldValue('indexedDataCapacity', 'More than 4 TB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...values,
              indexedDataCapacity: 'More than 4 TB',
            })
          );
        }
      } else if (values.averagePeakEPS === '250 to 1250 EPS') {
        if (values.indexedDataRetention === '1 Month') {
          await setFieldValue('indexedDataCapacity', '50 GB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...values,
              indexedDataCapacity: '50 GB',
            })
          );
        } else if (values.indexedDataRetention === '3 Months') {
          await setFieldValue('indexedDataCapacity', '250 GB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...values,
              indexedDataCapacity: '250 GB',
            })
          );
        } else if (values.indexedDataRetention === '6 Months') {
          await setFieldValue('indexedDataCapacity', '500 GB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...values,
              indexedDataCapacity: '500 GB',
            })
          );
        } else if (values.indexedDataRetention === 'More than 6') {
          await setFieldValue('indexedDataCapacity', 'More than 4 TB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...values,
              indexedDataCapacity: 'More than 4 TB',
            })
          );
        }
      } else if (values.averagePeakEPS === '500 to 2500 EPS') {
        if (values.indexedDataRetention === '1 Month') {
          await setFieldValue('indexedDataCapacity', '100 GB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...values,
              indexedDataCapacity: '100 GB',
            })
          );
        } else if (values.indexedDataRetention === '3 Months') {
          await setFieldValue('indexedDataCapacity', '500 GB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...values,
              indexedDataCapacity: '500 GB',
            })
          );
        } else if (values.indexedDataRetention === '6 Months') {
          await setFieldValue('indexedDataCapacity', '1 TB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...values,
              indexedDataCapacity: '1 TB',
            })
          );
        } else if (values.indexedDataRetention === 'More than 6') {
          await setFieldValue('indexedDataCapacity', 'More than 4 TB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...values,
              indexedDataCapacity: 'More than 4 TB',
            })
          );
        }
      } else if (values.averagePeakEPS === '1000 to 5000 EPS') {
        if (values.indexedDataRetention === '1 Month') {
          await setFieldValue('indexedDataCapacity', '250 GB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...values,
              indexedDataCapacity: '250 GB',
            })
          );
        } else if (values.indexedDataRetention === '3 Months') {
          await setFieldValue('indexedDataCapacity', '1 TB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...values,
              indexedDataCapacity: '1 TB',
            })
          );
        } else if (values.indexedDataRetention === '6 Months') {
          await setFieldValue('indexedDataCapacity', '2 TB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...values,
              indexedDataCapacity: '2 TB',
            })
          );
        } else if (values.indexedDataRetention === 'More than 6') {
          await setFieldValue('indexedDataCapacity', 'More than 4 TB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...values,
              indexedDataCapacity: 'More than 4 TB',
            })
          );
        }
      } else if (values.averagePeakEPS === '2000 to 10000 EPS') {
        if (values.indexedDataRetention === '1 Month') {
          await setFieldValue('indexedDataCapacity', '500 GB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...values,
              indexedDataCapacity: '500 GB',
            })
          );
        } else if (values.indexedDataRetention === '3 Months') {
          await setFieldValue('indexedDataCapacity', '2 TB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...values,
              indexedDataCapacity: '2 TB',
            })
          );
        } else if (values.indexedDataRetention === '6 Months') {
          await setFieldValue('indexedDataCapacity', '4 TB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...values,
              indexedDataCapacity: '4 TB',
            })
          );
        } else if (values.indexedDataRetention === 'More than 6') {
          await setFieldValue('indexedDataCapacity', 'More than 4 TB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...values,
              indexedDataCapacity: 'More than 4 TB',
            })
          );
        }
      } else if (values.averagePeakEPS === 'More than 2000 to 10000 EPS') {
        await setFieldValue('indexedDataCapacity', 'More than 4 TB');
        localStorage.setItem(
          'formValues',
          JSON.stringify({
            ...values,
            indexedDataCapacity: 'More than 4 TB',
          })
        );
      }
    }
    if (
      values.useRecommendedValueEPS === true &&
      values.useRecommendedValueDataCapacity === true
    ) {
      if (values.activeAgents === '100') {
        await setFieldValue('averagePeakEPS', '100 to 500 EPS');
        const originalValues = { ...values, averagePeakEPS: '100 to 500 EPS' };
        if (values.indexedDataRetention === '1 Month') {
          setFieldValue('indexedDataCapacity', '25 GB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...originalValues,
              indexedDataCapacity: '25 GB',
            })
          );
        } else if (values.indexedDataRetention === '3 Months') {
          setFieldValue('indexedDataCapacity', '100 GB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...originalValues,
              indexedDataCapacity: '100 GB',
            })
          );
        } else if (values.indexedDataRetention === '6 Months') {
          setFieldValue('indexedDataCapacity', '250 GB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...originalValues,
              indexedDataCapacity: '250 GB',
            })
          );
        } else if (values.indexedDataRetention === 'More than 6') {
          setFieldValue('indexedDataCapacity', 'More than 4 TB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...originalValues,
              indexedDataCapacity: 'More than 4 TB',
            })
          );
        }
      } else if (values.activeAgents === '250') {
        await setFieldValue('averagePeakEPS', '250 to 1250 EPS');
        const originalValues = { ...values, averagePeakEPS: '250 to 1250 EPS' };
        if (values.indexedDataRetention === '1 Month') {
          setFieldValue('indexedDataCapacity', '50 GB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...originalValues,
              indexedDataCapacity: '50 GB',
            })
          );
        } else if (values.indexedDataRetention === '3 Months') {
          setFieldValue('indexedDataCapacity', '250 GB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...originalValues,
              indexedDataCapacity: '250 GB',
            })
          );
        } else if (values.indexedDataRetention === '6 Months') {
          setFieldValue('indexedDataCapacity', '500 GB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...originalValues,
              indexedDataCapacity: '500 GB',
            })
          );
        } else if (values.indexedDataRetention === 'More than 6') {
          setFieldValue('indexedDataCapacity', 'More than 4 TB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...originalValues,
              indexedDataCapacity: 'More than 4 TB',
            })
          );
        }
      } else if (values.activeAgents === '500') {
        await setFieldValue('averagePeakEPS', '500 to 2500 EPS');
        const originalValues = { ...values, averagePeakEPS: '500 to 2500 EPS' };
        if (values.indexedDataRetention === '1 Month') {
          setFieldValue('indexedDataCapacity', '100 GB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...originalValues,
              indexedDataCapacity: '100 GB',
            })
          );
        } else if (values.indexedDataRetention === '3 Months') {
          setFieldValue('indexedDataCapacity', '500 GB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...originalValues,
              indexedDataCapacity: '500 GB',
            })
          );
        } else if (values.indexedDataRetention === '6 Months') {
          setFieldValue('indexedDataCapacity', '1 TB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...originalValues,
              indexedDataCapacity: '1 TB',
            })
          );
        } else if (values.indexedDataRetention === 'More than 6') {
          setFieldValue('indexedDataCapacity', 'More than 4 TB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...originalValues,
              indexedDataCapacity: 'More than 4 TB',
            })
          );
        }
      } else if (values.activeAgents === '1000') {
        await setFieldValue('averagePeakEPS', '1000 to 5000 EPS');
        const originalValues = {
          ...values,
          averagePeakEPS: '1000 to 5000 EPS',
        };
        if (values.indexedDataRetention === '1 Month') {
          setFieldValue('indexedDataCapacity', '250 GB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...originalValues,
              indexedDataCapacity: '250 GB',
            })
          );
        } else if (values.indexedDataRetention === '3 Months') {
          setFieldValue('indexedDataCapacity', '1 TB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...originalValues,
              indexedDataCapacity: '1 TB',
            })
          );
        } else if (values.indexedDataRetention === '6 Months') {
          setFieldValue('indexedDataCapacity', '2 TB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...originalValues,
              indexedDataCapacity: '2 TB',
            })
          );
        } else if (values.indexedDataRetention === 'More than 6') {
          setFieldValue('indexedDataCapacity', 'More than 4 TB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...originalValues,
              indexedDataCapacity: 'More than 4 TB',
            })
          );
        }
      } else if (values.activeAgents === '2000') {
        await setFieldValue('averagePeakEPS', '2000 to 10000 EPS');
        const originalValues = {
          ...values,
          averagePeakEPS: '2000 to 10000 EPS',
        };
        if (values.indexedDataRetention === '1 Month') {
          setFieldValue('indexedDataCapacity', '500 GB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...originalValues,
              indexedDataCapacity: '500 GB',
            })
          );
        } else if (values.indexedDataRetention === '3 Months') {
          setFieldValue('indexedDataCapacity', '2 TB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...originalValues,
              indexedDataCapacity: '2 TB',
            })
          );
        } else if (values.indexedDataRetention === '6 Months') {
          setFieldValue('indexedDataCapacity', '4 TB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...originalValues,
              indexedDataCapacity: '4 TB',
            })
          );
        } else if (values.indexedDataRetention === 'More than 6') {
          setFieldValue('indexedDataCapacity', 'More than 4 TB');
          localStorage.setItem(
            'formValues',
            JSON.stringify({
              ...originalValues,
              indexedDataCapacity: 'More than 4 TB',
            })
          );
        }
      } else {
        const originalValues = {
          ...values,
          averagePeakEPS: 'More than 2000 to 10000 EPS',
        };
        await setFieldValue('averagePeakEPS', 'More than 2000 to 10000 EPS');
        await setFieldValue('indexedDataCapacity', 'More than 4 TB');
        localStorage.setItem(
          'formValues',
          JSON.stringify({
            ...originalValues,
            indexedDataCapacity: 'More than 4 TB',
          })
        );
      }
    }
  };

  return (
    <Formik
      className=''
      enableReinitialize={true}
      validationSchema={stepTwoValidation}
      initialValues={props.data}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isValid, values, setFieldValue, handleChange }) => (
        <Form className='flex flex-col '>
          <div className='relative min-h-[calc(100vh-500px)] 3xl:max-h-[1080px] 3xl:min-h-max 3xl:max-w-[1283px]'>
            <div className='flex-col sm:flex-row header-background text-white sm:flex sm:items-center sm:justify-between p-5 h-[72px] lg:h-[100px] xl:h-[125px] 2xl:h-[150px] xl:pl-[22px] 2xl:pl-[40px] 2xl:pr-[37px] rounded-2xl m-3 lg:m-7'>
              <h1 className='text-2xl 2xl:text-3xl  font-semibold'>
                Deal registration form
              </h1>
              <img
                className='w-[200px] h-[30px] mt-2 sm:mt-0 sm:w-auto mr-4 2xl:mr-0'
                alt='stepper'
                src={StepTwoImg}
              ></img>
            </div>
            <div className=''>
              <div className='w-full  xl:min-h-[280px] 2xl:min-h-[400px] '>
                <div className='pb-5 pl-[32px] lg:pl-[52px] lg:pr-[63px] 2xl:pl-[70px]'>
                  <div className='flex items-center'>
                    <h3 className='text-lg 2xl:text-xl text-[#b0b0b0] font-semibold'>
                      Deal registration
                    </h3>
                    <button
                      onClick={() => props.prev(values)}
                      className='hover:cursor-pointer'
                    >
                      <h3 className='text-lg ml-1 2xl:text-xl text-[#b0b0b0] hover:text-[#3d82f1] font-semibold'>
                        {'>'} End customer info
                      </h3>
                    </button>
                    <button
                      className={`${
                        values.formType !== '' ? 'hover:cursor-pointer' : ''
                      } `}
                      onClick={
                        values.formType !== ''
                          ? () => {
                              setFieldValue('formType', '');
                              localStorage.setItem(
                                'formValues',
                                JSON.stringify({ ...values, formType: '' })
                              );
                            }
                          : () => {}
                      }
                    >
                      <h3
                        className={`${
                          values.formType !== ''
                            ? 'text-[#b0b0b0] hover:cursor-pointer hover:text-[#3d82f1]'
                            : 'text-[#3d82f1] hover:cursor-text'
                        } text-lg ml-1 2xl:text-xl font-semibold`}
                      >
                        {'>'} Plan
                      </h3>
                    </button>
                    {values.formType === 'cloud' ? (
                      <button type='button' className='hover:cursor-pointer'>
                        <h3 className='text-lg ml-1 2xl:text-xl text-[#3d82f1] hover:text-[#3d82f1] font-semibold'>
                          {'>'} Wazuh Cloud
                        </h3>
                      </button>
                    ) : values.formType === 'onpremises' ? (
                      <button type='button' className='hover:cursor-pointer'>
                        <h3 className='text-lg ml-1 2xl:text-xl text-[#3d82f1] hover:text-[#3d82f1] font-semibold'>
                          {'>'} On Prem deployment
                        </h3>
                      </button>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  {values.formType === '' && (
                    <div className='flex w-full justify-center gap-12 mt-8'>
                      <Field
                        type='radio'
                        id='cloud'
                        name='formType'
                        value='cloud'
                        className='hidden peer'
                        required
                        onChange={(e) => {
                          handleChange(e);
                          localStorage.setItem(
                            'formValues',
                            JSON.stringify({
                              ...values,
                              formType: e.target.value,
                            })
                          );
                        }}
                      />
                      <label
                        htmlFor='cloud'
                        className='bg-gray-400 hover:cursor-pointer  hover:bg-blue-400 w-full py-3 text-white rounded-xl text-center'
                      >
                        Wazuh Cloud
                      </label>
                      <Field
                        type='radio'
                        id='onpremises'
                        name='formType'
                        value='onpremises'
                        className='hidden peer'
                        required
                        onChange={(e) => {
                          handleChange(e);
                          localStorage.setItem(
                            'formValues',
                            JSON.stringify({
                              ...values,
                              formType: e.target.value,
                            })
                          );
                        }}
                      />
                      <label
                        htmlFor='onpremises'
                        className='bg-gray-400 hover:cursor-pointer  hover:bg-blue-400 w-full py-3 text-white rounded-xl text-center'
                      >
                        On prem deployment
                      </label>
                    </div>
                  )}
                  {values.formType === 'cloud' && (
                    <div className='flex mt-8'>
                      <div className='grid gap-3 md:gap-x-10 grid-cols-2 md:flex w-full justify-between'>
                        <li className='flex justify-between w-full'>
                          <Field
                            type='radio'
                            id='small'
                            name='cloudType'
                            value='small'
                            className='hidden peer'
                            required
                            onChange={(e) => {
                              handleChange(e);
                              setFieldValue('cloudType', 'small');
                              setFieldValue('activeAgents', '100');
                              setFieldValue('indexedDataRetention', '1 Month');
                              setFieldValue('archiveDataRetention', '3 Months');
                              setFieldValue('supportPlan', 'Standard');
                              setFieldValue('averagePeakEPS', '100 to 500 EPS');
                              setFieldValue('indexedDataCapacity', '25 GB');
                              localStorage.setItem(
                                'formValues',
                                JSON.stringify({
                                  ...values,
                                  cloudType: e.target.value,
                                  activeAgents: '100',
                                  indexedDataRetention: '1 Month',
                                  archiveDataRetention: '3 Months',
                                  supportPlan: 'Standard',
                                  averagePeakEPS: '100 to 500 EPS',
                                  indexedDataCapacity: '25 GB',
                                })
                              );
                            }}
                          />

                          <label
                            htmlFor='small'
                            className=' shadow-md border border-transparent peer-checked:border-blue-500 hover:border-blue-500 text-sm md:text-xl font-semibold hover:cursor-pointer  bg-white py-3 items-center w-full text-black rounded-xl text-center'
                          >
                            Small
                          </label>
                        </li>
                        <li className='flex justify-between w-full'>
                          <Field
                            type='radio'
                            id='medium'
                            name='cloudType'
                            value='medium'
                            className='hidden peer'
                            required
                            onChange={(e) => {
                              handleChange(e);
                              setFieldValue('cloudType', 'medium');
                              setFieldValue('activeAgents', '250');
                              setFieldValue('indexedDataRetention', '3 Months');
                              setFieldValue('archiveDataRetention', '1 Year');
                              setFieldValue('supportPlan', 'Standard');
                              setFieldValue(
                                'averagePeakEPS',
                                '250 to 1250 EPS'
                              );
                              setFieldValue('indexedDataCapacity', '250 GB');
                              localStorage.setItem(
                                'formValues',
                                JSON.stringify({
                                  ...values,
                                  cloudType: e.target.value,
                                  activeAgents: '250',
                                  indexedDataRetention: '3 Months',
                                  archiveDataRetention: '1 Year',
                                  supportPlan: 'Standard',
                                  averagePeakEPS: '250 to 1250 EPS',
                                  indexedDataCapacity: '250 GB',
                                })
                              );
                            }}
                          />

                          <label
                            htmlFor='medium'
                            className=' shadow-md border border-transparent peer-checked:border-blue-500 hover:border-blue-500 text-sm md:text-xl font-semibold hover:cursor-pointer  bg-white py-3 items-center w-full text-black rounded-xl text-center'
                          >
                            Medium
                          </label>
                        </li>
                        <li className='flex justify-between w-full'>
                          <Field
                            type='radio'
                            id='large'
                            name='cloudType'
                            value='large'
                            className='hidden peer'
                            required
                            onChange={(e) => {
                              handleChange(e);
                              setFieldValue('cloudType', 'large');
                              setFieldValue('activeAgents', '500');
                              setFieldValue('indexedDataRetention', '3 Months');
                              setFieldValue('archiveDataRetention', '1 Year');
                              setFieldValue('supportPlan', 'Standard');
                              setFieldValue(
                                'averagePeakEPS',
                                '500 to 2500 EPS'
                              );
                              setFieldValue('indexedDataCapacity', '500 GB');
                              localStorage.setItem(
                                'formValues',
                                JSON.stringify({
                                  ...values,
                                  cloudType: e.target.value,
                                  activeAgents: '500',
                                  indexedDataRetention: '3 Months',
                                  archiveDataRetention: '1 Year',
                                  supportPlan: 'Standard',
                                  averagePeakEPS: '500 to 2500 EPS',
                                  indexedDataCapacity: '500 GB',
                                })
                              );
                            }}
                          />

                          <label
                            htmlFor='large'
                            className=' shadow-md border border-transparent peer-checked:border-blue-500 hover:border-blue-500 text-sm md:text-xl font-semibold hover:cursor-pointer  bg-white py-3 items-center w-full text-black rounded-xl text-center'
                          >
                            Large
                          </label>
                        </li>
                        <li className='flex justify-between w-full'>
                          <Field
                            type='radio'
                            id='custom'
                            name='cloudType'
                            value='custom'
                            className='hidden peer'
                            required
                            onChange={(e) => {
                              setFieldValue('cloudType', 'custom');
                              localStorage.setItem(
                                'formValues',
                                JSON.stringify({
                                  ...values,
                                  cloudType: e.target.value,
                                })
                              );
                            }}
                          />

                          <label
                            htmlFor='custom'
                            className=' shadow-md border border-transparent peer-checked:border-blue-500 hover:border-blue-500 text-sm md:text-xl font-semibold hover:cursor-pointer  bg-white py-3 items-center w-full text-black rounded-xl text-center'
                          >
                            Custom
                          </label>
                        </li>
                      </div>
                    </div>
                  )}
                  {values.formType === 'cloud' && (
                    <div className='flex mt-8 2xl:mt-16'>
                      <div className='flex flex-col w-full justify-between'>
                        <div className='flex'>
                          <h2 className='text-base lg:text-xl 2xl:text-2xl font-semibold md:w-1/2'>
                            Basic settings
                          </h2>
                          <h2 className='hidden md:block text-base lg:text-xl 2xl:text-2xl font-semibold md:w-1/2 lg:ml-10'>
                            Advanced settings
                          </h2>
                        </div>
                        <div className='flex flex-col md:flex-row'>
                          <div className='flex flex-col md:w-1/2 md:justify-around'>
                            <div className='flex mt-4 2xl:mt-8 gap-4 items-center   2xl:ml-[25px]'>
                              <img
                                className='w-8 md:w-7 xl:w-8'
                                src={ActiveAgents}
                              ></img>
                              <p className='w-28 md:w-16 xl:w-44 text-sm xl:text-base'>
                                Active Agents
                              </p>
                              <Field
                                // disabled={values.cloudType != 'custom'}
                                name='activeAgents'
                                as='select'
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFieldValue('cloudType', 'custom');
                                  setFieldValue('activeAgents', value);
                                  handleFieldChange(
                                    {
                                      ...values,
                                      activeAgents: value,
                                    },
                                    setFieldValue
                                  );
                                  localStorage.setItem(
                                    'formValues',
                                    JSON.stringify({
                                      ...values,
                                      activeAgents: e.target.value,
                                      cloudType: 'custom',
                                    })
                                  );
                                  // if (values.useRecommendedValueEPS === true) {
                                  //   if (value === '100') {
                                  //     setFieldValue(
                                  //       'averagePeakEPS',
                                  //       '100 to 500 EPS'
                                  //     );
                                  //   } else if (value === '250') {
                                  //     setFieldValue(
                                  //       'averagePeakEPS',
                                  //       '250 to 1250 EPS'
                                  //     );
                                  //   } else if (value === '500') {
                                  //     setFieldValue(
                                  //       'averagePeakEPS',
                                  //       '500 to 2500 EPS'
                                  //     );
                                  //   } else if (value === '1000') {
                                  //     setFieldValue(
                                  //       'averagePeakEPS',
                                  //       '1000 to 5000 EPS'
                                  //     );
                                  //   } else if (value === '2000') {
                                  //     setFieldValue(
                                  //       'averagePeakEPS',
                                  //       '2000 to 10000 EPS'
                                  //     );
                                  //   } else {
                                  //     setFieldValue(
                                  //       'averagePeakEPS',
                                  //       'More than 2000 to 10000 EPS'
                                  //     );
                                  //   }
                                  // }
                                  // if (
                                  //   values.useRecommendedValueDataCapacity ===
                                  //   true
                                  // ) {
                                  //   if (
                                  //     value === '100' &&
                                  //     values.indexedDataRetention === '1 Month'
                                  //   ) {
                                  //     setFieldValue(
                                  //       'indexedDataCapacity',
                                  //       '25 GB'
                                  //     );
                                  //   } else if (
                                  //     value === '100' &&
                                  //     values.indexedDataRetention === '3 Months'
                                  //   ) {
                                  //     setFieldValue(
                                  //       'indexedDataCapacity',
                                  //       '100 GB'
                                  //     );
                                  //   } else if (
                                  //     value === '100' &&
                                  //     values.indexedDataRetention === '6 Months'
                                  //   ) {
                                  //     setFieldValue(
                                  //       'indexedDataCapacity',
                                  //       '250 GB'
                                  //     );
                                  //   } else if (
                                  //     value === '100' &&
                                  //     values.indexedDataRetention ===
                                  //       'More than 6'
                                  //   ) {
                                  //     setFieldValue(
                                  //       'indexedDataCapacity',
                                  //       'More than 4 TB'
                                  //     );
                                  //   } else if (
                                  //     value === '250' &&
                                  //     values.indexedDataRetention === '1 Month'
                                  //   ) {
                                  //     setFieldValue(
                                  //       'indexedDataCapacity',
                                  //       '50 GB'
                                  //     );
                                  //   } else if (
                                  //     value === '250' &&
                                  //     values.indexedDataRetention === '3 Months'
                                  //   ) {
                                  //     setFieldValue(
                                  //       'indexedDataCapacity',
                                  //       '250 GB'
                                  //     );
                                  //   } else if (
                                  //     value === '250' &&
                                  //     values.indexedDataRetention === '6 Months'
                                  //   ) {
                                  //     setFieldValue(
                                  //       'indexedDataCapacity',
                                  //       '500 GB'
                                  //     );
                                  //   } else if (
                                  //     value === '250' &&
                                  //     values.indexedDataRetention ===
                                  //       'More than 6'
                                  //   ) {
                                  //     setFieldValue(
                                  //       'indexedDataCapacity',
                                  //       'More than 4 TB'
                                  //     );
                                  //   } else if (
                                  //     value === '500' &&
                                  //     values.indexedDataRetention === '1 Month'
                                  //   ) {
                                  //     setFieldValue(
                                  //       'indexedDataCapacity',
                                  //       '100 GB'
                                  //     );
                                  //   } else if (value === '1000') {
                                  //     setFieldValue(
                                  //       'indexedDataCapacity',
                                  //       '250 GB'
                                  //     );
                                  //   } else if (value === '2000') {
                                  //     setFieldValue(
                                  //       'indexedDataCapacity',
                                  //       '500 GB'
                                  //     );
                                  //   } else {
                                  //     setFieldValue(
                                  //       'indexedDataCapacity',
                                  //       'More than 4 TB'
                                  //     );
                                  //   }
                                  // }
                                }}
                                className='styled-select border focus:border-[#3D82F1]  border-[#B7C6D7] rounded-md w-[300px] md:w-[200px] 2xl:w-[300px] px-2 py-2 text-black placeholder-[#B7C6D7]'
                              >
                                <option value='100'>Up to 100</option>
                                <option value='250'>Up to 250</option>
                                <option value='500'>Up to 500</option>
                                <option value='1000'>Up to 1,000</option>
                                <option value='2000'>Up to 2,000</option>
                                <option value='More than 2000'>
                                  More than 2,000
                                </option>
                              </Field>
                              <CloudInfo
                                text={
                                  'The Wazuh agent is installed on your endpoints, such as workstations, servers, and cloud instances. It provides threat protection and is used to collect log data and security events.'
                                }
                              />
                            </div>
                            <div className='flex gap-4 mt-4 items-center    2xl:ml-[25px]'>
                              <img
                                className='w-8 md:w-7 xl:w-8'
                                src={IndexedDataRetention}
                              ></img>
                              <p className='w-28 md:w-16 xl:w-44 text-sm xl:text-base'>
                                Indexed data retention
                              </p>
                              <Field
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFieldValue('indexedDataRetention', value);
                                  setFieldValue('cloudType', 'custom');
                                  handleFieldChange(
                                    { ...values, indexedDataRetention: value },
                                    setFieldValue
                                  );
                                  localStorage.setItem(
                                    'formValues',
                                    JSON.stringify({
                                      ...values,
                                      indexedDataRetention: e.target.value,
                                      cloudType: 'custom',
                                    })
                                  );
                                }}
                                as='select'
                                name='indexedDataRetention'
                                // disabled={values.cloudType != 'custom'}
                                className='styled-select border focus:border-[#3D82F1]  border-[#B7C6D7] rounded-md w-[300px] md:w-[200px] 2xl:w-[300px] px-2 py-2 text-black placeholder-[#B7C6D7]'
                              >
                                <option value='1 Month'>1 month</option>
                                <option value='3 Months'>3 months</option>
                                <option value='6 Months'>6 months</option>
                                <option value='More than 6'>
                                  More than 6 months
                                </option>
                              </Field>
                              <CloudInfo
                                text={
                                  'Defines how long your searchable, indexed data is retained for analysis and reporting. The earliest reached threshold, either in terms of retention time or storage capacity, will be applied as the limit.'
                                }
                              />
                            </div>
                            <div className='flex gap-4 mt-4 items-center    2xl:ml-[25px]'>
                              <img
                                className='w-8 md:w-7 xl:w-8'
                                src={ArchiveDataRetention}
                              ></img>
                              <p className='w-28 md:w-16 xl:w-44 text-sm xl:text-base'>
                                Archive data <br></br> retention
                              </p>
                              <Field
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFieldValue('archiveDataRetention', value);
                                  setFieldValue('cloudType', 'custom');
                                  localStorage.setItem(
                                    'formValues',
                                    JSON.stringify({
                                      ...values,
                                      archiveDataRetention: e.target.value,
                                      cloudType: 'custom',
                                    })
                                  );
                                }}
                                name='archiveDataRetention'
                                // disabled={values.cloudType != 'custom'}
                                as='select'
                                className='styled-select border focus:border-[#3D82F1]  border-[#B7C6D7] rounded-md w-[300px] md:w-[200px] 2xl:w-[300px] px-2 py-2 text-black placeholder-[#B7C6D7]'
                              >
                                <option value='3 Months'>3 months</option>
                                <option value='6 Months'>6 months</option>
                                <option value='1 Year'>1 year</option>
                                <option value='More than 1 year'>
                                  More than 1 year
                                </option>
                              </Field>
                              <CloudInfo
                                text={
                                  'Determines the storage duration for your historical, raw security alerts data. Retrieving this data will take longer due to its archival nature.'
                                }
                              />
                            </div>
                            <div className='flex gap-4 mt-4 items-center    2xl:ml-[25px]'>
                              <img
                                className='w-8 md:w-7 xl:w-8'
                                src={SupportPlan}
                              ></img>
                              <p className='w-28 md:w-16 xl:w-44 text-sm xl:text-base'>
                                Support plan
                              </p>
                              <Field
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFieldValue('supportPlan', value);
                                  setFieldValue('cloudType', 'custom');
                                  localStorage.setItem(
                                    'formValues',
                                    JSON.stringify({
                                      ...values,
                                      supportPlan: e.target.value,
                                      cloudType: 'custom',
                                    })
                                  );
                                }}
                                as='select'
                                name='supportPlan'
                                // disabled={values.cloudType != 'custom'}
                                className='styled-select border focus:border-[#3D82F1]  border-[#B7C6D7] rounded-md w-[300px] md:w-[200px] 2xl:w-[300px] px-2 py-2 text-black placeholder-[#B7C6D7]'
                              >
                                <option value='Standard'>
                                  Standard support
                                </option>
                                <option value='Premium'>Premium support</option>
                              </Field>
                              <CloudInfo
                                text={
                                  'Choose the level of support that suits your needs. Standard support provides robust assistance for everydar requirements. Premium support offers more extensive support, including around-the-clock assistance and prioritized resolution of issues.'
                                }
                              />
                            </div>
                          </div>

                          <div className='flex flex-col md:w-1/2 lg:ml-10 2xl:ml-[100px]'>
                            <h2 className='block md:hidden text-base xl:text-2xl font-semibold md:w-1/2 mt-8'>
                              Advanced settings
                            </h2>
                            <div className='flex mt-4 2xl:mt-8 gap-4 items-center'>
                              <img
                                className='w-8 md:w-7 xl:w-8'
                                src={AverageEPS}
                              ></img>
                              <p className='w-28 md:w-16 xl:w-44 text-sm xl:text-base'>
                                Average / Peak EPS
                              </p>
                              <Field
                                name='averagePeakEPS'
                                disabled={
                                  values.useRecommendedValueEPS === true
                                }
                                as='select'
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFieldValue('averagePeakEPS', value);
                                  handleFieldChange(
                                    { ...values, averagePeakEPS: value },
                                    setFieldValue
                                  );
                                  localStorage.setItem(
                                    'formValues',
                                    JSON.stringify({
                                      ...values,
                                      averagePeakEPS: e.target.value,
                                    })
                                  );
                                  // if (
                                  //   values.useRecommendedValueDataCapacity ===
                                  //   true
                                  // ) {
                                  //   if (value === '100 to 500 EPS') {
                                  //     setFieldValue(
                                  //       'indexedDataCapacity',
                                  //       '25 GB'
                                  //     );
                                  //   } else if (value === '250 to 1250 EPS') {
                                  //     setFieldValue(
                                  //       'indexedDataCapacity',
                                  //       '50 GB'
                                  //     );
                                  //   } else if (value === '500 to 2500 EPS') {
                                  //     setFieldValue(
                                  //       'indexedDataCapacity',
                                  //       '100 GB'
                                  //     );
                                  //   } else if (value === '1000 to 5000 EPS') {
                                  //     setFieldValue(
                                  //       'indexedDataCapacity',
                                  //       '250 GB'
                                  //     );
                                  //   } else if (value === '2000 to 10000 EPS') {
                                  //     setFieldValue(
                                  //       'indexedDataCapacity',
                                  //       '500 GB'
                                  //     );
                                  //   } else {
                                  //     setFieldValue(
                                  //       'indexedDataCapacity',
                                  //       'More than 4 TB'
                                  //     );
                                  //   }
                                  // }
                                }}
                                className='styled-select border focus:border-[#3D82F1]  border-[#B7C6D7] rounded-md w-[300px] md:w-[200px] 2xl:w-[300px] px-2 py-2 text-black placeholder-[#B7C6D7]'
                              >
                                <option value='100 to 500 EPS'>
                                  100 to 500 EPS
                                </option>
                                <option value='250 to 1250 EPS'>
                                  250 to 1250 EPS
                                </option>
                                <option value='500 to 2500 EPS'>
                                  500 to 2500 EPS
                                </option>
                                <option value='1000 to 5000 EPS'>
                                  1000 to 5000 EPS
                                </option>
                                <option value='2000 to 10000 EPS'>
                                  2000 to 10000 EPS
                                </option>
                                <option value='More than 2000 to 10000 EPS'>
                                  More than 2000 to 10000 EPS (contact our sales
                                  team)
                                </option>
                              </Field>
                              <CloudInfo
                                text={
                                  'Represents the normal (average) and maximum (peak) event generation rates per second. The environment efficiently processes events up to the peak rate, while events that exceed this rate are queued. Event drops may occur if these excess events fill the queue to its full capacity.'
                                }
                              />
                            </div>
                            <div className='flex gap-4 mt-4 items-center'>
                              <div className='w-[30px] xl:w-[163px]'></div>
                              <p>Use recommended values</p>
                              <Switch
                                checked={values.useRecommendedValueEPS}
                                onChange={(isChecked) => {
                                  setFieldValue(
                                    'useRecommendedValueEPS',
                                    isChecked
                                  );
                                  if (isChecked === true) {
                                    setFieldValue('cloudType', 'custom');
                                    localStorage.setItem(
                                      'formValues',
                                      JSON.stringify({
                                        ...values,
                                        cloudType: 'custom',
                                      })
                                    );
                                  }
                                  handleFieldChange(
                                    {
                                      ...values,
                                      useRecommendedValueEPS: isChecked,
                                      cloudType: 'custom',
                                    },
                                    setFieldValue
                                  );
                                  localStorage.setItem(
                                    'formValues',
                                    JSON.stringify({
                                      ...values,
                                      useRecommendedValueEPS: isChecked,
                                      cloudType: 'custom',
                                    })
                                  );
                                  // {
                                  //   values.activeAgents === '100'
                                  //     ? setFieldValue(
                                  //         'averagePeakEPS',
                                  //         '100 to 500 EPS'
                                  //       )
                                  //     : values.activeAgents === '250'
                                  //     ? setFieldValue(
                                  //         'averagePeakEPS',
                                  //         '250 to 1250 EPS'
                                  //       )
                                  //     : values.activeAgents === '500'
                                  //     ? setFieldValue(
                                  //         'averagePeakEPS',
                                  //         '500 to 2500 EPS'
                                  //       )
                                  //     : values.activeAgents === '1000'
                                  //     ? setFieldValue(
                                  //         'averagePeakEPS',
                                  //         '1000 to 5000 EPS'
                                  //       )
                                  //     : values.activeAgents === '2000'
                                  //     ? setFieldValue(
                                  //         'averagePeakEPS',
                                  //         '2000 to 10000 EPS'
                                  //       )
                                  //     : values.activeAgents === 'More than 2000'
                                  //     ? setFieldValue(
                                  //         'averagePeakEPS',
                                  //         'More than 2000 to 10000 EPS'
                                  //       )
                                  //     : setFieldValue(
                                  //         'averagePeakEPS',
                                  //         'More than 2000 to 10000 EPS'
                                  //       );
                                  // }
                                }}
                                className={`${
                                  values.useRecommendedValueEPS
                                    ? 'bg-blue-600'
                                    : 'bg-gray-200'
                                } relative inline-flex h-6 w-11 items-center rounded-full`}
                              >
                                <span className='sr-only'>Enable switch</span>
                                <span
                                  className={`${
                                    values.useRecommendedValueEPS
                                      ? 'translate-x-6'
                                      : 'translate-x-1'
                                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                />
                                <img
                                  className='relative right-2'
                                  src={WhiteCheck}
                                ></img>
                              </Switch>
                            </div>
                            <div className='flex gap-4 mt-4 items-center'>
                              <img
                                className='w-8 md:w-7 xl:w-8'
                                src={IndexedDataCapacity}
                              ></img>
                              <p className='w-28 md:w-16 xl:w-44 text-sm xl:text-base'>
                                Indexed data capacity
                              </p>
                              <Field
                                name='indexedDataCapacity'
                                disabled={
                                  values.useRecommendedValueDataCapacity
                                }
                                as='select'
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFieldValue('indexedDataCapacity', value);
                                  localStorage.setItem(
                                    'formValues',
                                    JSON.stringify({
                                      ...values,
                                      indexedDataCapacity: e.target.value,
                                    })
                                  );
                                }}
                                className='styled-select border focus:border-[#3D82F1]  border-[#B7C6D7] rounded-md w-[300px] md:w-[200px] 2xl:w-[300px] px-2 py-2 text-black placeholder-[#B7C6D7]'
                              >
                                <option value='25 GB'>25 GB</option>
                                <option value='50 GB'>50 GB</option>
                                <option value='100 GB'>100 GB</option>
                                <option value='250 GB'>250 GB</option>
                                <option value='500 GB'>500 GB</option>
                                <option value='1 TB'>1 TB</option>
                                <option value='2 TB'>2 TB</option>
                                <option value='3 TB'>3 TB</option>
                                <option value='4 TB'>4 TB</option>
                                <option value='More than 4 TB'>
                                  More than 4 TB (contact our sales team)
                                </option>
                              </Field>
                              <CloudInfo
                                text={
                                  'Sets the storage limit for your indexed security alerts. The earliest reached threshold, either in terms of retention time or storage capacity, will be applied as the limit.'
                                }
                              />
                            </div>
                            <div className='flex gap-4 mt-4 items-center'>
                              <div className='w-[30px] xl:w-[163px]'></div>
                              <p>Use recommended values</p>
                              <Switch
                                checked={values.useRecommendedValueDataCapacity}
                                onChange={(isChecked) => {
                                  setFieldValue(
                                    'useRecommendedValueDataCapacity',
                                    isChecked
                                  );
                                  if (isChecked === true) {
                                    setFieldValue('cloudType', 'custom');
                                  }
                                  handleFieldChange(
                                    {
                                      ...values,
                                      useRecommendedValueDataCapacity:
                                        isChecked,
                                      cloudType: 'custom',
                                    },
                                    setFieldValue
                                  );
                                  localStorage.setItem(
                                    'formValues',
                                    JSON.stringify({
                                      ...values,
                                      useRecommendedValueDataCapacity:
                                        isChecked,
                                      cloudType: 'custom',
                                    })
                                  );
                                  // {
                                  //   values.averagePeakEPS ===
                                  //     '100 to 500 EPS' &&
                                  //   values.indexedDataRetention === '1 Month'
                                  //     ? setFieldValue(
                                  //         'indexedDataCapacity',
                                  //         '25 GB'
                                  //       )
                                  //     : values.averagePeakEPS ===
                                  //         '250 to 1250 EPS' &&
                                  //       values.indexedDataRetention ===
                                  //         '1 Month'
                                  //     ? setFieldValue(
                                  //         'indexedDataCapacity',
                                  //         '50 GB'
                                  //       )
                                  //     : (values.averagePeakEPS ===
                                  //         '500 to 2500 EPS' &&
                                  //         values.indexedDataRetention ===
                                  //           '1 Month') ||
                                  //       (values.averagePeakEPS ===
                                  //         '100 to 500 EPS' &&
                                  //         values.indexedDataRetention ===
                                  //           '3 Months')
                                  //     ? setFieldValue(
                                  //         'indexedDataCapacity',
                                  //         '100 GB'
                                  //       )
                                  //     : values.averagePeakEPS ===
                                  //       '1000 to 5000 EPS'
                                  //     ? setFieldValue(
                                  //         'indexedDataCapacity',
                                  //         '250 GB'
                                  //       )
                                  //     : values.averagePeakEPS ===
                                  //       '2000 to 10000 EPS'
                                  //     ? setFieldValue(
                                  //         'indexedDataCapacity',
                                  //         '500 GB'
                                  //       )
                                  //     : values.averagePeakEPS ===
                                  //       'More than 2000 to 10000 EPS'
                                  //     ? setFieldValue(
                                  //         'indexedDataCapacity',
                                  //         'More than 4 TB'
                                  //       )
                                  //     : setFieldValue(
                                  //         'indexedDataCapacity',
                                  //         'More than 4 TB'
                                  //       );
                                  // }
                                }}
                                className={`${
                                  values.useRecommendedValueDataCapacity
                                    ? 'bg-blue-600'
                                    : 'bg-gray-200'
                                } relative inline-flex h-6 w-11 items-center rounded-full`}
                              >
                                <span className='sr-only'>Enable switch</span>
                                <span
                                  className={`${
                                    values.useRecommendedValueDataCapacity
                                      ? 'translate-x-6'
                                      : 'translate-x-1'
                                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                />
                                <img
                                  className='relative right-2'
                                  src={WhiteCheck}
                                ></img>
                              </Switch>
                            </div>
                            <div className='flex'>
                              <div className='flex mt-4 xl:max-w-[450px]'>
                                <img
                                  className='w-3 xl:w-5 relative bottom-2 mr-2'
                                  src={Interrogation}
                                />
                                <h3 className=' text-sm'>
                                  The recommended settings are based on our
                                  experience, but your workload may differ. Be
                                  sure to deploy, monitor, and adjust them as
                                  needed.{' '}
                                  <a
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href='https://documentation.wazuh.com/current/cloud-service/your-environment/settings.html'
                                    className='text-blue-400 hover:cursor-pointer hover:underline'
                                  >
                                    Read More.
                                  </a>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {values.formType === 'onpremises' && (
                    <div className=''>
                      <div className='w-full  xl:min-h-[300px] 2xl:min-h-[400px]'>
                        <div className='pb-5 '>
                          <div className='grid grid-cols-1 portrait:md:grid-cols-2 md:grid-cols-3  lg:grid-cols-2 xl:grid-cols-3 mt-6 w-full gap-x-10 md:static'>
                            <div className='flex flex-col'>
                              <div>
                                {errors.Number_of_Servers__c &&
                                touched.Number_of_Servers__c ? (
                                  <p className='text-sm 2xl:text-base font-semibold mt-6 md:mt-0 text-red-500 mb-2'>
                                    {errors.Number_of_Servers__c}
                                  </p>
                                ) : (
                                  <p className='text-sm 2xl:text-base font-semibold mt-6 md:mt-0 mb-2'>
                                    Number of servers
                                  </p>
                                )}
                                <Field
                                  className=' border focus:border-[#3D82F1] border-[#B7C6D7] rounded-md w-full px-2 py-2 text-black placeholder-[#B7C6D7]'
                                  type='number'
                                  name='Number_of_Servers__c'
                                  min='0'
                                  placeholder='0'
                                  onChange={(e) => {
                                    handleChange(e);
                                    localStorage.setItem(
                                      'formValues',
                                      JSON.stringify({
                                        ...values,
                                        Number_of_Servers__c: e.target.value,
                                      })
                                    );
                                  }}
                                ></Field>
                              </div>
                            </div>
                            <div className='flex flex-col'>
                              <div>
                                {errors.Number_of_Workstations__c &&
                                touched.Number_of_Workstations__c ? (
                                  <p className='text-sm 2xl:text-base font-semibold mt-6 md:mt-0 text-red-500 mb-2'>
                                    {errors.Number_of_Workstations__c}
                                  </p>
                                ) : (
                                  <p className='text-sm 2xl:text-base font-semibold mt-6 md:mt-0 mb-2'>
                                    Number of workstations
                                  </p>
                                )}

                                <Field
                                  className=' border focus:border-[#3D82F1] border-[#B7C6D7] rounded-md w-full px-2 py-2 text-black placeholder-[#B7C6D7]'
                                  placeholder='0'
                                  type='number'
                                  name='Number_of_Workstations__c'
                                  min='0'
                                  onChange={(e) => {
                                    handleChange(e);
                                    localStorage.setItem(
                                      'formValues',
                                      JSON.stringify({
                                        ...values,
                                        Number_of_Workstations__c:
                                          e.target.value,
                                      })
                                    );
                                  }}
                                ></Field>
                              </div>
                            </div>
                            <div className='lg:mt-6 xl:mt-0 flex flex-col'>
                              <div>
                                {errors.Number_of_Network_Devices__c &&
                                touched.Number_of_Network_Devices__c ? (
                                  <p className='text-sm 2xl:text-base font-semibold mb-2 text-red-500'>
                                    {errors.Number_of_Network_Devices__c}
                                  </p>
                                ) : (
                                  <p className='text-sm 2xl:text-base font-semibold mt-6 md:mt-0 mb-2'>
                                    Number of network devices
                                  </p>
                                )}

                                <Field
                                  className=' border focus:border-[#3D82F1] border-[#B7C6D7] rounded-md w-full px-2 py-2 text-black placeholder-[#B7C6D7]'
                                  placeholder='0'
                                  type='number'
                                  min='0'
                                  name='Number_of_Network_Devices__c'
                                  onChange={(e) => {
                                    handleChange(e);
                                    localStorage.setItem(
                                      'formValues',
                                      JSON.stringify({
                                        ...values,
                                        Number_of_Network_Devices__c:
                                          e.target.value,
                                      })
                                    );
                                  }}
                                ></Field>
                              </div>
                            </div>
                            <div className='mt-6 xl:mt-10 flex flex-col'>
                              <div>
                                {errors.Professional_support &&
                                touched.Professional_support ? (
                                  <p className='text-sm 2xl:text-base font-semibold  text-red-500 mb-2'>
                                    {errors.Professional_support}
                                  </p>
                                ) : (
                                  <p className='text-sm 2xl:text-base font-semibold mb-2'>
                                    Professional support
                                  </p>
                                )}

                                <Field
                                  className=' border focus:border-[#3D82F1] border-[#B7C6D7] rounded-md w-full px-2 py-2 text-black placeholder-[#B7C6D7] styled-select'
                                  as='select'
                                  name='Professional_support'
                                  onChange={(e) => {
                                    handleChange(e);
                                    localStorage.setItem(
                                      'formValues',
                                      JSON.stringify({
                                        ...values,
                                        Professional_support: e.target.value,
                                      })
                                    );
                                  }}
                                >
                                  <option disabled selected value=''>
                                    -
                                  </option>
                                  <option value='Standard'>
                                    Standard (8/5)
                                  </option>
                                  <option value='Premium'>
                                    Premium (24/7)
                                  </option>
                                </Field>
                              </div>
                            </div>

                            <div className='mt-6 xl:mt-10 flex flex-col'>
                              <div>
                                {errors.Hot_Storage_Retention_Period__c &&
                                touched.Hot_Storage_Retention_Period__c ? (
                                  <p className='text-sm 2xl:text-base font-semibold text-red-500 mb-2'>
                                    {errors.Hot_Storage_Retention_Period__c}
                                  </p>
                                ) : (
                                  <p className='text-sm 2xl:text-base font-semibold mb-2'>
                                    Hot storage retention period (days)
                                  </p>
                                )}

                                <Field
                                  className=' border focus:border-[#3D82F1] border-[#B7C6D7] rounded-md w-full px-2 py-2 text-black placeholder-[#B7C6D7] styled-select'
                                  as='select'
                                  name='Hot_Storage_Retention_Period__c'
                                  onChange={(e) => {
                                    handleChange(e);
                                    localStorage.setItem(
                                      'formValues',
                                      JSON.stringify({
                                        ...values,
                                        Hot_Storage_Retention_Period__c:
                                          e.target.value,
                                      })
                                    );
                                  }}
                                >
                                  <option disabled selected value=''>
                                    -
                                  </option>
                                  <option value='1'>Daily</option>
                                  <option value='30'>30</option>
                                  <option value='90'>90</option>
                                  <option value='365'>365</option>
                                  <option value='0'>N/A</option>
                                </Field>
                              </div>
                            </div>
                            <div className='mt-6 xl:mt-10 flex flex-col'>
                              <div>
                                {errors.Cold_Storage_Retention_Period__c &&
                                touched.Cold_Storage_Retention_Period__c ? (
                                  <p className='text-sm 2xl:text-base font-semibold text-red-500 mb-2'>
                                    {errors.Cold_Storage_Retention_Period__c}
                                  </p>
                                ) : (
                                  <p className='text-sm 2xl:text-base font-semibold mb-2'>
                                    Cold storage retention period (days)
                                  </p>
                                )}

                                <Field
                                  className='styled-select border focus:border-[#3D82F1] border-[#B7C6D7] rounded-md w-full px-2 py-2 text-black placeholder-[#B7C6D7]'
                                  as='select'
                                  placeholder='0'
                                  name='Cold_Storage_Retention_Period__c'
                                  onChange={(e) => {
                                    handleChange(e);
                                    localStorage.setItem(
                                      'formValues',
                                      JSON.stringify({
                                        ...values,
                                        Cold_Storage_Retention_Period__c:
                                          e.target.value,
                                      })
                                    );
                                  }}
                                >
                                  <option disabled selected value=''>
                                    -
                                  </option>
                                  <option value='1'>Daily</option>
                                  <option value='30'>30</option>
                                  <option value='90'>90</option>
                                  <option value='365'>365</option>
                                  <option value='0'>N/A</option>{' '}
                                </Field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='flex w-full 3xl:w-[1283px] justify-between col-span-1 portrait:md:col-span-2 md:col-span-3 lg:col-span-2 xl:col-span-3 py-2 mb-6 2xl:mb-3'>
            {values.formType === 'cloud' ? (
              <button
                onClick={() => setFieldValue('formType', '')}
                type='button'
                className='bg-[#FBFCFD] justify-center border border-[#3D82F1] ml-5 xl:ml-[52px] 2xl:ml-[70px] hover:text-blue-700 hover:border-blue-800 hover:cursor-pointer text-sm 2xl:text-base  2xl:right-[calc(100vw-1500px)]   text-[#3D82F1] rounded-lg px-2 py-2 flex items-center w-[91px] lg:w-[109px] lg:h-[42px]'
              >
                <img
                  alt='white arrow pointing right'
                  className='relative right-2'
                  src={BlueLeftArrow}
                ></img>
                Previous
              </button>
            ) : values.formType === 'onpremises' ? (
              <button
                onClick={() => setFieldValue('formType', '')}
                type='button'
                className='bg-[#FBFCFD] justify-center border border-[#3D82F1] ml-5 xl:ml-[52px] 2xl:ml-[70px] hover:text-blue-700 hover:border-blue-800 hover:cursor-pointer text-sm 2xl:text-base  2xl:right-[calc(100vw-1500px)]   text-[#3D82F1] rounded-lg px-2 py-2 flex items-center w-[91px] lg:w-[109px] lg:h-[42px]'
              >
                <img
                  alt='white arrow pointing right'
                  className='relative right-2'
                  src={BlueLeftArrow}
                ></img>
                Previous
              </button>
            ) : (
              <button
                onClick={() => props.prev(values)}
                type='button'
                className='bg-[#FBFCFD] border justify-center border-[#3D82F1] ml-5 xl:ml-[52px] 2xl:ml-[70px] hover:text-blue-700 hover:border-blue-800 hover:cursor-pointer text-sm 2xl:text-base  2xl:right-[calc(100vw-1500px)]   text-[#3D82F1] rounded-lg px-2 py-2 flex items-center w-[91px] lg:w-[109px] lg:h-[42px]'
              >
                <img
                  alt='white arrow pointing right'
                  className='relative right-2'
                  src={BlueLeftArrow}
                ></img>
                Previous
              </button>
            )}

            <button
              type='submit'
              className={`${
                isValid && values.formType !== ''
                  ? 'bg-[#3D82F1] border border-[#3D82F1] hover:bg-blue-800 hover:cursor-pointer text-white'
                  : 'bg-[#B7C6D7] border border-[#B7C6D7] hover:cursor-not-allowed text-white'
              } text-sm 2xl:text-base  2xl:right-[calc(100vw-1500px)] justify-center rounded-lg px-2 py-2 flex items-center mr-5 md:mr-10 lg:mr-16 xl:mr-16 p-5 2xl:mr-16 w-[91px] lg:w-[109px] lg:h-[42px]`}
            >
              Next
              <img
                alt='white arrow pointing right'
                className='relative left-5'
                src={
                  isValid && values.formType !== ''
                    ? WhiteRightArrow
                    : WhiteRightArrow
                }
              ></img>
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default StepTwo;
