import { useState, useEffect } from 'react';
import BlogPostIcon from './img/BlogPostIcon.svg';
import ArrowPagination from './img/ArrowPagination';
import axios from 'axios';

const BlogPostsSection = () => {
  const [postsData, setPostsData] = useState([]);
  const [index, setIndex] = useState(0);
  useEffect(() => {
    const getPostData = async () => {
      const postData = await axios.get(
        'https://wazuh.com/wp-json/wp/v2/posts?_fields=yoast_head_json.schema.%40graph,id,title,link&per_page=10'
      );
      const posts = postData.data.map((post) => {
        const graph = post.yoast_head_json?.schema?.['@graph'];
        const articleSection =
          graph && graph.length > 0 ? graph[0].articleSection[0] : null;
        const cleanedData = post.title.rendered.replace(/&#8211;|–/g, '-');
        return {
          id: post.id,
          title: cleanedData,
          link: post.link,
          articleSection: articleSection,
        };
      });

      setPostsData(posts);
    };
    getPostData();
  }, []);

  const handleNext = () => {
    if (index + 1 < postsData.length) {
      setIndex(index + 1);
    }
  };

  const handlePrev = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  return (
    <div className='flex flex-1 flex-col bg-[#F6F6F6] p-4 rounded-lg overflow-hidden h-[45%]'>
      <div className='flex justify-between'>
        <div className='text-xl font-semibold'>Latest blogposts</div>
        <div className='flex mr-2'>
          <button onClick={handlePrev}>
            <ArrowPagination
              className={`w-2 h-4 ml-2 hover:cursor-pointer hover:scale-125 transform duration-200`}
              mainfill={index === 0 ? '#B7C6D7' : '#387DEC'}
            />
          </button>
          <button onClick={handleNext}>
            <ArrowPagination
              className={`w-2 h-4 ml-2 rotate-180 hover:cursor-pointer hover:scale-125 transform duration-200`}
              mainfill={index + 1 === postsData.length ? '#B7C6D7' : '#387DEC'}
            />
          </button>
        </div>
      </div>
      <span className='form-divider w-full h-1 my-3'></span>
      <div className='flex gap-5 flex-col justify-between h-full'>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={postsData.length && postsData[index].link}
          key={index}
          className={`
              bg-[rgb(61,130,241)]
          rounded-lg p-2 transform hover:scale-105 transition duration-300 ease-out hover:cursor-pointer  h-full`}
        >
          <span
            className={`
                bg-[rgb(112,164,248)]
             rounded-tl-xl rounded-br-xl text-sm px-2 py-1 relative -top-2 -left-2 text-white`}
          >
            {postsData.length && postsData[index].articleSection}
          </span>
          <div className='flex justify-around items-center xl:mt-3'>
            <div
              className={`text-white
               w-[300px] text-lg h-full max-h-[120px] multi-line-ellipsis`}
            >
              {postsData.length && postsData[index].title}
            </div>
            <div className='items-center justify-center flex relative'>
              <div className='bg-white w-24 h-24 flex items-center justify-center p-2 rounded-full'>
                <img
                  className='w-14 h-12'
                  src={BlogPostIcon}
                  alt='Blog post icon'
                />
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default BlogPostsSection;
