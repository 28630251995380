import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import EmailIcon from '../img/EmailIcon.svg';
import WhiteCross from '../img/WhiteCross.svg';
import Oval from '../img/Oval.svg';
import Submitted from '../img/Submitted.svg';
import { Await, Link } from 'react-router-dom';
import CountriesList from '../CountriesList';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useSubmitTrainingMutation } from '../../redux/features/user/trainingsApiSlice';

export default function TrainingModal({
  seats,
  courseName,
  username,
  partner,
  id,
  partnerId,
  seatsLeft,
}) {
  let [isOpen, setIsOpen] = useState(false);
  const [err, setErr] = useState(null);
  const [submitTraining] = useSubmitTrainingMutation();
  let [isSuccessOpen, setIsSuccessOpen] = useState(false);
  let [isErrorOpen, setIsErrorOpen] = useState(false);
  let [submitLoading, setSubmitLoading] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  function openErrorModal() {
    setIsErrorOpen(true);
  }
  function closeErrorModal() {
    setIsErrorOpen(false);
  }

  function closeLoadingModal() {
    setSubmitLoading(false);
  }

  function closeSuccessModal() {
    setIsSuccessOpen(false);
  }

  function openSuccessModal() {
    setIsSuccessOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function goBackHome() {
    setIsOpen(false);
    setIsSuccessOpen(false);
  }

  const registerValidationSchema = Yup.object({
    username: Yup.string()
      .required('Name is required')
      .max(30, 'User must be 30 characters or less.'),
    company: Yup.string().required('Company is required'),
    phone: Yup.string().required('Phone is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required')
      .max(70, 'Email must be 70 characters or less.'),
    confirmationEmail: Yup.string()
      .email('Invalid email address')
      .max(70, 'Email must be 70 characters or less.')
      .oneOf([Yup.ref('email'), null], 'Emails must match'),
    country: Yup.string().required('Country is required'),
    courseName: Yup.string().required('Course Name is required'),
    message: Yup.string().required('Message is required'),
    // password: Yup.string().required('Password is required!'),
  });

  const initialData = {
    LeadSource: 'Training form',
    username: username,
    company: partner,
    phone: '',
    email: '',
    confirmationEmail: '',
    country: 'United States of America',
    courseName: courseName,
    message:
      seats === 0
        ? 'I would like to join the waiting list for this course.'
        : '',
    recaptcha: '',
    courseId: id,
    partnerId: partnerId,
  };

  const handleSubmit = async (formData, { resetForm }) => {
    setSubmitLoading(true);
    const token = await window.grecaptcha.execute(
      process.env.REACT_APP_RECAPTCHA_SITE_KEY,
      { action: 'submit' }
    );

    formData.recaptcha = token;
    try {
      const res = await submitTraining(formData);
      resetForm();
      if (res.data === 'Training Submitted Successfully!') {
        setSubmitLoading(false);
        openSuccessModal();
      } else {
        setSubmitLoading(false);
        openErrorModal();
      }
    } catch (error) {
      setErr(error.response.data);
    }
  };

  const cancelButtonRef = useRef(null);

  const noCopyPaste = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div className='flex'>
        <button
          type='button'
          disabled={seatsLeft === 0}
          onClick={openModal}
          className={`${
            seats === 0 ? 'btn bg-yellow-white' : 'btn bg-yellow-gradient'
          } ${
            seatsLeft === 0 && 'hover:cursor-not-allowed'
          } w-full rounded-xl my-4 py-2`}
        >
          {seats === 0 ? 'Join the waiting list' : 'Book your seat'}
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          initialFocus={cancelButtonRef}
          as='div'
          className='relative z-10'
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-75' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto font-manrope'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-7xl transform overflow-hidden rounded-2xl bg-white px-0 pb-4 text-left align-middle shadow-xl transition-all'>
                  <div className='flex flex-col w-auto'>
                    <Formik
                      validationSchema={registerValidationSchema}
                      initialValues={initialData}
                      onSubmit={handleSubmit}
                    >
                      {({ errors, touched, values, setFieldValue }) => (
                        <Form className='flex flex-col gap-4 justify-center mb-4'>
                          <div className='text-center flex-col flex items-center content-center '>
                            <h1 className='text-2xl pl-8 justify-between  font-semibold items-center bg-[#3D82F1] w-full flex py-3 text-white'>
                              <p>
                                Book
                                your seat
                              </p>
                              <img
                                onClick={closeModal}
                                className='mr-8 hover:cursor-pointer'
                                src={WhiteCross}
                              ></img>
                            </h1>
                          </div>
                          <p className='ml-9 px-4 mt-4'>
                            Complete this form and we will contact you shortly
                            to confirm your enrollment.
                          </p>{' '}
                          <div className='flex flex-col md:flex-row justify-between mx-8 md:gap-12 px-4'>
                            <div className='w-full'>
                              <div className='w-full flex justify-between md:gap-4'>
                                <div className='w-full'>
                                  {errors.username && touched.username ? (
                                    <p className='font-semibold mb-2 text-red-500'>
                                      {errors.username}
                                    </p>
                                  ) : (
                                    <p className='font-semibold mb-2'>Name</p>
                                  )}
                                  <Field
                                    className=' border border-[#B7C6D7] rounded-md w-full  px-2 py-2 text-black placeholder-[#B7C6D7]'
                                    placeholder='Name'
                                    type='text'
                                    name='username'
                                  ></Field>
                                </div>
                              </div>
                              <div className='w-full flex justify-between gap-4'>
                                <div className='w-full mt-4'>
                                  {errors.company && touched.company ? (
                                    <p className='font-semibold mb-2 text-red-500'>
                                      {errors.company}
                                    </p>
                                  ) : (
                                    <p className='font-semibold mb-2'>
                                      Company
                                    </p>
                                  )}
                                  <Field
                                    className=' border border-[#B7C6D7] rounded-md w-full  px-2 py-2 text-black placeholder-[#B7C6D7]'
                                    placeholder='Company'
                                    type='text'
                                    name='company'
                                  ></Field>
                                </div>
                                <div className='xl:w-full w-1/2 mt-4'>
                                  {errors.phone && touched.phone ? (
                                    <p className='font-semibold mb-2 text-red-500'>
                                      {errors.phone}
                                    </p>
                                  ) : (
                                    <p className='font-semibold mb-2'>Phone</p>
                                  )}
                                  <PhoneInput
                                    style={{
                                      borderWidth: '1px',
                                      borderColor: '#B7C6D7',
                                      borderRadius: '0.375rem',
                                      width: '100%',
                                      padding: '0.5rem',
                                      color: 'rgb(0,0,0)',
                                    }}
                                    defaultCountry='US'
                                    international
                                    countryCallingCodeEditable={false}
                                    value={values.phone}
                                    onChange={(value) =>
                                      setFieldValue('phone', value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className='w-full flex justify-between md:gap-4 mt-4'>
                                <div className='w-full'>
                                  {errors.email && touched.email ? (
                                    <p className='font-semibold mb-2 text-red-500'>
                                      {errors.email}
                                    </p>
                                  ) : (
                                    <p className='font-semibold mb-2 mt-4 md:mt-0'>
                                      Business email
                                    </p>
                                  )}

                                  <div className='flex'>
                                    <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-gray-50 rounded-l-md border border-r-0 border-gray-300'>
                                      <img alt='email icon' src={EmailIcon} />
                                    </span>
                                    <Field
                                      name='email'
                                      type='email'
                                      className='rounded-none rounded-r-lg bg-white border  text-gray-900 focus:ring-blue-500 focus:border-blue-500 w-full text-sm border-gray-300 p-2.5'
                                      placeholder='Business email'
                                    ></Field>
                                  </div>
                                </div>
                              </div>
                              <div className='w-full flex justify-between md:gap-4 mt-4'>
                                <div className='w-full'>
                                  {errors.confirmationEmail &&
                                  touched.confirmationEmail ? (
                                    <p className='font-semibold mb-2 text-red-500'>
                                      {errors.confirmationEmail}
                                    </p>
                                  ) : (
                                    <p className='font-semibold mb-2 mt-4 md:mt-0'>
                                      Confirmation email
                                    </p>
                                  )}

                                  <div className='flex'>
                                    <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-gray-50 rounded-l-md border border-r-0 border-gray-300'>
                                      <img alt='email icon' src={EmailIcon} />
                                    </span>
                                    <Field
                                      onCut={noCopyPaste}
                                      onCopy={noCopyPaste}
                                      onPaste={noCopyPaste}
                                      autoComplete='off'
                                      name='confirmationEmail'
                                      type='email'
                                      className='rounded-none rounded-r-lg bg-white border  text-gray-900 focus:ring-blue-500 focus:border-blue-500 w-full text-sm border-gray-300 p-2.5'
                                      placeholder='Confirmation email'
                                    ></Field>
                                  </div>
                                </div>
                              </div>
                              <div className='w-full flex justify-between md:gap-4'>
                                <div className='w-full mt-4'>
                                  {errors.country && touched.country ? (
                                    <p className='font-semibold mb-2 text-red-500'>
                                      {errors.country}
                                    </p>
                                  ) : (
                                    <p className='font-semibold mb-2'>
                                      Country
                                    </p>
                                  )}
                                  <Field
                                    className='styled-select border border-[#B7C6D7] rounded-md w-full  px-2 py-2 text-black placeholder-[#B7C6D7]'
                                    placeholder='Country'
                                    as='select'
                                    name='country'
                                  >
                                    <CountriesList />
                                  </Field>
                                </div>
                              </div>
                            </div>
                            <div className='w-full flex flex-col'>
                              <div className='w-full'>
                                {errors.courseName && touched.courseName ? (
                                  <p className='font-semibold mb-2 text-red-500'>
                                    {errors.courseName}
                                  </p>
                                ) : (
                                  <p className='font-semibold mb-2'>Course</p>
                                )}
                                <Field
                                  className=' border border-[#B7C6D7] rounded-md w-full  px-2 py-2 text-black placeholder-[#B7C6D7]'
                                  placeholder='Course name'
                                  type='text'
                                  name='courseName'
                                  disabled='true'
                                ></Field>
                              </div>
                              <div className='w-full h-full flex flex-col mt-4'>
                                {errors.message && touched.message ? (
                                  <p className='font-semibold mb-2 text-red-500'>
                                    {errors.message}
                                  </p>
                                ) : (
                                  <p className='font-semibold mb-2'>Message</p>
                                )}
                                <Field
                                  as='textarea' /* This makes the Field render as a textarea */
                                  className='border border-[#B7C6D7] rounded-md flex flex-1 w-full px-2 py-2 text-black placeholder-[#B7C6D7]'
                                  placeholder='Write your message here'
                                  name='message'
                                />
                              </div>
                            </div>
                          </div>
                          {err && <p className='text-red-500'>{err}</p>}
                          <div className='flex justify-between mt-2 px-12 gap-12 bg-[#F8F8F8] rounded-xl'>
                            <button
                              type='submit'
                              className='btn bg-yellow-gradient col-span-2 rounded-lg my-2 py-[10px] text-center items-center w-full'
                            >
                              Submit
                            </button>
                            <button
                              type='button'
                              className='bg-[#FBFCFD] border border-[#3D82F1] hover:text-blue-700 hover:border-blue-800 text-[#3D82F1] col-span-2 rounded-lg my-2 py-[10px] text-center items-center w-full'
                              onClick={closeModal}
                              ref={cancelButtonRef}
                            >
                              Cancel
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* User registered confirmation modal */}
      <Transition appear show={isSuccessOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeSuccessModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-75' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <div className='mt-4 flex flex-col justify-center items-center font-manrope'>
                    <img
                      alt='submitted message'
                      className='w-44'
                      src={Submitted}
                    />
                    <div className=' text-center mt-2'>
                      <p className='text-[22px] font-semibold'>
                        Your submission has been sent!
                      </p>
                      <p className='text-base font-semibold my-4'>
                        Wazuh Sales team will review it shortly.
                      </p>
                    </div>

                    <button
                      onClick={goBackHome}
                      type='button'
                      className='inline-flex mt-4 mb-4 justify-center rounded-lg border border-transparent bg-[#3D82F1] px-12 py-3 text-base font-normal text-white hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2'
                    >
                      Go back home
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={submitLoading} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeLoadingModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-75' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <div className='m-4 flex flex-col justify-center items-center font-manrope'>
                    <img alt='submitted message' className='w-44' src={Oval} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isErrorOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeErrorModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-75' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <div className='mt-4 flex flex-col justify-center items-center font-manrope'>
                    <img
                      alt='submitted message'
                      className='w-44'
                      src={Submitted}
                    />
                    <div className=' text-center mt-2'>
                      <p className='text-2xl font-semibold'>
                        There was an error submitting your form!
                      </p>
                      <p className='text-base font-semibold my-4'>
                        Please try again.
                      </p>
                    </div>

                    <button
                      onClick={closeErrorModal}
                      type='button'
                      className='inline-flex mt-4 mb-4 justify-center rounded-md border border-transparent bg-[#3D82F1] px-8 py-2 text-base font-normal text-white hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2'
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
